import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InventoryService } from 'src/app/xp-admin-web/operations/inventory/inventory.service';
import { DatePipe } from '@angular/common';
import { data } from 'jquery';

@Component({
  selector: 'app-inv-add-item',
  templateUrl: './inv-add-item.component.html',
  styleUrls: ['./inv-add-item.component.scss']
})
export class InvAddItemComponent implements OnInit {
  // filterForm: FormGroup
  AddForm: FormGroup;
  EditType: string = ''
  label1: any;
  label2: any;
  label3: any;
  label4: any;
  label5: any;
  label6: any;
  label7: any;
  label8: any;
  label9: any;
  label10: any;
  label11: any;
  label12: any;
  label13: any;
  label14: any;


  public totalSize = 0;
  header: any;
  SaveButton: any;
  CancelButton: any;
  addItem: boolean = false;
  addInventory: boolean = false;
  issueItem: boolean = false;
  IsItemList: boolean = false;
  isGateway: boolean = false;
  Itemlists: any[];
  GatewayList: any[];
  IsMessage: boolean = false;
  UserList:any[]
  MessageList: any[];
  countdown: number = 5;
  constructor(public dialogRef: MatDialogRef<InvAddItemComponent>, public fb: FormBuilder, private _service: InventoryService, @Inject(MAT_DIALOG_DATA) public data: any,
    public _datePipe: DatePipe, private ngZone: NgZone) {
    this.AddForm = this.fb.group({
      ItemName: new FormControl('', [Validators.required]),
      ItemId: new FormControl('', [Validators.required]),
      Input: new FormControl('', [Validators.required]),
      FromSerialNo: new FormControl('', [Validators.required]),
      ToSerialNo: new FormControl('', [Validators.required]),
      Quantity: new FormControl(0, [Validators.required]),
      Gateway: new FormControl('', [Validators.required]),
      GatewayId: new FormControl('', [Validators.required]),
      Invoice: new FormControl('', [Validators.required]),
      InvoiceDate: new FormControl('', [Validators.required]),
      InvoiceValue: new FormControl('', [Validators.required]),
      InventoryId: new FormControl('', [Validators.required]),
      Supplier: new FormControl('', [Validators.required]),
      ModelNo: new FormControl('', [Validators.required]),
      CompanyName: new FormControl('', [Validators.required]),
      IssueTo: new FormControl('', [Validators.required]),
    })
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.EditType = this.data.data.editType
    this.header = this.data.data.header
    this.SaveButton = this.data.data.SaveButton
    this.CancelButton = this.data.data.CancelButton
    if (this.EditType === 'add-item') {
      this.label1 = this.data.data.label1;
      this.addItem = true;
    }
    else if (this.EditType === "add-invert-item") {
      this.label2 = this.data.data.label2;
      this.label3 = this.data.data.label3;
      this.label4 = this.data.data.label4;
      this.label5 = this.data.data.label5;
      this.label6 = this.data.data.label6;
      this.label7 = this.data.data.label7;
      this.label8 = this.data.data.label8;
      this.label9 = this.data.data.label9;
      this.label10 = this.data.data.label10;
      this.label11 = this.data.data.label11;
      this.label12 = this.data.data.label12;
      this.label13 = this.data.data.label13;
      this.Itemlists = this.data.ItemList;
      this.IsItemList = true;
    }
    else if (this.EditType === "issue-invert-item") {
      this.AddForm.controls['ItemId'].setValue(this.data.data.LookupId)
      this.AddForm.controls['Supplier'].setValue(this.data.data.Supplier)
      this.label4 = this.data.data.label4;
      this.label5 = this.data.data.label5;
      this.label6 = this.data.data.label6;
      this.label7 = this.data.data.label7;
      this.label8 = this.data.data.label8;
      this.label14 = this.data.data.label14;
      this.GatewayList = this.data.data.GateWayList;
      this.issueItem = true;
    }
  }

  close(): void {
    this.dialogRef.close()
  }
  getData() {
    this._service.getInwardInventory(this.AddForm.value).subscribe(res => {
      this.Itemlists = res['Data']
      this.totalSize = res['Data'][0]['TotalRecords'];
    })
  }

  saveData(EditType: string) {
    if (EditType === "add-item") {
      const ItemName = this.AddForm.controls['ItemName'];
      if (!ItemName.value) {
        ItemName.markAsTouched();
      }
      else if (ItemName.valid) {
        const item = {
          ItemName: this.capitalizeFirstLetter(this.AddForm.get("ItemName")?.value)
        };
        this._service.addInventoryServicesItem(item).subscribe(result => {
          if (result['Success']) {
            this.dialogRef.close(true);
          }
        })
      }
    }
    else if (EditType === "add-invert-item") {
      this.AddForm.markAllAsTouched();
      if (this.AddForm.controls['ItemId'].valid && this.AddForm.controls['Input'].valid && this.AddForm.controls['FromSerialNo'].valid && this.AddForm.controls['ToSerialNo'].valid && this.AddForm.controls['Quantity'].valid
        && this.AddForm.controls['Invoice'].valid && this.AddForm.controls['InvoiceDate'].valid && this.AddForm.controls['InvoiceValue'].valid
      ) {
        const formData = this.AddForm.value;
        const dataToSubmit = {
          ItemId: formData.ItemId,
          Supplier: this.capitalizeFirstLetter(formData.Input),
          FromSerialNo: formData.FromSerialNo,
          ToSerialNo: formData.ToSerialNo,
          Quantity: formData.Quantity,
          Invoice: formData.Invoice,
          InvoiceDate: formData.InvoiceDate,
          InvoiceValue: formData.InvoiceValue,
          ModelNo:formData.ModelNo,
          CompanyName:formData.CompanyName
        }

        this._service.addInwardInventory(dataToSubmit).subscribe(res => {
          if (res['Success']) {
            this.dialogRef.close(true)
          }
        })
      }
      else {
        console.error('Invalid Form');
      }
    }
    else if (EditType === "issue-invert-item") {
      this.AddForm.markAllAsTouched();
      if (this.AddForm.controls['ItemId'].valid && this.AddForm.controls['Supplier'].valid && this.AddForm.controls['FromSerialNo'].valid && this.AddForm.controls['ToSerialNo'].valid
        && this.AddForm.controls['GatewayId'].valid && this.AddForm.controls['IssueTo'].valid
      ) {
        const formData = this.AddForm.value;
        const dataToSubmit = {
          ItemId: formData.ItemId,
          Supplier: formData.Supplier,
          GatewayId: formData.GatewayId,
          FromSerialNo: formData.FromSerialNo,
          ToSerialNo: formData.ToSerialNo,
          IssueTo: formData.IssueTo
        }
        this._service.addInwardGatewayInventory(dataToSubmit).subscribe(res => {
          if (res['Success']) {
            this.IsMessage = true;
            this.MessageList = res['Data']
            const interval = setInterval(() => {
              this.countdown--;
              if (this.countdown === 0) {
                clearInterval(interval);
                this.dialogRef.close(true);
              }
            }, 1000);
          }
        })
      }
      else {
        console.error('Invalid Form');
      }
    }
  }
  capitalizeFirstLetter(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  TotalSerial: any;
  calculateDifference(): number | string {
    const fromSerial = this.AddForm.get('FromSerialNo')?.value;
    const toSerial = this.AddForm.get('ToSerialNo')?.value;
    if (fromSerial && toSerial && !isNaN(fromSerial) && !isNaN(toSerial)) {
      this.TotalSerial = toSerial - fromSerial + 1;
      this.AddForm.controls['Quantity'].setValue(this.TotalSerial);
      return this.TotalSerial;
    }
    return 'N/A';
  }


  selectedDate: any;
  onChangedate($event) {
    this.selectedDate = this._datePipe.transform($event, 'yyyy-MM-dd');
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.AddForm.controls['InvoiceDate'].setValue(this.selectedDate);
      });
    });
  }

  onSelectGateway(GatewayId: number): void {
    this._service.getUserNameByGateway(GatewayId).subscribe((res: any) => {
      if (res?.Data && Array.isArray(res.Data)) {
        const userList = res.Data.map((user: any) => ({
          UserId: user.UserId,
          UserName: user.UserName
        }));
        this.UserList = userList;
      }
    });
  }
  
}


