import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '../services/alert.service';
import { get } from 'jquery';
import { isSuccess } from 'angular-in-memory-web-api';
import { DatePipe } from '@angular/common';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TripService } from 'src/app/xp-admin-web/trips/trip.service';


@Component({
  selector: 'app-customise-mis-dialog',
  templateUrl: './customise-mis-dialog.component.html',
  styleUrls: ['./customise-mis-dialog.component.scss']
})
export class CustomiseMisDialogComponent implements OnInit {

  public customiseMISDialogData : CustomiseMISDialogData;
  public selectedIndex : number = 0;
  public scheduledDate : any = null

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<CustomiseMisDialogComponent>,private alertService: AlertService,public datepipe : DatePipe,private tripsService : TripService) { 
    this.dialogRef.disableClose = true;
    this.customiseMISDialogData = data['customiseMISDialogData']
   
  }

  onTimeChange(event: string): void {
   
  }

 

  ngOnInit(): void {
    this.onSelectStatus(this.selectedIndex)
  }

  isNotNullUndefinedAndEmpty(value: any): boolean {
    return value != null && value !== undefined && value !== '';
  }


  close(status : boolean){
    if(!status) this.dialogRef.close({success : status,customisedFormat : null,scheduledTime: this.scheduledDate})
    else{
      this.dialogRef.close({success : status,customisedFormat : this.customiseMISDialogData.misTypeDetails[this.selectedIndex],scheduledTime: this.scheduledDate}) 
    }
  }

  saveFormat(){
    if(this.customiseMISDialogData.showScheduleTime && this.isNotNullUndefinedAndEmpty(this.scheduledDate) ){
      this.close(true)
    }
    else this.alertService.createAlert("Please select schedule time",2)
    
  }
  
  moveSelectedToChoosenOnes(indexOfMisType){
    const selectedAnnexuresToMove : any[] = this.customiseMISDialogData.misTypeDetails[indexOfMisType].misAvailableColumns.filter(x => x.isSelected)
    for(let i=0;i<selectedAnnexuresToMove.length;i++){
      let currentAnnexureData : CustomiseMISColumnsFormat = selectedAnnexuresToMove[i]
      this.customiseMISDialogData.misTypeDetails[indexOfMisType].misOptedColumns.push({labelName: currentAnnexureData.labelName,keyName:currentAnnexureData.keyName,additionalInfo: currentAnnexureData.additionalInfo,isSelected: false})
    }
    this.customiseMISDialogData.misTypeDetails[indexOfMisType].misAvailableColumns = this.customiseMISDialogData.misTypeDetails[indexOfMisType].misAvailableColumns.filter(x => !x.isSelected)
  }

  moveSelectedToAnnexure(indexOfMisType){
    const selectedChoseOnesToMove : any[] = this.customiseMISDialogData.misTypeDetails[indexOfMisType].misOptedColumns.filter(x => x.isSelected)
    for(let i=0;i<selectedChoseOnesToMove.length;i++){
      let currentChoosenOneData : CustomiseMISColumnsFormat = selectedChoseOnesToMove[i]
      this.customiseMISDialogData.misTypeDetails[indexOfMisType].misAvailableColumns.push({labelName: currentChoosenOneData.labelName,keyName:currentChoosenOneData.keyName,additionalInfo: currentChoosenOneData.additionalInfo,isSelected: false})
    }
    this.customiseMISDialogData.misTypeDetails[indexOfMisType].misOptedColumns = this.customiseMISDialogData.misTypeDetails[indexOfMisType].misOptedColumns.filter(x => !x.isSelected)
  }

  moveUp(index: number,misTypeIndex : number) {
    if (index > 0) {
      const columns = this.customiseMISDialogData.misTypeDetails[misTypeIndex].misOptedColumns;
      [columns[index - 1], columns[index]] = [columns[index], columns[index - 1]];
    }
  }

  // Method to move the item down
  moveDown(index: number,misTypeIndex : number) {
    const columns = this.customiseMISDialogData.misTypeDetails[misTypeIndex].misOptedColumns;
    if (index < columns.length - 1) {
      [columns[index + 1], columns[index]] = [columns[index], columns[index + 1]];
    }
  }

  onSelectStatus(selectedIndex){
    this.getOptedMISColumns(this.customiseMISDialogData.misTypeDetails[selectedIndex].misFormatType, this.customiseMISDialogData.identifierId).subscribe({
      next: (data) => {
        this.customiseMISDialogData.misTypeDetails[selectedIndex].misOptedColumns = data.customiseMISColumnsFormat
        this.customiseMISDialogData.misTypeDetails[selectedIndex].scheduledDateTime = data.scheduledDateTime
        this.customiseMISDialogData.misTypeDetails[selectedIndex].customerMISFormatId = data.customerMISFormatId
        this.scheduledDate = this.datepipe.transform(this.customiseMISDialogData.misTypeDetails[selectedIndex].scheduledDateTime, 'HH:mm')
        if(this.customiseMISDialogData.misTypeDetails[selectedIndex].misOptedColumns.length > 0){
          const optedColumn = this.customiseMISDialogData.misTypeDetails[selectedIndex].misOptedColumns.map(x => x.labelName)
          this.customiseMISDialogData.misTypeDetails[selectedIndex].misAvailableColumns = this.customiseMISDialogData.misTypeDetails[selectedIndex].misAvailableColumns.filter(x=> !optedColumn.includes(x.labelName))
        }
      },
      error: (error) => {
        console.error('Error fetching MIS columns:', error);
      }
    });
  }

  public getOptedMISColumns(misType: string, customerId: number): Observable<{customerMISFormatId: number | null, customiseMISColumnsFormat: CustomiseMISColumnsFormat[],scheduledDateTime : string}> {
    let customiseMISColumnsFormatData: CustomiseMISColumnsFormat[] = [];
    
    return this.tripsService.getCustomiseMISColumnsByMISType(misType, customerId).pipe(
      map(response => {
        if (response["Success"]) {
          const data = response['Data'];
          const customiseMISColumnsFormats: any[] = data['CustomiseMISColumnsFormats'] || [];
  
          for (let i = 0; i < customiseMISColumnsFormats.length; i++) {
            const x = customiseMISColumnsFormats[i];
            customiseMISColumnsFormatData.push({
              labelName: x["labelName"],
              keyName: x["keyName"],
              additionalInfo: x["additionalInfo"],
              isSelected: false
            });
          }
  
          return {
            customerMISFormatId: data["CustomisedMISFormatId"] || null,
            customiseMISColumnsFormat: customiseMISColumnsFormatData,
            scheduledDateTime : data["ScheduledDateTime"] || null
          };
        }
  
        // In case of an unsuccessful response, return empty values
        return { customerMISFormatId: null, customiseMISColumnsFormat: [],scheduledDateTime: null };
      }),
      catchError(error => {
        console.error(error); // Optional: log the error
        return of({ customerMISFormatId: null, customiseMISColumnsFormat: [],scheduledDateTime : null }); // Return empty object on error
      })
    );

  }


}

export class CustomiseMISDialogData{

  public identifierId : number;
  public dialogTitle : string ;
  public misTypeDetails : MISTypeDetails[] =[] ;
  public showScheduleTime : boolean;

}

export class MISTypeDetails{
 
  public misFormatType : string;
  public misAvailableColumns : CustomiseMISColumnsFormat[] = []
  public misOptedColumns : CustomiseMISColumnsFormat[] = []
  public scheduledDateTime : any
  public customerMISFormatId : number
}

export class CustomiseMISColumnsFormat{
  public labelName : string
  public keyName : string
  public additionalInfo : string = ""
  public isSelected : boolean = false
}
