import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { responseModel } from 'src/app/_models/response.model';
import { GlobalFilter } from '../ffv-associate-billing/ffv-billing/ffv-billing.component';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class BillingService {

  public Total_FCL : number = 0;
  public Total_LCL : number = 0;
  public TotalExpress : number = 0;
  public TotalBB_DD : number= 0;
  constructor(private _http: HttpClient) {


  }

  GetbilledOrdersByCustomer(data) {
    var respdata = this._http.post(environment.apiUrl + 'api/get-billed-orders-customer-annexure-details-hs', data);

    return respdata;

  }
  UpsertDesPatchDetails(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-despatch-details', data);
  }

  GetUnbilledOrdersByCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-customer-annexure-details-hs', data);
  }
  GetUnbilledOrdersByCustomerId(data) {
    return this._http.post(environment.apiUrl + 'api/get-unbilled-order-items-customer', data);
  }
  getUnbilledDetails(data) {
    return this._http.post(environment.apiUrl + 'api/get-unbiled-customer-details', data);
  }
  gettotalpaymentdetailsReport(data) {
    // var respdata =  this._http.get(environment.apiUrl + 'api/get-total-payment-details');
    var respdata = this._http.post(environment.apiUrl + 'api/get-total-payment-details', data);
    return respdata;

  }

  deleteinvoicerecord(data) {
    return this._http.post(environment.apiUrl + 'api/update-delete-customer-invoice', data);
  }
  deletecreditnote(creditnote){
    return this._http.post(environment.apiUrl + 'api/delelte-credit-note', creditnote)
  }
  getbilledDetails(data) {
    return this._http.post(environment.apiUrl + 'api/get-biled-customer-details', data);
  }
  getBilledOrdersOfCustomers(data) {
    return this._http.post(environment.apiUrl + 'api/get-billed-order-customers', data);
  }
  getDuesOverDueRecievables(data) {
    return this._http.post(environment.apiUrl + 'api/get-dues-overdues-recievables', data);
  }
  getDuesOverDuesRecievables(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/cb/get-due-overdues-invoices', data);
  }
  getDuesOverDueRecievablesXpFinance(data) {
    return this._http.post(environment.apiUrl + 'api/get-dues-overdues-recievables-xp-finance', data);
  }
  getAllCollectedOrders(data) {
    return this._http.post(environment.apiUrl + 'api/get-collected-orders-customer', data);
  }
  getAllOnAccountOrders(data) {
    return this._http.post(environment.apiUrl + 'api/get-onaccount-details', data);
  }
  getRevenueShrinkages(data) {
    return this._http.post(environment.apiUrl + 'api/get-revenue-shrinkage-orders-customer', data);
  }
  GetReceivablesDashboard(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-receivables-dashboard', filterData);
  }
  GetAgeingAnalysisDashboard() {
    return this._http.get(environment.apiUrl + 'api/get-ageing-analysis-dashboard');
  }
  GetReceivablesChartInfo(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-receivables-chart-info', filterData);
  }
  GetCashDiscountOrders(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-cash-discount-orders', filterData);
  }
  GetCreditNotesOfCustomer(filterData) {
    return this._http.post(environment.apiUrl + 'api/get-credit-notes-customer', filterData);
  }

  getcustomercountdetails() {

    var respdata = this._http.get(environment.apiUrl + 'api/get-customer-billing-count-details');
    return respdata;
  }

  getbilledUnbilledReport(data) {
    var respdata = this._http.post(environment.apiUrl + 'api/get-billed-unbilled-totalamount-CB', data);
    return respdata;

  }
  GetDashboardSummaryCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-dashboard-summary-customer-web', data);
  }
  GetChartsDataCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-charts-data-customers', data);
  }

  getTopCustomersReport(data) {
    //var respdata =  this._http.get(environment.apiUrl + 'api/get-top-customers-CB');
    var respdata = this._http.post(environment.apiUrl + 'api/get-top-customers-CB', data);
    return respdata;

  }

  getTopAssociatesReport(data) {
    //  var respdata =  this._http.get(environment.apiUrl + 'api/get-top-associates-CB');
    var respdata = this._http.post(environment.apiUrl + 'api/get-top-associates-CB', data);
    return respdata;

  }
  //To get the unbilled orders of customers
  GetUnbilledOrdersByCustomers(data) {
    return this._http.post<any>(environment.apiUrl + 'api/get-unbilled-order-customers',data);
  }
  GetDesPatchDetails(id) {
    return this._http.get(environment.apiUrl + 'api/get-update-despatch-details/' + id);
  }
    
  GetFinanceManager(){
    return this._http.get(environment.apiUrl + 'api/get-finance-managers');
  }
  getIntransitData(data){
    return this._http.post(environment.apiUrl + 'api/get-intransit-billing', data);
  }
  getIntransitExpressOrdersLCL(data){
    return this._http.post(environment.apiUrl + 'api/get-intransit-express', data);
  }
  get_total_counts(screenName, Customerid,FromDate, ToDate) {
    var counts = this._http.get(environment.apiUrl + 
      'api/get-customer-billing-sub-tabs-count?ScreenName='+screenName+'&CustomerId='+Customerid
    +'&startDate='+FromDate+'&endDate='+ ToDate);
    return counts;
    }
    sub_tabs_count(screen,id,fromdate,todate){
      this.get_total_counts(screen,id,fromdate,todate).subscribe(data => {
        if (data != null) {
          console.log("count response",data['Data']);
          this.Total_FCL = data['Data']['TotalFCL'];
          this.Total_LCL = data['Data']['TotalLCL'];
          this.TotalExpress = data['Data']['TotalExpress'];
          this.TotalBB_DD = data['Data']['TotalBB_DD'];
        }
      });
    }

    // get_intransit_breakbulk(data){
    //   return this._http.post(environment.apiUrl + 'api/get-intransit-breakbulk', data);
    // }
    // get_intransit_express_CB(data){
    //   return this._http.post(environment.apiUrl + 'api/get-intransit-express-CB', data);
    // }

    GetCreditNoteForCustomers(data) {
      return this._http.post(environment.apiUrl + 'api/get-all-credit-note-for-customers', data);
    }

    GetCBDashboardBilled(data){
      return this._http.post(environment.coreapiUrl + 'api/v2/customerBilling/get-CB-total-billed-graph', data);
    }
    GetCBBashboardUnbilled(data){
      return this._http.post(environment.coreapiUrl + 'api/v2/customerBilling/get-CB-total-unbilled-graph', data);
    }
    getCancelledOrdersOfCustomers(data) {
      return this._http.post(environment.apiUrl + 'api/get-cancelled-order-customers', data);
    }

    getCancelledCreditNotes(data) {
      return this._http.post(environment.apiUrl + 'api/get-cancelled-credit_notes', data);
    }

    downloadInvoices(data){
      return this._http.post(environment.coreapiUrl + 'api/v2/downloadpdfs', { SelectedItems: data }, { responseType: 'blob' });
    }
    DOWNLOADpods(data){
      return this._http.post(environment.apiUrl + 'api/downloadPODs',data, { responseType: 'blob' });
    }
    //CORE APIs
    get_intransit_express_CB(data){
      return this._http.post(environment.coreapiUrl + 'api/v2/customerBilling/GetExpressIntransitOrders', data);
    }

    get_intransit_breakbulk(data){
      return this._http.post(environment.coreapiUrl + 'api/v2/customerBilling/GetBreakbulkIntransitOrders', data);
    }

    getProvisionalInvoices(data){
      return this._http.post(environment.coreapiUrl+'api/v2/get-provisional-invoices',data);
    }

    getDataToGenerateFCLInvoiceDocument(provisionalInvoiceId){
      return this._http.get(environment.coreapiUrl + 'api/v2/get-data-to-generate-fcl-invoice-document?provisionalInvoiceId='+provisionalInvoiceId);
    }

    finaliseProvisionalInvoice(invoiceData){
      return this._http.post(environment.coreapiUrl + 'api/v2/finalise-fcl-provisional-invoice',invoiceData);
    }
}
