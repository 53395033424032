import { Menu } from './menu.model';
 
export const verticalMenuItems = [
    new Menu(1, 'Dashboard', '/xp/dashboard', null, 'dashboard', null, false, 0, "white"),
    new Menu(2, 'Manage Users', '/xp/manageusers', null, 'accessible', null, true, 0, "white"),
    new Menu(3, 'Lanes', '/xp/lanes', null, 'accessible', null, true, 0, "white"),
    new Menu(4, 'Transactions', '/xp/transactions', null, 'dvr', null, true, 0, "white"),
    new Menu(5, 'Capacity', '/xp/lanes/capacity', null, 'accessible', null, true, 3, "white"),
    new Menu(6, 'Orders', '/xp/lanes/orders', null, 'accessible', null, true, 3, "white"),
    new Menu(7, 'Trips', '/xp/lanes/trips', null, 'accessible', null, true, 3, "white"),
    new Menu(8, 'Customers', '/xp/manageusers/customers', null, 'accessible', null, true, 2, "white"),
    new Menu(9, 'Fleet Vendors', '/xp/manageusers/fleetvendors', null, 'accessible', null, true, 2, "white"),
    new Menu(10, 'Invoice', '/xp/transactions/invoice', null, 'accessible', null, true, 4, "white"),
    new Menu(11, 'Payments', '/xp/transactions/payments', null, 'accessible', null, true, 4, "white"),
    new Menu(12, 'Scheduling', '/xp/scheduling', null, 'accessible', null, true, 0, "white"),,
    new Menu(13, 'Admin', '/xp/admin', null, 'accessible', null, true, 0, "white"),
    new Menu(14, 'Profile', '/xp/admin/profile', null, 'accessible', null, true, 13, "white"),
    new Menu(15, 'Preference', '/xp/admin/preference', null, 'accessible', null, true, 13, "white"),
    new Menu(16, 'Fleet', '/xp/fleet', null, 'accessible', null, true, 0, "white"),
    new Menu(17, 'Driver', '/xp/fleet/driver', null, 'accessible', null, true, 16, "white"),
    new Menu(18, 'Lanes', '/xp/fleet/lanes', null, 'accessible', null, true, 16, "white"),
    new Menu(19, 'Trucks', '/xp/fleet/trucks', null, 'accessible', null, true, 16, "white"),
    new Menu(20, 'Loads', '/xp/loads', null, 'accessible', null, true, 0, "white"),
    new Menu(21, 'Trips', '/xp/trips', null, 'accessible', null, true, 0, "white"),
    new Menu(22, 'Payments', '/xp/payments', null, 'accessible', null, true, 0, "white"),

]

export const horizontalMenuItems = [
    new Menu(1, 'Dashboard', '/xp/dashboard', null, 'dashboard', null, false, 0, "white"),
    new Menu(2, 'Manage Users', '/xp/manageusers', null, 'accessible', null, true, 0, "white"),
    new Menu(3, 'Lanes', '/xp/lanes', null, 'accessible', null, true, 0, "white"),
    new Menu(4, 'Transactions', '/xp/transactions', null, 'dvr', null, true, 0, "white"),
    new Menu(5, 'Capacity', '/xp/lanes/capacity', null, 'accessible', null, true, 3, "white"),
    new Menu(6, 'Orders', '/xp/lanes/orders', null, 'accessible', null, true, 3, "white"),
    new Menu(7, 'Trips', '/xp/lanes/trips', null, 'accessible', null, true, 3, "white"),
    new Menu(8, 'Customers', '/xp/manageusers/customers', null, 'accessible', null, true, 2, "white"),
    new Menu(9, 'Fleet Vendors', '/xp/manageusers/fleetvendors', null, 'accessible', null, true, 2, "white"),
    new Menu(10, 'Invoice', '/xp/transactions/invoice', null, 'accessible', null, true, 4, "white"),
    new Menu(11, 'Payments', '/xp/transactions/payments', null, 'accessible', null, true, 4, "white"),
    new Menu(12, 'Scheduling', '/xp/scheduling', null, 'accessible', null, true, 0, "white"),
    new Menu(13, 'Admin', '/xp/admin', null, 'accessible', null, true, 0, "white"),
    new Menu(14, 'Profile', '/xp/admin/profile', null, 'accessible', null, true, 13, "white"),
    new Menu(15, 'Preference', '/xp/admin/preference', null, 'accessible', null, true, 13, "white"),
    new Menu(16, 'Fleet', '/xp/fleet', null, 'accessible', null, true, 0, "white"),
    new Menu(17, 'Driver', '/xp/fleet/driver', null, 'accessible', null, true, 16, "white"),
    new Menu(18, 'Lanes', '/xp/fleet/lanes', null, 'accessible', null, true, 16, "white"),
    new Menu(19, 'Trucks', '/xp/fleet/trucks', null, 'accessible', null, true, 16, "white"),
    new Menu(20, 'Loads', '/xp/loads', null, 'accessible', null, true, 0, "white"),
    new Menu(21, 'Trips', '/xp/tripsinvendor', null, 'accessible', null, true, 0, "white"),
    new Menu(22, 'Payments', '/xp/payments', null, 'accessible', null, true, 0, "white"),

]

export const adminMenuItems = [
    
    new Menu(2, 'Orders', '/xp/ordersNew', null, 'add_shopping_cart', null, true, 0, "white"),
    new Menu(22, 'Capacity', '/xp/capacity', null, 'settings_applications', null, true, 0, "white"),
    new Menu(30, 'Load Board', '/xp/load-board', null, 'assignment', null, false, 0, "red"),
    new Menu(3, 'Customers', '/xp/user/customers', null, 'people', null, false, 0, "white"),
    new Menu(4, 'FFV', '/xp/user/ffv2', null, 'directions_car', null, true, 0, "white"),
    new Menu(5, 'Operations', '/xp/operation', null, 'record_voice_over', null, true, 0, "white"),
    new Menu(24, 'Service', '/xp/trips', null, 'accessible', null, true, 0, "white"),
    new Menu(6, 'Finance', '/xp/finance', null, 'attach_money', null, true, 0, "white"),
    new Menu(7, 'Admin', '/xp/admin', null, 'person', null, true, 0, "white"),
    new Menu(10, 'Master Data', '/xp/admin/masterdata', null, 'bookmarks', null, true, 7, "white"),
    new Menu(8, 'Roles & Users', '/xp/admin/users', null, 'supervisor_account', null, true, 7, "white"),
    new Menu(13, 'Contracts', '/xp/admin/contracts', null, 'book', null, true, 7, "white"),
    new Menu(13, 'Price Master', '/xp/admin/price-master', null, 'attach_money', null, true, 7, "white"),
    new Menu(13, 'Diesel Prices', '/xp/admin/diesel-master', null, 'local_gas_station', null, true, 7, "white"),
  //  new Menu(17, 'Route Master', '/xp/admin/networkschedule', null, 'wifi', null, true, 7, "white"),
    new Menu(18, 'Vehicle Types', '/xp/admin/vehicletype', null, 'airport_shuttle', null, true, 7, "white"),
    new Menu(19, 'Testimonials', '/xp/admin/testimonials', null, 'feedback', null, true, 7, "white"),
    new Menu(20, 'Offers', '/xp/admin/offers', null, 'local_offer', null, true, 7, "white"),
    new Menu(21, 'Settings', '/xp/admin/settings', null, 'settings_applications', null, true, 7, "white"),
    new Menu(39, 'Consignor Data', '/xp/admin/consignor-data', null, 'settings_application', null, true, 7, "white"),
   // new Menu(23, 'Associates', '/xp/admin/associates', null, 'people', null, true, 7, "white"),
  //  new Menu(24, 'Menu Search', '/xp/operation/MenuSearch', null, 'search', null, true, 5, "white"),
    // new Menu(25, 'Manage Capacity', '/xp/operation/manageCapacity', null, 'library_add_check', null, true, 5, "white"),
 //   new Menu(26, 'Enquiry', '/xp/operation/enquiry', null, 'contact_phone', null, true, 5, "white"), 
    // new Menu(27, 'Manage Orders', '/xp/operation/manageOrders', null, 'shopping_cart', null, true, 5, "white"),
    new Menu(27, 'Operations One', '/xp/operation/operationOne', null, 'track_changes', null, true, 5, "white"),

    new Menu(34, 'Mark Attendance', '/xp/operation/markattendance', null, 'today', null, true, 5, "red"),
    new Menu(48, 'Certificate Of facts', '/xp/operation/CertificateOfFacts', null, 'event_note', null, true, 5, "red"),
     new Menu(38, 'Executive Summary', '/xp/finance/executive-summary', null, 'format_list_bulleted', null, true, 6, "red"),
     new Menu(36, ' Customer Billing', '/xp/finance/Billing', null, 'featured_play_list', null, true, 6, "red"),
     new Menu(29, 'Receivables', '/xp/finance/Receivables', null, 'archive', null, true, 6, "red"),
     new Menu(28, 'FFV / Associate Billing', '/xp/finance/ffv-associate-billing', null, 'featured_play_list', null, true, 6, "red"),
     new Menu(30, 'Payables', '/xp/finance/Payables', null, 'unarchive', null, true, 6, "red"),
    
    
    
    //new Menu(28, 'Masters', '/xp/finance/Masters', null, 'shopping_cart', null, true, 6, "white"),
    new Menu(31, 'Manage FFV', '/xp/user/ffv2', null, 'dns', null, true, 4, "white"),
    new Menu(35, 'Associates', '/xp/user/associates', null, 'people', null, true, 4, "red"),
  //  new Menu(32, 'Credit / Debit Notes', '/xp/finance/credit-debit-notes', null, 'repeat', null, true, 6, "red"),
    new Menu(33, 'Drivers Panel', '/xp/user/drivers-panel', null, 'dns', null, true, 4, "red"),
    
    
    new Menu(37, 'Event Log', '/xp/admin/event-log', null, 'settings_applications', null, true, 7, "red"),
    new Menu(42, 'Approvals', '/xp/approvals', null, 'done_all', null, true, 0, "white"),
    new Menu(81, 'Credit Note', '/xp/approvals/approve-credit-note', null, 'supervisor_account', null, false, 42, "white"),
    new Menu(90, 'FFV Invoice(Dynamic)', '/xp/approvals/FFV-Invoice-Dynamic', null, 'featured_play_list', null, false, 42, "white"),
    new Menu(88, 'FFV Payments', '/xp/approvals/ffv-payment/normal-payment', null, 'supervisor_account', null, false, 42, "white"),
    // new Menu(82, 'Customer Paid/To-Pay', '/xp/approvals/paid-to-pay-approvals', null, 'book', null, false, 42, "white"),
    // new Menu(89, 'SDA Approvals', '/xp/approvals/sda-approvals', null, 'assignment', null, false, 42, "white")

]

export const customerAdminMenuItems = [
    new Menu(1, 'Dashboard', '/xp/customerdashboard', null, 'dashboard', null, false, 0, "white"),
    new Menu(2, 'Manage Users', '/xp/customerAdmin/manageUsers', null, 'people', null, false, 9, "white"),
    new Menu(3, 'Location Master', '/xp/locations/origins', null, 'location_searching', null, false, 0, "white"),
    new Menu(4, 'Testimonials', '/xp/customerAdmin/testimonials', null, 'record_voice_over', null, false, 9, "white"),
    new Menu(5, 'Preferences', '/xp/customerAdmin/preferences', null, 'settings_applications', null, false, 9, "white"),
    new Menu(6, 'Invoices', '/xp/customerinvoice', null, 'accessible', null, true, 0, "white"),
    new Menu(7, 'Rates', '/xp/rates', null, 'attach_money', null, true, 0, "white"),
    new Menu(8, 'Orders', '/xp/contracts', null, 'rate_review', null, true, 0, "white"),
    new Menu(12,"performance",'/xp/performance', null, 'description', null, true, 0, "white"),
    new Menu(9, 'Admin', '/xp/customerAdmin', null, 'person', null, true, 0, "white"),
    new Menu(10, 'Messages', '/xp/customerAdmin/messages', null, 'message', null, false, 9, "white"),
    new Menu(11, 'Offers', '/xp/customerAdmin/offers', null, 'local_offer', null, false, 9, "white"),
    new Menu(12, 'Contact Us', '/xp/customerAdmin/ContactUs', null, 'people', null, false, 9, "white"),
    // new Menu(11, 'My Points', '/xp/customerAdmin/offers', null, 'local_offer', null, false, 9, "white"),
]

export const vendorMenuItems = [
    new Menu(1, 'Fleet Dashboard', '/xp/fleetDashboard', null, 'dashboard', null, false, 0, "white"),
    new Menu(14, 'Profile', '/xp/admin/profile', null, 'emoji_people', null, true, 13, "white"),
    new Menu(15, 'Preference', '/xp/admin/preference', null, 'near_me', null, true, 13, "white"),
    new Menu(16, 'Fleet', '/xp/fleet', null, 'directions_car', null, true, 0, "white"),
    new Menu(17, 'Drivers', '/xp/fleet/driver', null, 'contacts', null, true, 16, "white"),
    new Menu(18, 'Lanes', '/xp/fleet/lanes', null, 'import_export', null, true, 16, "white"),
    new Menu(19, 'Trucks', '/xp/fleet/trucks', null, 'local_shipping', null, true, 16, "white"),
    new Menu(20, 'Loads', '/xp/loads', null, 'king_bed', null, true, 0, "white"),
    new Menu(21, 'Trips', '/xp/tripsinvendor', null, 'local_taxi', null, true, 0, "white"),
    new Menu(22, 'Payments', '/xp/payments', null, 'attach_money', null, true, 0, "white"),
    new Menu(13, 'Admin', '/xp/admin', null, 'person', null, true, 0, "white"),

]

// export const horizontalMenuItems = [
//     new Menu(1, 'Dashboard', '/xp/dashboard', null, 'dashboard', null, false, 0, "white"),
//     new Menu(2, 'Fleet', '/xp/fleet', null, 'accessible', null, true, 0, "white"),
//     new Menu(3, 'Loads', '/xp/loads', null, 'accessible', null, true, 0, "white"),
//     new Menu(4, 'Trips', '/xp/trips', null, 'dvr', null, true, 0, "white"),
//     new Menu(5, 'Payments', '/xp/payments', null, 'accessible', null, true, 0, "white"),
//     new Menu(6, 'Admin', '/xp/admin', null, 'accessible', null, true, 0, "white"),
//     new Menu(7, 'Driver', '/xp/fleet/driver', null, 'accessible', null, true, 2, "white"),
//     new Menu(8, 'Lanes', '/xp/fleet/lanes', null, 'accessible', null, true, 2, "white"),
//     new Menu(9, 'Trucks', '/xp/fleet/trucks', null, 'accessible', null, true, 2, "white"),
//     new Menu(10, 'Profile', '/xp/admin/profile', null, 'accessible', null, true, 6, "white"),
//     new Menu(11, 'Preference', '/xp/admin/prefence', null, 'accessible', null, true, 6, "white"),
//     // new Menu(12, 'Scheduling', '/xp/scheduling', null, 'accessible', null, true, 0, "white")
// ]
