import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { Menu } from './menu.model';
import { verticalMenuItems, horizontalMenuItems, vendorMenuItems, adminMenuItems, customerAdminMenuItems } from './menu';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

@Injectable()
export class MenuService {

  sessionDetails: any;


  constructor(private location: Location,
    private _http: HttpClient,
    private router: Router,

  ) {
    this.sessionDetails = JSON.parse(localStorage.getItem('userSession'));
  }

  public getVerticalMenuItems(): Array<Menu> {
    return verticalMenuItems;
  }

  public getHorizontalMenuItems(): Array<Menu> {
    return horizontalMenuItems;
  }

  public getAdminMenuItems(): Array<Menu> {
    return adminMenuItems;
  }

  public getVendorMenuItems(): Array<Menu> {
    return vendorMenuItems;
  }

  public getCustomerAdminMenuItems(): Array<Menu> {
    return customerAdminMenuItems;
  }

  public expandActiveSubMenu(menu: Array<Menu>) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(item => item.id == menuItem.parentId)[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId) {
    let menuItem = document.getElementById('menu-item-' + menuId);
    let subMenu = document.getElementById('sub-menu-' + menuId);
    if (subMenu) {
      if (subMenu.classList.contains('show')) {
        subMenu.classList.remove('show');
        menuItem.classList.remove('expanded');
      }
      else {
        subMenu.classList.add('show');
        menuItem.classList.add('expanded');
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId) {
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];
    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach(item => {
        if (item.id != menuId) {
          let subMenu = document.getElementById('sub-menu-' + item.id);
          let menuItem = document.getElementById('menu-item-' + item.id);
          if (subMenu) {
            if (subMenu.classList.contains('show')) {
              subMenu.classList.remove('show');
              menuItem.classList.remove('expanded');
            }
          }
        }
      });
    }
  }

  public getAdminCustomMenuItems(): Array<Menu> {
    let permissions = this.sessionDetails['Permissions'];
    let isAdmin = this.sessionDetails['IsAdmin'];
    let vc_Permission=this.sessionDetails['Phone']; 
    var adminMenuItems: Menu[] = new Array()
    if (isAdmin || permissions.length > 0) {
      if (isAdmin || permissions.filter(x => x.ModuleName == 'Orders').length > 0) {
        adminMenuItems.push(new Menu(2, 'Orders', '/xp/ordersNew', null, 'add_shopping_cart', null, true, 0, "white"));
      }
      if (isAdmin || permissions.filter(x => x.ModuleName == 'Capacity').length > 0) {
        adminMenuItems.push(new Menu(22, 'Capacity', '/xp/capacity', null, 'settings_applications', null, true, 0, "white"));
      }
      if (isAdmin || permissions.filter(x => x.ModuleName == 'Load Board').length > 0) {
        adminMenuItems.push(new Menu(30, 'Load Board', '/xp/load-board', null, 'assignment', null, false, 0, "white"));
      }
      if (isAdmin || permissions.filter(x => x.ModuleName == 'Customers').length > 0) {
        adminMenuItems.push(new Menu(3, 'Customers', '', null, 'people', null, true, 0, "white"));
        adminMenuItems.push(new Menu(46, 'Manage Customers', '/xp/user/customers', null, 'person', null, true, 3, "white"))
        //adminMenuItems.push(new Menu(47, 'Consignor/Consignee', '/xp/user/consignor', null, 'person_outline', null, true, 3, "white"))
      }

      if (isAdmin || permissions.filter(x => x.ScreenName == 'Manage Consignor' || x.ScreenName == 'Manage Consignee').length > 0) {
        adminMenuItems.push(new Menu(47, 'Consignor/Consignee', '/xp/user/consignor', null, 'person_outline', null, true, 3, "white"))
      }

      if (isAdmin || permissions.filter(x => x.ModuleName == 'FFV').length > 0) {
        adminMenuItems.push(new Menu(4, 'FFV', '', null, 'directions_car', null, true, 0, "white"));
        // adminMenuItems.push(new Menu(33, 'Drivers Panel', '/xp/user/drivers-panel', null, 'dns', null, true, 4, "white"));
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Manage FFV').length > 0) {
          adminMenuItems.push(new Menu(31, 'Manage FFV', '/xp/user/ffv2', null, 'dns', null, true, 4, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Associates - Admin' || x.ScreenName == 'Associates - Payments').length > 0) {
          adminMenuItems.push(new Menu(35, 'Associates', '/xp/user/associates', null, 'people', null, true, 4, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Drivers Panel - Manage Drivers' || x.ScreenName == 'Drivers Panel - Redemption' || x.ScreenName == 'Drivers Panel - Reward/Payment Log').length > 0) {
          adminMenuItems.push(new Menu(33, 'Drivers Panel', '/xp/user/drivers-panel', null, 'dns', null, true, 4, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Add FFV Route (FFV Routes)')) {
          adminMenuItems.push(new Menu(33, 'Master Routes', '/xp/user/routes', null, 'directions', null, true, 4, "white"));
        }
      }
      if (isAdmin || permissions.filter(x => x.ModuleName == 'Operations' || x.ModuleName == 'Operations LCL' || x.ScreenName == 'Mark Attendance'|| x.ScreenName == 'Certificate Of facts').length > 0) {
        adminMenuItems.push(new Menu(5, 'Operations', '', null, 'record_voice_over', null, true, 0, "white"));
        adminMenuItems.push(new Menu(27, 'Operations FCL', '/xp/operation/operationOne', null, 'track_changes', null, true, 5, "white"));
       
        if (isAdmin || permissions.filter(x => x.ModuleName == 'Operations LCL').length > 0) {
          adminMenuItems.push(new Menu(45, 'Operations LCL', '/xp/operation/operations-lcl', null, 'donut_large', null, true, 5, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Mark Attendance').length > 0) {
          adminMenuItems.push(new Menu(34, 'Mark Attendance', '/xp/operation/markattendance', null, 'today', null, true, 5, "white"));
        }

        if (isAdmin || permissions.filter(x => x.ScreenName == 'Certificate Of facts').length > 0) {
          adminMenuItems.push(new Menu(48, 'Certificate Of Facts (COF)', '/xp/operation/CertificateOfFacts', null, 'event_note', null, true, 5, "white"));
        }

        // if (isAdmin || permissions.filter(x => x.ModuleName == 'Operations' ).length > 0) 
          if(vc_Permission=='8120306854')
          {
          adminMenuItems.push(new Menu(87, 'Pending Trips', '/xp/operation/pending-trips', null, 'today', null, true, 5, "white"))
          }
          if (isAdmin || permissions.filter(x => x.ModuleName == 'Inventory(MHRM)').length > 0) {
            adminMenuItems.push(new Menu(91, 'Inventory(MHRM)', '/xp/operation/Inventory/Inventory/inv-res-items', null,'folder', null, true, 5, "white"));
          }
    }

      if (isAdmin || permissions.filter(x => x.ModuleName == 'Service').length > 0) {
        adminMenuItems.push(new Menu(24, 'Service', '', null, 'accessible', null, true, 0, "white"));
        adminMenuItems.push(new Menu(51, 'Service FCL', '/xp/trips/trip', null, 'accessible', null, true, 24, "white"));
        adminMenuItems.push(new Menu(50, 'MIS', '/xp/trips/manage-customer-mis', null, 'track_changes', null, true, 24, "white"));
        
      }
      if (isAdmin || permissions.filter(x => x.ModuleName == 'Finance').length > 0) {
        adminMenuItems.push(new Menu(6, 'Finance', '', null, 'attach_money', null, true, 0, "white"));
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Executive Summary - XP Finance'
          || x.ScreenName == 'Executive Summary - GST Working - XP GST'
          || x.ScreenName == 'Executive Summary - GST Working - FFV/Associate GST'
          || x.ScreenName == 'Executive Summary - TDS Working - TDS Payables'
          || x.ScreenName == 'Executive Summary - TDS Working - TDS Receivables'
        ).length > 0) {
          adminMenuItems.push(new Menu(28, 'Executive Summary', '/xp/finance/executive-summary', null, 'domain_verification', null, true, 6, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Customer Billing - Total orders'
          || x.ScreenName == 'Customer Billing - Not validated'
          || x.ScreenName == 'Customer Billing - Validate'
          || x.ScreenName == 'Customer Billing - Validated'
          || x.ScreenName == 'Customer Billing - Generate Bill'
          || x.ScreenName == 'Customer Billing - Un-Billed'
          || x.ScreenName == 'Customer Billing - Billed'
        ).length > 0) {
          adminMenuItems.push(new Menu(36, 'Customer Billing', '/xp/finance/Billing', null, 'featured_play_list', null, true, 6, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Receivables - Manage Receivables' || x.ScreenName == 'Receivables - Ageing Analysis').length > 0) {
          adminMenuItems.push(new Menu(29, 'Receivables', '/xp/finance/Receivables', null, 'archive', null, true, 6, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'FFV Billing - Not validated'
          || x.ScreenName == 'FFV Billing - Validate'
          || x.ScreenName == 'FFV Billing - Validated'
          || x.ScreenName == 'FFV Billing - Generate Bill'
          || x.ScreenName == 'FFV Billing - Generate Bill F&V'
          || x.ScreenName == 'FFV Billing - Un-Billed'
          || x.ScreenName == 'FFV Billing - Billed'
          || x.ScreenName == 'FFV Billing - Total orders'
          || x.ScreenName == 'Associate Billing - Not Validated'
          || x.ScreenName == 'Associate Billing - Validated'
          || x.ScreenName == 'Associate Billing - Generate Bill'
          || x.ScreenName == 'Associate Billing - Un-Billed'
          || x.ScreenName == 'Associate Billing - Billed'
        ).length > 0) {
          adminMenuItems.push(new Menu(28, 'FFV/Associate Billing', '/xp/finance/ffv-associate-billing', null, 'featured_play_list', null, true, 6, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Payables - FFV Payables - Manage Payables'
          || x.ScreenName == 'Payables - FFV Payables - Ageing Analysis'
          || x.ScreenName == 'Payables - Associate Payables - Manage Payables'
          || x.ScreenName == 'Payables - Associate Payables - Ageing Analysis'
        ).length > 0) {
          adminMenuItems.push(new Menu(30, 'Payables', '/xp/finance/Payables', null, 'unarchive', null, true, 6, "white"));
        }
      }
      if (isAdmin || permissions.filter(x => x.ModuleName == 'Admin').length > 0) {
        adminMenuItems.push(new Menu(7, 'Admin', '', null, 'person', null, true, 0, "white"));
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Master Data - Post Codes' || x.ScreenName == 'Master Data - Locations'
          || x.ScreenName == 'Master Data - Districts' || x.ScreenName == 'Master Data - States'
          || x.ScreenName == 'Master Data - Branches' || x.ScreenName == 'Master Data - Zones').length > 0) {
          adminMenuItems.push(new Menu(10, 'Master Data', '/xp/admin/masterdata', null, 'bookmarks', null, true, 7, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Users' || x.ScreenName == 'Roles').length > 0) {
          adminMenuItems.push(new Menu(8, 'Roles & Users', '/xp/admin/users', null, 'supervisor_account', null, true, 7, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Contracts').length > 0) {
          adminMenuItems.push(new Menu(13, 'Contracts', '/xp/admin/contracts', null, 'book', null, true, 7, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Vehicle Types').length > 0) {
          adminMenuItems.push(new Menu(18, 'Vehicle Types', '/xp/admin/vehicletype', null, 'airport_shuttle', null, true, 7, "white"));
        }

        if (isAdmin || permissions.filter(x => x.ScreenName == 'Diesel Prices - Dynamic' || x.ScreenName == 'Diesel Prices - Dedicated').length > 0) {
          adminMenuItems.push(new Menu(13, 'Diesel Prices', '/xp/admin/diesel-master', null, 'local_gas_station', null, true, 7, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Price Master - Customer' || x.ScreenName == 'Price Master - FFV').length > 0) {
          adminMenuItems.push(new Menu(13, 'Price Master', '/xp/admin/price-master', null, 'attach_money', null, true, 7, "white"));
        }

        if (isAdmin || permissions.filter(x => x.ScreenName == 'Testimonials').length > 0) {
          adminMenuItems.push(new Menu(19, 'Testimonials', '/xp/admin/testimonials', null, 'feedback', null, true, 7, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Offers - Customer' || x.ScreenName == 'Offers - FFV' || x.ScreenName == 'Offers - Driver' || x.ScreenName == 'Offers - Announcements').length > 0) {
          adminMenuItems.push(new Menu(20, 'Offers', '/xp/admin/offers', null, 'local_offer', null, true, 7, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Settings - Orders' || x.ScreenName == 'Settings - Capacity' || x.ScreenName == 'Settings - Loadboard'
          || x.ScreenName == 'Settings - Customer' || x.ScreenName == 'Settings - FFV' || x.ScreenName == 'Settings - Operations'
          || x.ScreenName == 'Settings - Finance' || x.ScreenName == 'Settings - Service' || x.ScreenName == 'Settings - Admin').length > 0) {
          adminMenuItems.push(new Menu(21, 'Settings', '/xp/admin/settings', null, 'settings_applications', null, true, 7, "white"));
        }
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Event Log').length > 0) {
          adminMenuItems.push(new Menu(37, 'Event log', '/xp/admin/event-log', null, 'settings_applications', null, true, 7, "white"));
        }
        // if(isAdmin || permissions.filter(x => x.screenName == 'Update Consignor Backend').length > 0){
        adminMenuItems.push(new Menu(39, 'Consignor Data', '/xp/admin/consignor-data', null, 'settings_application', null, true, 7, "white"));

        if(vc_Permission=='8120306854')
        {
        adminMenuItems.push(new Menu(40, 'Daily Change', '/xp/admin/daily-change', null, 'bookmarks', null, true, 7, "white"));
        }

        // }
        // if (isAdmin || permissions.filter(x => x.ScreenName == 'Backlog').length > 0) {
        //   adminMenuItems.push(new Menu(37, 'Backlog', '/xp/admin/backlog', null, 'open_in_browser', null, true, 7, "white"));
        // }
      }
      if (isAdmin || permissions.filter(x  => x.ModuleName == 'Approvals' ).length > 0) 
      {
        adminMenuItems.push(new Menu(42, 'Approvals', '', null, 'done_all', null, true, 0, "white"));
        if (isAdmin || permissions.filter(x => x.ScreenName == 'Approve Credit Note').length > 0) {
          adminMenuItems.push(new Menu(81, 'Credit Note', '/xp/approvals/approve-credit-note', null, 'supervisor_account', null, false, 42, "white"));
        }
        // if(isAdmin || permissions.filter(x => x.ScreenName == 'Approve Paid/To-Pay Invoices').length > 0){
        //   adminMenuItems.push(new Menu(82, 'Customer Paid/To-Pay', '/xp/approvals/paid-to-pay-approvals', null, 'book', null, false, 42, "white"));
        // }
        if(isAdmin || permissions.filter(x => x.ScreenName == 'Approvals FFV Payment').length > 0){
          adminMenuItems.push(new Menu(88, 'FFV Payments', '/xp/approvals/ffv-payment/early-payment', null, 'supervisor_account', null, false, 42, "white"));
        }
        if (isAdmin || 
          permissions.filter(x => x.ScreenName == 'Approval>FCL>Pending' 
          || x.ScreenName == 'Approval>FCL>Approved'
          || x.ScreenName == 'Approval>LCL>Pending' 
          || x.ScreenName == 'Approval>LCL>Approved' 
          // || x.ModuleName == 'Operations LCL'
       
         ).length > 0) {
           adminMenuItems.push(new Menu(90, 'FFV Invoice(Dynamic)', '/xp/approvals/FFV-Invoice-Dynamic', null, 'featured_play_list', null, true, 42, "white"));
        }
        // if(isAdmin || permissions.filter(x => x.ScreenName == 'SDA Approvals').length > 0){
        //   adminMenuItems.push(new Menu(89, 'SDA Approvals', '/xp/approvals/sda-approvals',null,  'assignment', null,  false, 42, "white"));
        // }
        }

       {
         
          adminMenuItems.push(new Menu(2, 'Tickets', '/xp/tickets/raise-tickets', null, 'assignment_ind', null, true, 0, "white"));
        }
      

      }

      
    
    //  new Menu(17, 'Route Master', '/xp/admin/networkschedule', null, 'wifi', null, true, 7, "white"),
    // new Menu(23, 'Associates', '/xp/admin/associates', null, 'people', null, true, 7, "white"),
    // new Menu(25, 'Manage Capacity', '/xp/operation/manageCapacity', null, 'library_add_check', null, true, 5, "white"),
    // new Menu(27, 'Manage Orders', '/xp/operation/manageOrders', null, 'shopping_cart', null, true, 5, "white"),
    //new Menu(28, 'Masters', '/xp/finance/Masters', null, 'shopping_cart', null, true, 6, "white"),

    // adminMenuItems=  _.orderBy(adminMenuItems, ['id'], ['asc'])
    return adminMenuItems;
  }

  public checkUserPermissions(screenName) {
    let access = {
      btCreate: false,
      btRead: false,
      btUpdate: false,
      btDelete: false,
      btAllow: false,
      btUserOTP: false,
      btAdminOTP: false
    };
    if (this.sessionDetails != null && this.sessionDetails != undefined && this.sessionDetails != '') {
      let permissions = this.sessionDetails['Permissions'];
      let isAdmin = this.sessionDetails['IsAdmin'];
      if (isAdmin) {
        access.btCreate = access.btRead = access.btUpdate = access.btDelete = true;
        var screenAccess = permissions.filter(x => x.ScreenName == screenName);
        if(screenAccess.length > 0){
          access.btAllow = screenAccess[0].Allow;
        }
        else{
          access.btAllow = false
        }

      } else if (permissions != null && permissions.filter(x => x.ScreenName == screenName).length > 0) {
        var screenAccess = permissions.filter(x => x.ScreenName == screenName);
        access.btCreate = screenAccess[0].Create;
        access.btRead = screenAccess[0].Read;
        access.btUpdate = screenAccess[0].Update;
        access.btDelete = screenAccess[0].Delete;
        access.btAllow = screenAccess[0].Allow;
        access.btUserOTP = screenAccess[0].UserOTP;
        access.btAdminOTP = screenAccess[0].AdminOTP;
      }
    }
    return access;
  }


  //to generate otp
  generateOTP(isAdmin, type) {
    return this._http.get(environment.apiUrl + "api/generate-otp?isAdmin=" + isAdmin + "&type=" + type);
  }
  //to validate otp
  validateOTP(otp, isAdmin) {
    return this._http.get(environment.apiUrl + "api/validate-otp?otp=" + otp + "&isAdmin=" + isAdmin);
  }

  GetGatewayListForUsers(){
    return this._http.get(environment.apiUrl + "api/get-gateway-list-users")
  }
}
