import { Component, Inject, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadBoardService } from 'src/app/xp-admin-web/load-board/load-board.service';
import { BookingMenuService } from 'src/app/xp-admin-web/operations/operation-one/booking-menu/booking-menu.service';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { AddDriverVehicleDialogComponent } from '../add-driver-vehicle-dialog/add-driver-vehicle-dialog.component';
import { AlertService } from '../services/alert.service';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';
import { environment } from 'src/environments/environment';
import { FfvDetailsService } from 'src/app/xp-admin-web/users/ffv2/ffv-details.service';

@Component({
  selector: 'app-assign-lcl-vehicle-dialog',
  templateUrl: './assign-lcl-vehicle-dialog.component.html',
  styleUrls: ['./assign-lcl-vehicle-dialog.component.scss']
})

///Express
export class AssignLclVehicleDialogComponent implements OnInit {

  Customer: any;
  Vehicle_Type: any;
  vc_bookingid: any;
  VehicleSearch = [];
  ffvs = [];
  vehicles = [];
  drivers = [];
  type = '';
  details;
  isManifestAvailable: boolean = false;
  customer;
  searchedVehicles: any[] = [];
  DriverSearch: any[] = [];
  FFVSearch: any[] = [];
  addEditForm: FormGroup;
  TripRemark: any;
  selectedFFVName: string = ''; 

  totalWeight = 0;
  Weight = 0;
  bookingId;
  xptsno: any;
  vehiclelist: any;
  vehicletype: any;
  manifest: any;
  gateway:any;
  makeDriverDisabled: boolean = false;

  constructor(public adminService : AdminService,public fb: FormBuilder, public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _LclPickupService: LclPickUpService,
    private _bookingService: BookingMenuService, private dialog : MatDialog, private alertService : AlertService,
    private _FfvDetailsService: FfvDetailsService) {
    this.addEditForm = this.fb.group({
      int_manifest_id: new FormControl(''),
      OrderDetailId: new FormControl(''),
      FFVId: new FormControl('', Validators.required),
      FFVName: new FormControl(''),
      VehicleId: new FormControl(''),
      Vehicle: new FormControl(''),
      VehicleNo: new FormControl(''),
      VehicleTypeId: new FormControl(''),
      VehicleTypeName: new FormControl(''),
      DriverId: new FormControl(''),
      DriverName: new FormControl(''),
      DriverNo: new FormControl(''),
      FFVSearch: new FormControl(''),
      VehicleSearch: new FormControl(''),
      DriverSearch: new FormControl(''),
      ManifestNo: new FormControl(''),
      IsConfirmed: new FormControl(false),
      dec_dynamic_freight:new FormControl(),
      ScreenName:new FormControl(),
      TripRemark: new FormControl(''),

    })
  }
   AddScreenName:any;
  isDynamicFright:boolean;
  OrderIdList = [];
  ngOnInit(): void {
    this.isDynamicFright=false;
    if (this.data != null) {
      this.AddScreenName=this.data['ScreenName'];
      this.addEditForm.controls['ScreenName'].setValue(this.data['ScreenName']);
      this.Weight = this.data.data['Appox_weight'];
      this.TripRemark = this.data.data['TripRemark'];
      this.Vehicle_Type = this.data.data['Customer'] || this.data.data['CustomerName'];
      this.Customer = this.data.data['Vehicle_Type'] || this.data.data['VehicleType'];
      this.vc_bookingid = this.data.data['vc_bookingid'] || this.data.data['strBookingId'];
      this.bookingId = this.data.data['int_bookingid'] || this.data.data['BookingId'];
      this.xptsno = this.data.data['XPTSNo'];
      this.gateway = this.data.data['vc_origin_branch'];
      this.manifest = this.data.data['int_manifest_id'] || this.data.data['XPTSId'] || this.data.data['ManifestId'];
      this.ffvs = this.data['ffvs'];
      this.type = this.data['type'];
      this.details = this.data['data'];
      this.vehicletype = this.details['vehicle_type_id'] || this.details['VehicleTypeId'];
      this.addEditForm.controls['int_manifest_id'].setValue(this.manifest)
      if (this.details['FFVId'] != null) {
        this.addEditForm.controls['FFVId'].setValue(this.details.FFVId);
        this.addEditForm.controls['FFVName'].setValue(this.details.FFVName);
        this.addEditForm.controls['TripRemark'].setValue(this.details['TripRemark']);

        this.onFFVChange(this.details.FFVId);
      }

    }

    this.getVehicleTypes()

    // this.details.forEach(element => {
    //   this.OrderIdList.push(element.int_bookingid);
    //   this.totalWeight += Number(element.Appox_weight)
    // });
    // this.Weight = this.totalWeight ;
    // this.totalWeight = 0;
    // this.addEditForm.controls['BookingIds'].setValue(this.OrderIdList)
  }
  close() {
    this.dialogRef.close();
  }

  vehiclesTypes : any[] = []
  vehicleTypesEligibleForRequestedVehicleType : any[] = []
  getVehicleTypes(){
    this.adminService.getVehicleTypes().subscribe(response=>{
      if(response !=null){
        const responseData = response["Data"]
        if(responseData != null && responseData != undefined){
          this.vehiclesTypes = responseData.sort((a, b) => a.Payload - b.Payload);
          this.checkVehicleIsWithInRequestedPayload(this.Customer)
        }
      }
    })
  }


  checkVehicleIsWithInRequestedPayload(vehicleType : string){
    const requestedVehicleTypeDetails = this.vehiclesTypes.filter(data=> data['VehicleType'] == vehicleType)
    const requestedPayload = requestedVehicleTypeDetails.length > 0? this.vehiclesTypes.filter(data=> data['VehicleType'] == vehicleType)[0]["Payload"] : null
    this.vehicleTypesEligibleForRequestedVehicleType =  requestedPayload != null? this.vehiclesTypes.filter(data=> data['Payload'] >= requestedPayload) : []

  }

  checkIfSelectedVehiclePayloadIsLessThanRequestPayload(vehicleNumber : string,vehicleType : string) : boolean {
    const isVehicleEligible =  this.vehicleTypesEligibleForRequestedVehicleType.map(data => data['VehicleType']).includes(vehicleType)

    if(!isVehicleEligible)  this.alertService.createAlert(`Payload of ${vehicleNumber} is less than Requested Vehicle (${this.Customer}) Payload`,2)
     return isVehicleEligible
  }

  onFFVChange(e): void {
    this.addEditForm.controls['FFVName'].setValue(null);
    this.addEditForm.controls['Vehicle'].setValue(null);
    this.addEditForm.controls['VehicleId'].setValue(null);
    this.addEditForm.controls['VehicleNo'].setValue(null);
    this.addEditForm.controls['DriverId'].setValue(null);
    this.addEditForm.controls['DriverName'].setValue(null);
    this.addEditForm.controls['DriverNo'].setValue(null);

    if (e != null) {
      const selectedFFV = this.ffvs.find(x => x.FFVId === e);
      const ffvName = selectedFFV?.FFVName || null;
      this.addEditForm.controls['FFVName'].setValue(ffvName);
      this.selectedFFVName = ffvName; // Set the selected FFV name

      if (this.AddScreenName !== "LCL Express") {
        const api = `${environment.coreapiUrl}api/v2/get-all-vehcile-by-ffvid-latlong?FFVId=${e}&Lat=${this.details['OriginLat']}&Long=${this.details['OriginLong']}`;
        this._LclPickupService.getData(api).subscribe(data => {
          if (data != null) {
            this.vehiclelist = data['Data'];
            this.vehicles = data['Data'].filter(element => element.VehicleTypeId === this.vehicletype);
          }
        });
      }

      this._LclPickupService.getLoadboardVehiclesDriversforlcl(e, this.bookingId, 'origin').subscribe(data => {
        if (data != null) {
          if (this.AddScreenName === "LCL Express") {
            this.vehiclelist = data['Data']['Vehicles'];
            this.vehicles = data['Data']['AllVehicles'].filter(element => element.VehicleTypeId === this.vehicletype);
          }
          this.drivers = data['Data']['Drivers'];
        }
      });
    }
  }

  viewOrderSummary(val) {

  }

  onVehicleNoChange(keyword) {
    var selectedFFVName = this.selectedFFVName
    let api = environment.coreapiUrl + `api/v2/get-manualvehcile-by-ffvid-latlong?FFVId=${this.addEditForm.controls['FFVId'].value}&VehicleNo=${keyword}&Lat=${this.details['OriginLat']}&Long=${this.details['OriginLong']}`
    if (keyword != null && keyword != '' && keyword.length >= 8) {
      this._LclPickupService.getData(api).subscribe(data => {
        if (data != null) {
          this.searchedVehicles = data['Data'];
        // if(selectedFFVName.toUpperCase() == 'INSTANT TRANSPORT SOLUTION PRIVATE LIMITED'){
        //     this.instantVehicleDriver(keyword);
        //   }
        }
      });
    }
  }
  Manifest: any;
  onVehicleChange(e) {
    this.addEditForm.controls['VehicleNo'].setValue(null);
    this.addEditForm.controls['Vehicle'].setValue(null);
    this.addEditForm.controls['VehicleTypeId'].setValue(null);
    this.addEditForm.controls['VehicleTypeName'].setValue(null);
    this.addEditForm.controls['DriverId'].setValue(null);
    this.addEditForm.controls['DriverName'].setValue(null);
    this.addEditForm.controls['DriverNo'].setValue(null);
    if (e != null) {
      let vehicleNo = this.vehiclelist.filter(x => x.VehicleId == e)[0].VehicleNo;
      this.addEditForm.controls['VehicleNo'].setValue(vehicleNo);
        //Tomesh Added---
        let int_vehicleCapacityType = this.vehiclelist.filter(x => x.VehicleId == e)[0].CapacityTypeId;
        if(int_vehicleCapacityType==52)
        {
          this.isDynamicFright=true;
        }
        else
        {
          this.isDynamicFright=false;
        }
        // End-----
      let vehicletypeid = this.vehiclelist.filter(x => x.VehicleId == e)[0].VehicleTypeId;
      this.addEditForm.controls['VehicleTypeId'].setValue(vehicletypeid);
      let vehicletypename = this.vehiclelist.filter(x => x.VehicleId == e)[0].VehicleType;
      this.addEditForm.controls['VehicleTypeName'].setValue(vehicletypename);
      if(this.selectedFFVName.toUpperCase() == 'INSTANT TRANSPORT SOLUTION PRIVATE LIMITED'){
        this.instantVehicleDriver(vehicleNo);
      }else{
      let lastDriverId = this.vehiclelist.filter(x => x.VehicleId == e)[0].LastDriverId;
      if (lastDriverId != null && this.drivers.filter(x => x.DriverId == lastDriverId).length > 0) {
        this.addEditForm.controls['DriverId'].setValue(lastDriverId);
        this.onDriverChange(lastDriverId);
      } else {
        this.addEditForm.controls['DriverId'].setValue(null);
        this.addEditForm.controls['DriverName'].setValue(null);
        this.addEditForm.controls['DriverNo'].setValue(null);
      }
    }

    }
    this._LclPickupService.getOldManifestNumberForNewOrderId(e).subscribe(data => {
      this.isManifestAvailable = false;
      if (data['Data'].length > 0) {
        this.isManifestAvailable = true
      }
      this.Manifest = data['Data'][0];
      let vc_manifestNo = this.Manifest['vc_manifestNo'];
      let int_manifestId = this.Manifest['int_manifest_Id'];
      this.addEditForm.controls['ManifestNo'].setValue(vc_manifestNo);
    })
  }
  instantVehicleDriver(vehicleNo){
    let driversList = this.drivers
    this._FfvDetailsService.validateINSTANTvehicleDriver(vehicleNo).subscribe(
      (response: any) => {
        if (response && response.Success && response.Data) {
          let driver = driversList.filter(x => x.PhoneNumber == response.Data.DriverMobileNumber)[0];
          if(driver != null){
          this.addEditForm.controls['DriverName'].setValue(driver.DriverName);
          this.addEditForm.controls['DriverId'].setValue(driver.DriverId);
          this.addEditForm.controls['DriverNo'].setValue(response.Data.DriverMobileNumber);
          this.makeDriverDisabled = true;
        }else{
          this.alertService.createAlert('This driver does not exist. Please add from other screen.',0)
          this.addEditForm.controls['DriverId'].setValue(null);
          this.addEditForm.controls['DriverName'].setValue(null);
          this.addEditForm.controls['DriverNo'].setValue(null);
        }
        } else {
          console.error('Invalid response:', response);
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    ); 
  }
  onDriverChange(e) {
    this.addEditForm.controls['DriverName'].setValue(null);
    this.addEditForm.controls['DriverNo'].setValue(null);
    if (e != null) {
      let driver = this.drivers.filter(x => x.DriverId == e)[0];
      let driverName = driver.DriverName;
      let driverNo = driver.PhoneNumber == null || driver.PhoneNumber == undefined ? driver.DriverNo : driver.PhoneNumber;
      this.addEditForm.controls['DriverName'].setValue(driverName);
      this.addEditForm.controls['DriverNo'].setValue(driverNo);
    }
  }


  onManualVehicleChange(e) {
   
    this.addEditForm.controls['VehicleNo'].setValue(null);
    this.addEditForm.controls['DriverId'].setValue(null);
    this.addEditForm.controls['DriverName'].setValue(null);
    this.addEditForm.controls['DriverNo'].setValue(null);
    if (e != null) {
      let vehicle = this.searchedVehicles.filter(x => x.VehicleId == e)[0];

     const isVehiclePayloadValid =  true
     //this.checkIfSelectedVehiclePayloadIsLessThanRequestPayload(vehicle["VehicleNo"],vehicle["VehicleType"])
     if(isVehiclePayloadValid) {
      if (this.vehicles.filter(x => x.VehicleId == e).length == 0) {
        let newVehicle = {
          VehicleId: vehicle['VehicleId'],
          VehicleNo: vehicle['VehicleNo'],
          VehicleType: vehicle['VehicleType'],
          ServiceClass: vehicle['ServiceClass'],
          LastDriverId: vehicle['LastDriverId']
        };
        this.vehicles.push(vehicle);
      }
      this.addEditForm.controls['VehicleId'].setValue(e);
      this.addEditForm.controls['VehicleNo'].setValue(vehicle.VehicleNo);
            //Tomesh Added---
            let int_vehicleCapacityType = this.searchedVehicles.filter(x => x.VehicleId == e)[0].CapacityTypeId;
            if(int_vehicleCapacityType==52)
            {
              this.isDynamicFright=true;
            }
            else
            {
              this.isDynamicFright=false;
            }
            // End-----
    if(this.selectedFFVName.toUpperCase() == 'INSTANT TRANSPORT SOLUTION PRIVATE LIMITED'){
          this.instantVehicleDriver(vehicle['VehicleNo']);
            }else{
      let lastDriverId = vehicle.LastDriverId;
      if (lastDriverId != null && this.drivers.filter(x => x.DriverId == lastDriverId).length > 0) {
        this.addEditForm.controls['DriverId'].setValue(lastDriverId);
        this.onDriverChange(lastDriverId);
      } else {
        this.addEditForm.controls['DriverId'].setValue(null);
        this.addEditForm.controls['DriverName'].setValue(null);
        this.addEditForm.controls['DriverNo'].setValue(null);
      }
    }
     }
     else {
      this.addEditForm.controls['Vehicle'].setValue(null);
      this.addEditForm.controls['VehicleId'].setValue(null);
     }
    }
  }
  isSubmitting = false;
  bt_isBtnDisable:boolean=false;
  onSave(isConfirmed): void {
    debugger
    this.bt_isBtnDisable = true;
  
    const ffvId = this.addEditForm.controls['FFVId'].value;
    const vehicleId = this.addEditForm.controls['VehicleId'].value;
    const driverId = this.addEditForm.controls['DriverId'].value;
    const driverNo = this.addEditForm.controls['DriverNo'].value;
    const TripRemark = this.addEditForm.controls['TripRemark'].value;
  
    if (
      (isConfirmed === true &&
        (ffvId == null || ffvId === '' ||
          vehicleId == null || vehicleId === '' ||
          driverId == null || driverId === '' ||
          driverNo == null || driverNo === '' 
          // TripRemark == null || TripRemark === ''
        )) ||
      (isConfirmed === false && (ffvId == null || ffvId === ''))
    ) {
      this.addEditForm.controls['FFVId'].markAsTouched();
      this.addEditForm.controls['VehicleId'].markAsTouched();
      this.addEditForm.controls['DriverId'].markAsTouched();
      this.addEditForm.controls['DriverNo'].markAsTouched();
      this.addEditForm.controls['TripRemark'].markAsTouched();
      this.bt_isBtnDisable = false;
    } else {
      this.isSubmitting = true;
      this.addEditForm.controls['IsConfirmed'].setValue(isConfirmed);
  
      this._LclPickupService.AllocateFFVWithVehicleDriver(this.addEditForm.value).subscribe(data => {
        this.isSubmitting = false;
        if (data['Success']) {
          localStorage.setItem('operationsUpdated1', 'true');
          this.dialogRef.close(data['Success']);
          this.bt_isBtnDisable = false;
        }
      });
    }
  }

  openAddDriverdialog() {
    const ffvId  = this.addEditForm.controls['FFVId'].value
    const Vehicle = this.addEditForm.controls['VehicleNo'].value
    const DriverName = this.addEditForm.controls['DriverName'].value;
    const DriverNumber = this.addEditForm.controls['DriverNo'].value;
    const DriverId = this.addEditForm.controls['DriverId'].value;

    if((ffvId !=null && ffvId !=undefined && ffvId !='')){
      const dialogRef = this.dialog.open(AddDriverVehicleDialogComponent, {
        data: { 
          data : this.data, 
          ffvId:ffvId, 
          VehicleType : this.Customer, 
          VehicleTypeId : this.vehicletype, 
          ManifestId : this.manifest, 
          VehicleNo : Vehicle,
          VehicleList : this.vehiclelist,
          ScreenName:this.AddScreenName,
          DriverName: DriverName,
          DriverNumber: DriverNumber,
          DriverId: DriverId
        },
        height: 'auto',
        width: '800px',
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(d => {
        if(d == true){
          this.dialogRef.close(true);
        }
      });
    }
    else this.alertService.createAlert("Please select FFV",2)
  
  }    

}


@Pipe({
  name: 'stringFilterBy'
})
export class StringFilterByPipe implements PipeTransform {

  transform(arr: any[], searchText: string, fieldName?: string): any[] {
    if (!arr) return [];
    if (!searchText) return arr;
    searchText = searchText.toLowerCase();
    return arr.filter((it: any) => {
      if (typeof it == 'string') {
        return it.toLowerCase().includes(searchText);
      } else if (typeof it == 'number') {
        return it.toString().toLowerCase().includes(searchText);
      } else {
        return it[fieldName].toLowerCase().includes(searchText);
      }

    });
  }

}

