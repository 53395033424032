import { Component } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

    public pageTitle: string;
    public moduleTitle: string;
    public subTitle: string = "test";
    public description: {};
    public breadcrumbs: {
        name: string;
        url: string
    }[] = [];

    public settings: Settings;
    constructor(public appSettings: AppSettings,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public title: Title) {
        this.settings = this.appSettings.settings;
        this.description = {
            "No Permissions": "No Permissions",
            "Dashboard": "An overview representation of the customer admin login is represented here.",
            "Chat": "All messages can be viewed here",
            "Placement": "Placement",
            "Upcoming": "Upcoming",
            "InTransit": "InTransit",
            "Completed": "Completed",
            "Cancel / Rejected": "Cancel / Rejected",
            "Location Master": "Location Master",
            "Customer Billing": "Customer Billing can be managed using this screen.",
            // "Receivables": "Receivables",
            // "Payables": "Payables",
            "Masters": "Masters",
            "Analytics": 'Analytical representation  of the application shown here',
            "Users": "Customer users can be managed in this screen",
            'Users History Log': 'All Change History can be seen here',
            "Roles": 'Roles are managed in this screen',
            'Roles History Log': 'All Change History can be seen here',
            "Permissions": "This page is used to manage role permissions",
            "Manage Capacity": 'Manage Capacity',
            "Mark Attendance": "Mark Attendance",
            "Certificate of Facts": "User can generate Certificate Of facts",
            "Enquiry": 'Enquiry',
   
            

            "Order Summary": "Complete summary of an order can be viewed here",

            "Operations One": {
                "Booking": "Bookings are managed using this screen.",
                "Trips": "Trips are managed using this screen.",
                "Delivery": "Deliveries are managed using this screen.",
                "Print": "Please click any link for XPCN or XPLS or XPTS or POD to print the information.",
                "Manual Updation": "Manual updates can be made using this screen.",
                // "Admin Control": "Please click any link for XPCN or XPLS or XPTS to update the information.",
                "Edit XPCN": "XPCN can be edited and managed using this screen.",
                "Eway Bill": "Eway Bill are managed using this screen.",
                "Edit XPLS": "XPLS can be finalized and managed using this screen.",
                
            },
            "Admin Control":{
                    "Edit Orders":"Orders can be edited here.",
                    "Remove Timestamps":"Timestamps of an order can be Remove from here.",
                    "Change Vehicles":"Vehicle can be changed from  here.",
                    "Unfinalized XPCN":"XPCN can be Unfinalized from  here."
                },
            "mark-attendace":
            {
                "pending-vehicle-mark-attendace":"pending-vehicle-mark-attendace managed using this screen.",
                "billed-vehicle-mark-attendace":"billed-vehicle-mark-attendace managed using this screen."
            },
            

            "Delivery":
            {
                "LCL Breakbulk Delivery": "LCL Breakbulk Delivery are managed using this screen.",
                "LCL Agreegation Delivery":"LCL Agreegation Delivery are managed using this screen."
            },
            "LCL Breakbulk Delivery":{
                "Delivery Planning": "Delivery Planning is managed using this screen.",
                "Order Assigment":"Order Assigment are managed using this screen.",
                "Allocate Vehicle":"Allocate Vehicle are managed using this screen.",
                "Validate Placement": "Validate Placement can be managed using this screen.",
                "Generate Loading Tally":"Generate Loading Tally are managed using this screen.",
                "Close Loading Tally":"Close Loading Tally are managed using this screen.",
                "Update Part-B":"Update Part-B are managed using this screen.",
                "Validate XPTS" : "XPTS can be validated using this screen.",
                "Order Delivered":"Order Delivered are managed using this screen.",
                "Check/Upload POD":"Check/Upload POD are managed using this screen.",
                "Validated-pod":"Validated POD are managed using this screen.",
                "Undelivered":"Undelivered Orders are managed using this screen.",
                "Close XPTS":"Close XPTS are managed using this screen.",
                "Total Closed XPTS":"Total Closed XPTS are managed using this screen.",
                "Consolidated E-Way Bill" : "Consolidated E-Way Bill is managed using this screen.",
                "LCL Order Delivered":"Delivered LCL Orders are managed using this screen.",
                "LCL Order Intransit":"Intransit LCL Orders are managed using this screen.",
                "Delivery POD History":"Delivery POD History Orders are managed using this screen.",
                "Cancelled XPTS": "Cancelled XPTS are managed using this screen.",
                "Generate PODFN": "PODFN generated using this screen.",
                "Dispatch PODFN": "PODFN Dispatched using this screen.",
                "Receipt PODFN": "PODFN Receipt using this screen.",
                "Short Excess PODFN" : "Short Excess pods manage using this screen"
            },
            "Undelivered":{
                "LCL XPCN Arrived":"LCL XPCN Arrived are managed using this screen.",
                "LCL Undelivered":"LCL Undelivered are managed using this screen.",
            },

            "Operations-LCL": {
                "Pick-Up": "Pick-Ups are managed using this screen.",
                "Trips": "Trips are managed using this screen.",
                "Transhipment": "Transhipment are managed using this screen.",
                "LCL Delivery": "Deliveries are managed using this screen.",
                "Inventory Management": "Inventory Management is done using this screen.",
                "Print-LCL": "Please click any link for XPCN or XPLS or XPTS or POD to print the information.",
                "Admin Control": "Please click any link for XPCN or XPLS or XPTS to update the information.",
                "Edit LCL XPCN": "XPCN can be edited and managed using this screen.",
                "Tracking / MIS": "XPCN can be tracked or MIS details of customers can be generated here.",
                "LCL Billing":"Paid/To-Pay billing can be managed using this screen.",
                "Rebook XPCN" : "Rebooked XPCN is managed using this screen.",
                "XPCN Rebooking" : "XPCN can be rebooked using this screen."
            },
            "Inventory Management":{
                "LCL Tech Utilisation":"LCL Tech Utilisation is managed using this screen.",
                "LCL Gateway Performance":"LCL Gateway Performance is managed using this screen.",
                "LCL Vehicle Utilisation":"LCL Vehicle Utilisation is managed using this screen.",
                "Dimensions":  "Dimension of XPCN & Bxoes is viewed in the screem."
            },
            "Dimensions":{
                "Dimensions HU":"Boxes Dimensions variation is viewed using this screen",
                "Dimensions XPCN":"XPCN Weight's variation is viewed using this screen"
            },

            "Print-LCL":{
                "print-xpcn":"",
                "print-xpts":"",
                "print-manifest":"",
                "print-sticker": "",
            },

            "LCL Paid Express Billing":{
                "LCL Paid Express Billing": "Paid Billing is managed using this screen.",
                "LCL To Pay Express Billing": "To Pay Billing is managed using this screen.",
                },
            "LCL Paid Breakbulk Billing":{
                "LCL Paid Breakbulk/DD Billing": "Paid Billing is managed using this screen.",
                "LCL To Pay Breakbulk/DD Billing": "To Pay Billing is managed using this screen.",
                },

            "Pick-Up": {
                "Pickup Executive": "Executive assign to the booking using this screen.",
                "Order Assignment": "Order Assignment can be managed using this screen.",
                "Allocate Vehicle": "Vehicle Allocation is done using this screen.",
                "Add XPCN": "XPCN added using this screen.",
                "Edit XPCN": "XPCN can be edited using this screen.",
                "Prepare XPCN": "Prepare XPCNs can be managed using this screen.",
                "Finalize XPCN": "Finalize XPCNs can be managed using this screen.",
                "Order Picked": "Order Picked can be managed using this screen.",
                "Cancelled XPTS": "Cancelled XPTS managed using this screen",
                "Total Trips": "All Trips managed using this screen",
                "Not Connected": "Order Not Connected can be managed using this screen.",
                "Manifest" : "Manifest can be managed using this screen",
                "Cancelled Orders" : "Cancelled Orders can be managed using this screen",
                "Cancelled XPCN":"Cancelled XPCNs can be managed using this screen",
                "Download Sticker":"Stickers download can be managed using this screen.",
                "Validate Placement":"Vehicle placement validated using this screen.",
                "Generate Loading Tally": "All Generate Loading Tally can be managed using this screen",
                "Close Loading Tally":"All Close Loading Tally can be managed using this screen",
                "Generate Consolidate Eway Bill":"All Generate Consolidate eway bill can be managed using this screen",
                "Validate XPTS": "All XPTS can be validated here",
                "Total Dispatch Trips": " All Dispatch Trips can be managed here.",
                "Update Part-B":"Part-B can be managed using this screen.",
                "Trip Sheet": "Trip Sheet can be managed using this screen.",
                "Cancelled LCL Bookings": " All Cancelled bookings can be managed here.",
                "Cancellation XPCN":" All Cancellation XPCN can be managed here.",
                "Cancelled LCL XPCNs":" All Cancelled xpcns can be managed here.",
                "Short-Excess" : "All Boxes of Short/Excess Managed here.",
                "Routing" : "Routing can be managed using this screen.",
                "XPCN Issued":"XPCN Issued Can Be managed using this screen",
                "Picked":"Picked can Be managed using this screen",
                "Dispatched":"Dispatched XPCN Issued Can Be managed using this screen",
                "D2C Booking":"New D2C Bookings booked using this screen",
                "Express Door to Customer":"Executive assign to the booking using this screen.",
                "Move To Inventory":"Booking will be move to Inventory managed this screen",
                "Test":"Test screen work"
            },


            "Transhipment": {
                "LCL Arrivals":"Order Arrivals can be managed using this screen.",
                "LCL Despatch": "Order Despatch can be managed using this screen.",
            },
            


            "LCL Arrivals": {
                "Inbound Trips": "inbound-trips can be managed using this screen",
                "LCL Unloading": "lcl-unloading can be managed using this screen",
                "LCL Short Excess":"LCL Short Excess can be managed using this screen",

                "LCL Closed Arrived Trips":"LCL Closed Arrived Trips can be managed using this screen",

            },
            "Inbound Trips":{
                "Inbound Booking Arrival":"inbound-booking-arrival can be managed using this screen",
                "Inbound Linehaul Arrival":"inbound-linehaul-arrival can be managed using this screen",
                "Inbound Via Point Arrival":"inbound-via-point-arrival can be managed using this screen",
            },
        
            "Inbound Booking Arrival":{
                "LCL Arrived": "lcl-arrived can be managed using this screen",
                "LCL Intransit": "lcl-intransit can be managed using this screen",
            },

            "LCL Unloading":{
                "LCL Booking Arrival": "LCL Booking Arrival can be managed using this screen",
                "LCL Linehaul Arrival": "LCL Linehaul Arrival can be managed using this screen",
                "LCL Via Point Arrival": "LCL Via Point Arrival can be managed by using this screen",
            },


            "LCL Dispatch":{
                "close loading":"Order Close Loading Tally can be managed using this screen.",
                "Load Planner": "Order Load Planning can be managed using this screen.",
                "Raise Indent Linehaul": "Raise Indent Linehaul",
                "Assign Vehicle": "Order Assign Vehicle can be managed using this screen.",
                "Validate Placement": "Validate Placement can be managed using this screen.",
                "Generate Loading Tally": "All Generate Loading Tally can be managed using this screen",
                "Close Loading Tally":"All Close Loading Tally can be managed using this screen",
                "Validate XPTS": "All XPTS can he validate here",
                "Total Dispatch Trips": " All Dispatch Trips can be managed here",
                "Part Dispatch Trips": " All Part Dispatch Trips can be managed here",
                "Raise Case": " All Raise case are managed here",
                
            },

            "Print": {
                "Print XPCN": "Prints of XPCN can be managed using this screen.",
                "Print XPLS": "Prints of XPLS can be managed using this screen.",
                "Print XPTS": "Prints of XPTS can be managed using this screen.",
                "Print POD Forwarding Notes": "Prints of POD Forwarding Notes can be managed using this screen.",
                "Print Paid Bills": "Prints of Paid Bills can be managed using this screen.",
                "Print To-Pay Bills": "Prints of To-Pay Bills can be managed using this screen.",
                "Print LCL Aggregation Order": "Prints of LCL Aggregation Order can be managed using this screen.",
                "Print LCL Stickers": "Print all Stickers"
            },

            "Booking": {
                "Confirmed Orders": "Confirmed Orders can be managed using this screen.",
                "Prepare Provisional XPCN": "Provisional XPCNs can be managed using this screen.",
                "Issue XPLS": "XPLS can be issued and managed using this screen.",
                "Finalise XPCN": "This screen is used to enter details for finalization of XPCN",
                "Preview XPCN": "This screen is used to preview XPCN",
                "Issue XPLS Form": "XPLS can be Added or Updated here",
                "Cancellation of XPCN / XPLS": "Cancellation of XPCN or XPLS can be done here.",
                "Generate Bill For Paid Booking": "Bills for Paid Booking can be generated using this screen.",
                "Update Payment For Paid Booking": "Bills for Paid Booking can be updated using this screen.",
                "Generate Daily Status Report": "Daily Sales Report is generated and managed using this screen.",
                "Update Branch Finance Report": "Branch Finance Report is managed using this screen.",
                "Tracking Map": "Truck can be tracked using this screen.",
            },
            "Trips": {
                "Prepare / Validate XPTS": "XPTS can be prepared and validated using this screen.",
                "Update / Validate Placement": "Truck placements can be managed using this screen.",
                "Update / Validate Loading": "Truck loading can be managed using this screen.",
                "Update / Validate Arrival & Departure": "Truck arrivals and departures can be managed using this screen.",
                "Case Reporting": "This screen is used to manage cases of trips.",
                'Case Reporting History Log': 'All Change History can be seen here',
            },
            "LCL Delivery": {
                "Update / Validate Arrivals": "Truck arrivals at delivery locations are managed using this screen.",
                "Update / Validate Unloading": "Truck's unloading at delivery locations is managed using this screen.",
                "Update / Validate Delivery": "Truck deliveries are managed using this screen.",
                "Validate / Upload POD And Close XPTS": "Closure of XPTS and PODs are managed using this screen.",


                "Generate Bill For To-Pay": "Bills for To-Pay are generated using this screen.",
                "Update Payment For To-Pay": "Bills for To-Pay are updated using this screen.",
                "Generate Daily Delivery Report": "Daily Delivery Report is generated and managed using this screen.",
                "Update Delivery Finance Report": "Delivery Finance Report is managed using this screen.",
                "POD Forwarding Note": "PODs are managed using this screen.",
                "Case Reporting": 'This screen is used to view the summary of cases.',
                'Case Reporting History Log': 'All Change History can be seen here',
                'Total Orders': 'All Completed Total Orders can be seen here',



            },
            "Eway Bill Alert":{
                "Expiry Notification":"This screen is used to manage the Expired Eway Bills.",
                "Auto Updated":"This screen is used to manage the Auto Updated Eway Bills.",
                "Exception Report":"This screen is used to manage the Execeptions in Eway Bills.",
                "Other Ewaybills":"This screen is used to view other Eway Bills."
            },
            "Eway Bill Portal":{
                "Print Eway Bill":"This screen is used to print Eway Bills.",
                "Update Part-B":"This screen is used to update Part-B.",
                "Generate CEWB":"This screen is used to generate Consolidated Eway Bills."
            },
            "Unclaimed Goods":{
                "Unclaimed Goods":"This screen is used to manage Unclaimed Goods",
                "Claimed Goods":"This screen is used to manage Claimed Goods",
            },
            "Print Deviation":{
                "Rebooking Advice":"This screen is used to manage Rebooking Advice",
                "Cancel Deviation":"This screen is used to manage Cancel Deviation",
            },
            "Case Reporting": {
                "Manage Cases": "This screen is used to manage cases of trips.",
                "Manage Summary": "This screen is used to view the summary of cases.",

            },
            "Case Reporting Delivery": {
                "Manage Cases": "This screen is used to manage cases of trips.",
                "Case Reporting": "This screen is used to view the summary of cases."
            },
            "FCL Operations": "The operations activities for FCL shipments are managed here.",
            //  "FCL Operations": {
            //      "Booking":"Booking activities are managed here.",
            //      "Trips":"Trips are managed here.",
            //      "Delivery":"Deliveries are managed here.",
            //      "Exception-Management":"Exceptions are managed using this screen.",
            //      "Print":"Prints can be accessed from this screen. ",
            //  },
            "Agreements": "Agreements",
            "TDSRegister": "TDSRegister",
            "GSTRegister": "GSTRegister",
            "BlockUnblock": "BlockUnblock",
            "P&L": "P&L",
            "Menu Search": "Search is done in this screen.",
            'Contracts': 'All the contractual information can be tracked here',



            'Contracts History Log': 'All Change History can be seen here',



            "Price Master": {
                'Adhoc Pricing - Customer': 'All Customer Adhoc Prices can be seen here',
                'Adhoc Pricing Log - Customer': 'All Customer Adhoc Prices log can be seen here',
                'Customer Price Master': 'All Customer Prices can be seen here',
                'Customer Price Master History Log': 'All Change History can be seen here',
                'FFV Price Master': 'All FFV Prices  can be seen here',
                'FFV Price Master History Log': 'All Change History can be seen here',
                'Customer Halting Charges' : 'All Customers Halting Charges are managed here'


            },

            "Load Board": 'Load can be viewed and managed here',
            "FFV Not Assigned": 'Load can be viewed and managed here',
            "Vehicle Not Assigned": 'Load can be viewed and managed here',
            "Confirmed Loads": 'Load can be viewed and managed here',
            "Booked Loads": 'Load can be viewed and managed here',




            "Diesel Prices": {
                'Dynamic Diesel Prices': 'Dynamic Diesel Prices can be viewed and managed here',
                'Dynamic Diesel Prices History Log': 'All Change History can be seen here',
                'Dedicated Diesel Prices': 'Dedicated Diesel Prices can be viewed and managed here',
                'Dedicated Diesel Prices History Log': 'All Change History can be seen here',
            },

            "Route Master": 'All Routes can be viewed and managed here',
            "Messages": "All messages can be viewed here",
            "Notifications": "All notifications can be viewed here",
            "Associates": {
                "Associates": 'Associates Administration is managed here',
                "Payments": 'Associates payments are managed here',
                "Associates History": 'Associate history can be viewed here',
            },
            "Master Routes": {
                "Master Routes": 'Master Routes is managed here',
                "Payments": 'Associates payments are managed here',
                "Associates History": 'Associate history can be viewed here',
            },
            "Invoices": {
                "Ageing Analysis": "Vendor details can be managed here.",
                "Receivables": "Payables to be paid by customer are tracked in this screen",
                "Payments Log": "Payments made by vendors are tracked in this screen"
            },
            "Bills": {
                "Ageing Analysis": "Customer details can be managed here.",
                "Payments Log": "Payments made by customer are tracked in this screen",
                "Bills": "Bills to be paid to vendors are tracked in this screen",
            },

            "Account Payables": "Payments made by customer are tracked in this screen",
            "FFV Payables": "Payments made by customer are tracked in this screen",

            "Associate Payables": "Payments made by customer are tracked in this screen",
            "Manage Payables": "Payments made by customer are tracked in this screen",
            "Ageing Analysis": "All the overdue payables and upcoming payables are tracked here.",


            " Finance ": {
                "Billing": "Billing details can be managed in this screen.",
                "Receivables": "Payments made by customer are tracked in this screen.",
                "Payables": "Bills to be paid to vendors are tracked in this screen.",
                "FFV / Associate Billing": "Billing for FFVs and associates is managed using this screen.",
                "Credit / Debit Notes": "Screen under construction.",

            },
            "Total Orders": "Total Orders are managed here",
            "Validate Orders": "Orders can be validated here",
            "Not Validated Without POD": "Not Validated Orders Without POD are managed here",
            "Not Validated With POD" : "Not Validated Orders With POD are managed here",
            "Generate Spilt Bill" : "Spilt Bills are generated here.",
            "Intransit Trips" : "Intransit Trips with billing are managed here",
            "Validate Credit Note" : "Credit Notes can be validated here",
            "Cancelled Invoices" : "Cancelled Invoices are managed here",
            "Cancelled Credit Note" : "Cancelled CreditNotes are managed here",
            
            // }, 
            "FFV / Associate Billing": {
                "Total Trips": "Total Trips are managed here",
                "FFV Billing": "Billing for FFVs is managed using this screen.",
                "Associate Billing": "Billing for associates is managed using this screen.",
                "Validate Trips": "Trips are validated using this screen.",
                "Validated Trips": "Trips which are validated are displayed in this screen.",
                "Not Validated Trips": "Trips which are not validated are displayed in this screen.",
                "Generate Invoice": "Invoices are managed using this screen.",
                "Validated Orders": "Validation of orders is managed using this screen.",
                "Not Validated Orders": "Not validated orders are displayed in this screen.",
                "Annexure": "Annexure is viewed using this screen.",
                "Invoice": "Invoice is viewed using this screen.",
                "Total Preview": "Annexure and invoice preview.",
                "Intransit Trips": "FFV Intransit Trips are managed using this screen.",
                "Generate Manual Invoice": "Manual FFV Invoice are generated using this screen.",

            },
            "Credit / Debit Notes": "Screen under construction.",
            "Validated Orders": "Validated Orders are managed here",
            "Validated LCL Breakbulk/DD Orders": "LCL Breakbulk/DD Validated Orders are managed here",
            "Validated LCL Express Orders": "LCL Express Validated Orders are managed here",
            "Generate LCL Bill" : "LCL Bills are generated here.",
            "Confirm Provisional Invoices" : "Provisional invoices are managed here.",
            "Annexure": "An overview representation of the annexure is represented here.",
            "Invoice": "An overview representation of the invoice is represented here.",
            "Total Preview": "Annexure and Invoice preview.",
            "Receivables":
            {
                "Manage Receivables": "Receivables are managed using this screen.",
                "Ageing Analysis": "All the overdue receviables and upcoming receviables are tracked here.",
            },


            "Executive Summary": {
                "XP Finance": "XP Finance is managed using this screen.",
                "GST Working": {
                    "XP GST": "XP GST is managed using this screen.",
                    "FFV/Associate GST": "FFV/Associate GST is managed using this screen."
                },
                // "GST Working": "GST is managed using this screen.",
                "XP GST": "XP GST is managed using this screen.",
                "FFV/Associate GST": "FFV/Associate GST is managed using this screen.",
                "TDS Working": {
                    "TDS Payables": "TDS Payable is managed using this screen.",
                    "TDS Receivables": "TDS Receivable is managed using this screen."
                },
                "TDS Payables": "TDS Payable is managed using this screen.",
                "TDS Receivables": "TDS Receivable is managed using this screen."
            },

            "Payables": {
                "Manage Payables": "Payables are managed using this screen.",
                "Ageing Analysis": "All the overdue payables and upcoming payables are tracked here.",


            },


            "Partly Paid": "Partly Paid details are shown here",
            "Vehicle Types": "Vehicle types can be added and managed using this screen.",
            'Vehicle Types History Log': 'All Change History can be seen here',
            "Service Levels": 'All the Service Levels can be represented here',
            "Customers": "The customers and there contract  can be managed here.",
            "Consignee": "All Consignee and there contract are managed Here",
            "Consignor": "All Consignor and there contract are managed Here",
            "Operations": "All the operational statistis can be represented here",
            //  "Finance": "All the transactional statistics  can be represented here",
            "Hub": "All the Hubs related statistics can be represented here ",
            "Gateway": "All the  Gatway related  statistics can be represented here",
            "Zones": "All the  Zones related  statistics can be represented here",
            "Origins(David Malhotra)": "The customer origins are listed here",
            "Destinations(David Malhotra)": "The customer destinations are listed here",
            "Contract": "All the contractual information can be tracked here",
            "Serviceable Zones": 'List of Serviceable Zones appears here',
            "Scheduling": "All schedules can be managed here",
            "Vendors": "Vendors related demographics can be managed from this screen",
            "Customer": {
                "All Customer": "The customer orders can be viewed in this screen",
                "Order Details for Customer Shopkart": "This page gives the detailed report of orders for a particular customer"
            },
            "Profile": "This screen is used to manage profile",
            "Origins": 'Origin and location addresses can be managed here from this screen.',
            "Destinations": 'Destination and location addresses can be managed here from this screen.',
            "Preference": "This screen is used to manage preference",
            "Packages": 'Customer packages and demographic details of full container load can be managed from this screen',
            "Tracking": "This page is used to track order",
            "Capacity": "Capacity can be managed from this screen.",
            "Attendence": "Attendence of Dedicated vehicles can be monitered here.",
            "Fleet Utilization": "Utilization of Fleet can be monitered here.",
            "Empty KMs Analysis": "Empty KMs can be monitered here.",
            "Capacity History": "Changes related to capacity can be viewed here",
            "Capacity at Unloading" : "Future capacity can be viewed from this screen",
            "Future Capacity" : "Future capacity can be viewed from this screen",
            'FFV': 'FFV details are captured and managed in this screen',
            // 'Drivers Panel': 'Drivers details are managed in this screen',
            "Drivers Panel": {
                "Drivers Panel Grid": "Drivers and their details can be viewed in this screen.",
                "Redemption": "Redemptions of the rewarded amount for a driver can be managed using this screen.",
                "Reward / Payment Log": "Payment details of the rewarded amount can be viewed in this screen.",
            },
            "Orders": "All the active orders can be tracked here",
            "Book New Order": "New orders can be added from this screen",
            "Quotation": "Quotations can be generated from this screen",
            "LCL Orders": "LCL orders log can be viewed from this screen",
            "FCL": 'This screen is used to manage FCL Orders',
            "LCL": 'This screen is used to manage LCL Orders',
            'Part-Load Enquiries': "This screen is used to manage Part-load enquiries.",
            'Express': 'All the offers of Express shown here',
            'Bespoke': 'This page is used to manage the Bespoke',
            // "Delivery": 'Delivery is manage here',
            //  "Booking": 'Booking nis managed here',
            "Open Cases": "This screen is used to view the summary of open cases.",
            "Closed Cases": "This screen is used to view the summary of closed cases.",
            "Maps": "This page is used to locate the orders from different locations",
            "Trips Flow": 'Trips flow can be managed from here.',
            "Customer MIS": "Customer MIS can be managed and customised here.",
            "Placement Tracking":'Vehicle Placement can be managed from here',
            "Waiting For Loading":'Vehecles Waiting for loading are managed here',
            "Trips Service Level": 'Trips service level can be viewed in this screen',
            "FFV Service Level": 'FFV service level can be viewed in this screen',
            "Zone Service Level": 'Zone service level can be viewed in this screen',
            "Branch Service Level": 'Branch service level can be viewed in this screen',
            "Customer Service Level": 'Customer service level can be viewed in this screen',
            "Driver Service Level": 'Driver service level can be viewed in this screen',
            "Performance": 'Performance can be viewed in this screen',
            "Arrived": 'Arrived Trips can be viewed in this screen',
            "Halted": 'Halted Trips can be viewed in this screen',
            "Alerts-Trips": 'Trip Alerts can be viewed in this screen',
            "Network Flows": "Network flow is tracked using this screen.",
            "Tracking Map": "Truck can be tracked using this screen.",
            "Lanes": 'Lane is managed here',
            'BB': 'All the offers of BB shown here',
            "Vendor Trips": "Vendor trips can be managed here",
            "Loads": "Vendor loads details can be managed  from this screen",
            "Vendor Payments": "Payments can bemanaged here",
            "Zone": 'This screen shows the zone.',
            "Dashboards": "This is a summary dashboard of the entire system.vxcvxcvxvxvxc",
            "Alerts": "The alerts as reminders for the Marketing and Sales process can be viewed from here",
            'Manage Orders': 'This page is used to manage all the orders',
            "LCL Manage Orders":"This page is used to manage all LCL orders",
            "Hubs": "Regional hubs demographics and details are captured in this screen",
            "Zone & Branch Permissions": "Zone & Branch Permissions can be managed in this screen",
            "Preferences": "This screen is used to manage preferences",
            "Offers": "Offer details are captured and scheduled from this screen",
            "Customer Offers": "Customer offer details are captured and scheduled from this screen",
            'Customer Offers History Log': 'All Change History can be seen here',
            "Driver Offers": " Driver offer details are captured and scheduled from this screen",
            'Driver Offers History Log': 'All Change History can be seen here',
            "FFV Offers": " FFV offer details are captured and scheduled from this screen",
            'FFV Offers History Log': 'All Change History can be seen here',
            "Announcements": "Announcements can be viewed from this screen",
            'Announcements History Log': 'All Change History can be seen here',
            "Settings": "System level settings can be managed from this screen",
            "Event Log": "All the Events occured  can be viewed here",
            "Customer Bypass(LCL Express)": "Customer Delivery & POD are Updated here",
            "Daily Change": "Daily Change Are Updated Here",
            "Order Settings": "Settings for orders module can be managed from this screen",
            "Capacity Settings": "Settings for capacity module can be managed from this screen",
            "LoadBoard Settings": "Settings for loadboard module can be managed from this screen",
            "Customers Settings": "Settings for customers module can be managed from this screen",
            "FFV Settings": "Settings for FFV module can be managed from this screen",
            "Operations Settings": "Settings for operations module can be managed from this screen",
            "Finance Settings": "Settings for finance module can be managed from this screen",
            "Service Settings": "Settings for service module can be managed from this screen",
            "Admin Settings": "Settings for admin module can be managed from this screen",
            "Testimonials": "Testimonals by Customers and Vendors can be viewed in this screen",
            "FFV Testimonials History": "Changes related to FFV testimonials can be viewed here",
            "Customer Testimonials History": "Changes related to FFV testimonials can be viewed here",

            // "Route Master": "This page is used to manage routes",
            'Role Permissions': 'This page is used to manage permissions of each role',
            "Vehicle Rates": "Vehicle Rates can be manages in this screen",
            "Shipment Rates": "Shipment Rates",
            "Rates Dashboard": {
                "Vehicle Rates": "Vehicle Rates can be manages in this screen",
                "Daily Rates": "Daily rates can be manages in this screen",
                "Monthly Rates": "Monthly rates can be manages in this screen",
                "Drivers": "This section contains a listing of active and inactive drivers",
                "Gateways": "The list of gateways some of which are tagged as zonal hubs is managed here",
                "Routes Log": "The list of routes for the FFVs appear here",
                "Zones": "The list of active zones for the customer appears here",
                "Vehicles": "This section contains a database of your active and inactive vehicles and trailers",
                "Contracts": "All the vendor contracts can be managed here",
                "Billing Office": "Billing offices can be managed here",
                "Company Details": "The List of Details for the Company appears here",
                "Users":"The List of Details for the Manage FFvs Users"

            },
            "Customer Dashboard": {
                "Company Details": "The List of Details for the Company appears here",
                "Locations": "The list of customer locations  are managed here",

                "Collection Center And Delivery Center": "The list of LCL collection center and delivery center are managed here",

                "Consignee": "The list of Customer Consignee are managed here ",

                "Users": "The list of Customer Users can be seen and managed here",
                "Users-History": "Changes related to Customer Users can be viewed here",
                "MIS":"MIS can be managed here",
                "Destinations": "The list of  customer destinations are managed here",
                "Routes Log": "The list of routes for the customers appear here",
                "FCL Rates": "All the LCL Rates for a customer can be managed here",
                "LCL Rates": "All the LCL Rates for a customer can be managed here",
                "LCL Rates-Direct-Delivery": "All the LCL Rates for a customer can be managed here",
                "LCL Rates-Direct-Delivery History": "Changes related to LCL rates can be viewed here",
                "LCL Rates-Express": "All the LCL Rates for a customer can be managed here",
                "LCL Rates-BreakBulk": "All the LCL Rates for a customer can be managed here",
                "LCL Rates-Aggregation": "All the LCL Rates for a customer can be managed here",
                "LCL Rates-Milkrun": "All the LCL Rates for a customer can be managed here",
                "Billing-Office": "All the Billing-Office Address can appears here",
                "Billing-Office History": "Changes related to contracts can be viewed here",

                "Express Rates": "All the Express Rates for a customer can be managed here",
                "Contracts": "All the customer contracts can be managed here",
                "Customer Annexure": "All Customer Annexures can be managed here"
            },
            "LCL Rates History": "LCL Rates History can be managed here",
            "Customers History": "Changes related to customers can be viewed here",
            "Locations History": "Changes related to customer locations can be viewed here",

            "CC/DC History":"Changes related to CC and DC can be viewed here",

            "Consignor/Consignee History":"Changes related Consignee History can be viewed here",

            "Contracts History": "Changes related to contracts can be viewed here",
            "FCL Rates History": "Changes related to FCL rates can be viewed here",

            "FFVs History": "Changes related to FFVs can be viewed here",
            "Billing Office History": "Changes related to billing offices can be viewed here",
            "Routes Log History": "Changes related to routes can be viewed here",
            "Drivers History": "Changes related to drivers can be viewed here",
            "Vehicles History": "Changes related to vehicles can be viewed here",
            "Fixed Route History": "Changes related to fixed route FCL rates can be viewed here",
            "Fixed + Variable History": "Changes related to fixed + variable FCL rates can be viewed here",
            "Rate Per KM History": "Changes related to rate per KM FCL rates can be viewed here",

            'Backlog': {
                "Admin Backlogs":
                    "Screens which are change requests and their documents can be managed using this screen.",
                "Customers Backlogs":
                    "Screens which are change requests and their documents can be managed using this screen.",
                "Vendors Backlogs":
                    "Screens which are change requests and their documents can be managed using this screen.",
                "Drivers Backlogs":
                    "Screens which are change requests and their documents can be managed using this screen.",
            },

            "Gateways(Shopkart)": 'All the active users Gateway are shown here.',
            'Master Data': {
                'Countries': 'The list of serviceable countries is managed here',
                'Zones': 'The list of serviceable zones is managed here',
                'Zones History Log': 'All Change History can be seen here',
                'States': 'The list of serviceable states by country is managed here',
                'States History Log': 'All Change History can be seen here',
                'Districts': 'The list of serviceable districts by state is managed here',
                'Districts History Log': 'All Change History can be seen here',
                'Locations': 'The list of serviceable locations (TALUKS) by district is managed here',
                'Locations History Log': 'All Change History can be seen here',
                'Postcodes': 'The list of serviceable postcodes by district is managed here',
                "XP Billing Office": "XP Billing Office details can be managed from here",
                'Postcodes History Log': 'All Change History can be seen here',
                'Gateways': {
                    'Gateways':'All the  Gatways are managed here'
                },
                'Gateway History Log': 'All Change History can be seen here',
                'DC': 'All the  DC are managed here',
                'DC History Log': 'All Change History can be seen here',
                'Hubs': 'All the Hubs related statistics can be represented here',
                "Branch Office": 'Branch office is managed here',
                'Branch Office History Log': 'All Change History can be seen here',
                "Maps": "This page is used to locate the orders from different locations",
                "Regions": 'Regions are managed here',
            },
            "Approvals":{
                "Credit Note": "Credit Notes are approved using this screen",
                "Paid/To-Pay": "Paid/To-Pay Invoices are approved using this screen",
                "FFV Invoice(Dynamic)": "FFV Invoices are approved using this screen",

            },
            "Credit Note":{
                "Pending Credit Note":"These are the credit notes which have been raised but not yet approved.",
                "Approved Credit Note": "Approved credit note will appear in this section.",
            },
            "Paid/To-Pay":{
                "Pending Invoices": "Invoice which needs to be approved will appear in this section.",
                "Approved Invoices": "Approved Invoice will appear in this section."
            },
            "FFV Invoice(Dynamic)":{
                "FCL-FFV-Invoices": "Invoice which needs to be approved will appear in this section.",
                "LCL-FFV-Invoices": "Approved Invoice will appear in this section."
            },
            "FCL-FFV-Invoices":{
                "FCL Pending FFV Invoices": "FCL Invoice which needs to be approved will appear in this section.",
                "FCL Approved FFV Invoices": "FCL Approved Invoice will appear in this section."
            } ,
            "LCL-FFV-Invoices":{
                "LCL Pending FFV Invoices": "LCL Invoice which needs to be approved will appear in this section.",
                "LCL Approved FFV Invoices": "LCL Approved Invoice will appear in this section."
            } ,
            "LCL Pending FFV Invoices":
            {
            "LCL Pending Pickup Express FFV Invoices":"LCL Pending Pickup Express FFV Invoices will appear in this section.",
            "LCL Pending Linehaul FFV Invoices":"LCL Pending Linehaul FFV Invoices will appear in this section."

            },
            "FFV Payments":
            {
                "Early Request": "Early requests are viewed/approved/rejected from this screen.",
                "Pending FFV Payment": "Pending FFV Payment request are viewed/approved/rejected in this screen.",
                "Approved FFV Payment": "Approved FFV Payments are viewed in this screeen.",
                "Missed/Rejected Early Payment": "Rejected early requests are viewed in this screen."
            },

            "SDA Approvals":{
                "Pending SDA": "SDA Districts are approved through this screen.",
                "Approved SDA": "Approved SDA records are viewed in this screen."
            },
            "Tickets":{
                "Raise Tickets": "Executive can raise query tickets",
                "Raised Tickets": "Executive can see raised query tickets",
                "InProgess Tickets": "Developer can see raised query tickets and tickets progress",
                "Closed Tickets": "Developer and Admin can see closed query tickets",
                
            },
            //"Pending Trips":"Pending Trips Managed by this Screen",
            "Pending Trips":
            {
                "ITS Daily Trips":"ITS Daily Trips are managed using this screen",
                "ITS Matched Trips":"ITS Matched Trips are managed using this screen",
                "ITS Un-Matched Trips":"ITS Un-Matched Trips are managed using this screen",
            },
            "Inventory":{
                "Inventory":"Material Handling Resource Management"
            },
            

        };
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.breadcrumbs = [];
                this.parseRoute(this.router.routerState.snapshot.root);
                this.pageTitle = "";
                this.breadcrumbs.forEach(breadcrumb => {
                    this.pageTitle += ' | ' + breadcrumb.name;
                })
                this.pageTitle ? null : this.pageTitle = " | Dashboard";

                var temp = this.pageTitle.split(' | ');
                if (temp.length > 4) {
                    this.subTitle = temp.pop();
                    this.moduleTitle = temp[temp.length - 1];
                } else if (temp.length > 3) {
                    this.subTitle = temp.pop();
                    this.moduleTitle = temp[temp.length - 1];
                } else {
                    this.moduleTitle = temp.pop();
                }

                if (this.subTitle === 'Permissions') {
                    this.moduleTitle = "Permissions";
                    this.subTitle = "test";
                }
                this.title.setTitle(this.settings.name + this.pageTitle);
            }
        })
    }

    private parseRoute(node: ActivatedRouteSnapshot) {
        if (node.data['breadcrumb']) {
            if (node.url.length) {
                let urlSegments: UrlSegment[] = [];
                node.pathFromRoot.forEach(routerState => {
                    urlSegments = urlSegments.concat(routerState.url);
                });
                let url = urlSegments.map(urlSegment => {
                    return urlSegment.path;
                }).join('/');
                this.breadcrumbs.push({
                    name: node.data['breadcrumb'],
                    url: '/' + url
                })
            }
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
    }

    public closeSubMenus() {
        let menu = document.querySelector(".sidenav-menu-outer");
        if (menu) {
            for (let i = 0; i < menu.children[0].children.length; i++) {
                let child = menu.children[0].children[i];
                if (child) {
                    if (child.children[0].classList.contains('expanded')) {
                        child.children[0].classList.remove('expanded');
                        child.children[1].classList.remove('show');
                    }
                }
            }
        }
    }
}


