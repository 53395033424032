import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { responseModel } from 'src/app/_models/response.model';
import { env } from 'process';
import { event } from 'jquery';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LclDeliveryService {
  subscribe(arg0: (data: any) => void) {
    throw new Error('Method not implemented.');
  }
  private childCountSubject: BehaviorSubject<{ totalSize: number; screenname: string }> = new BehaviorSubject({
    totalSize: 0,
    screenname: '',
  });

  public dcount1: number;
  public dcount2: number;
  public dcount3: number;
  public dcount4: number;
  public dcount5: number;
  public dcount6: number;
  public dcount7: number;
  public dcount8: number;
  public dcount9: number;
  public dcount10: number;
  public dcount11: number;
  public dcount12: number;
  public dcount13: number;
  public dcount16: number;
  public dcount18: number;
  public dcount19: number;
  public dcount20: number;
  public dcount21: number;

  headers: { headers: any };
  constructor(private _http: HttpClient) { }

  // For Sending headers to API
  getHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.headers = httpOptions;
  }

  // getAllLclDeliveryXPTSD(filter) {
  //   return this._http.post(environment.apiUrl + 'api/get-all-delivery-Order-assigment', filter);
  // }
  getAllLclDeliveryXPTSD(filter) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/loadplanner', filter);
  }
  // getalldeliveryorderassignment(data) {
  //   return this._http.post(environment.coreapiUrl + 'api/v2/delivery/ffv-allocation', data);
  // }
  getalldeliveryorderassignment(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/ffv-allocation', data);
  }
  generatedeliveryxptsd(data) {
    return this._http.post(environment.apiUrl + 'api/upsert_delivery-xpts', data);
  }

  getxptsdialogdetails(id) {
    return this._http.get(environment.apiUrl + 'api/get-delivery_order-details-by-xptsd-id?XptsId=' + id);
  }

  getdeliveryallocatevehicledata(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/allocate-vehicle', data)
  }

  updatedeliveryffv(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-vehicle-details-lcl-delivery', data);
  }
  getwayCountList(data) {
    return this._http.post(environment.apiUrl + 'api/get-gateway-pod-delivery-count', data);
  }
  getloadingtally(data) {
   return this._http.post(environment.coreapiUrl + 'api/v2/delivery/Loading-Tally', data);
    //return this._http.post(environment.apiUrl + 'api/get-delivery_xpts-lcl_loading_tally', data);
  }
  loadingtallydialogbox(id) {
    return this._http.get(environment.apiUrl + 'api/get-delivery_xpcn-details-by-xpltd-id?int_tally_no=' + id);
  }
  scan_loading_tally(HuNumber, int_xpts_id) {
    return this._http.get(environment.apiUrl + 'api/update-lcl-delivery-loading-of-box?huNumber=' + HuNumber + '&int_xptsId=' + int_xpts_id);
  }
  scan_loading_tally_xpcn_docs(HuNumber) {
    return this._http.get(environment.apiUrl + 'api/update-lcl-delivery-loading-of-box?huNumber=' + HuNumber);
  }
  getcloseloadingtallydata(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/Close-Loading-Tally', data);
  }
  getvalidateplacementdata(data) {
 return this._http.post(environment.coreapiUrl + 'api/v2/delivery/validate-placement', data);
    //return this._http.post(environment.apiUrl + 'api/get-delivery_xpts-lcl_validate_placemenet', data);
  }
  validateplacementdatetime(data) {
    return this._http.post(environment.apiUrl + 'api/update-validate_delivery_vehicle_placemet',data );
  }
  getpartBdata(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/update-part-B', data)
  }
  getValidateXPTSdata(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/ValidateXPTS', data);
  }
  getdeliveryintransit(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/Out-For-Delivery', data);
  }
  
  validatedeliveryintransit(id, date) {
    return this._http.get(environment.apiUrl + 'api/update-validate-delivery-order-arrival-dest?int_order_id=' + id + '&dt_arrival_date=' + date);
  }
  getdelivereddata(data) {
    return this._http.post(environment.apiUrl + 'api/get-delivery-delivered-orders', data);
  } 
  getdeliverydeliveredtabgraph(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/DeliveredGraph',data);
  }

  getclosexptsdialogdata(xpts) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-undelivered_ordere-ids-close-xpts?int_xpts_id=' + xpts);
  }
  closexptsdelivery(data) {
    return this._http.post(environment.apiUrl + 'api/update-upsert-close-xpts-reason', data);
  }

  deliveryorderspoddata(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/not-uploaded/pod', data);
  }

  updatePOD(data) {
    return this._http.post(environment.apiUrl + 'api/save-xpcn-pod-lcl', data);
  }
  validatePOD(id) {
    return this._http.get(environment.apiUrl + 'api/update-validate-lcl-pod?XPCNId=' + id)
  }

  deletePOD(id, isCOD) {
    return this._http.get(environment.apiUrl + 'api/delete-lcl-pod?XPCNId=' + id + '&isCOD=' + isCOD)
  }

  getallundeliveredorders(data) {
    return this._http.post(environment.coreapiUrl + 'api/V2/delivery/Undelivered', data);
  }

  getCloseXPTSdata(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/close-running-trips', data);
  }

  ScanHuNumbersXPCN(HuNumber, tally_no, api) {
    return this._http.get(environment.apiUrl + api + '?huNumber=' + HuNumber + '&tally_no=' + tally_no);
  }

  getDeliveryOrderDetails(xptsId, genTallyDate) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-order-details-by-xptsid?xptsId=' + xptsId + '&genTallyDate=' + genTallyDate);
  }
  getDeliveryEwayBillDetails(xptsId) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-ewayBill-details-by-xptsid?xptsId=' + xptsId);
  }

  getLCLDeliveryCount() {
    return this._http.get(environment.apiUrl + 'api/get-operations-lcl-delivery-count');
  }
  
  getGatewaystatsForDeliveryPlanning(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/loadplanner/stats', data);
  }

  getCustomerstatsForDeliveryPlanning(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-customer-stats-delivery-planning', data);
  }

  getbranchstatsForFFVAllocation() {
    return this._http.get(environment.coreapiUrl + 'api/v2/get/FfvAllocationGraph');
  }
  getstatsForFFV() {
    return this._http.get(environment.coreapiUrl + 'api/v2/get/VehicleAllocationGraph');
  }
  getPickedBoxDetails(int_xpcn_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-delivery-hu-number-by-xpcn-id?int_xpcn_id=' + int_xpcn_id);
  }

  filterVehicle(number) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-ffv-vehicle-by-vehicleNo/' + number);
  }
  upsertnewolddeliveryvehicle(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-assign-new-old-vehicle-details-lcl-delivery', data);
  }
  getDeliveryLoadedBoxHubNumber(xpcnid) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-delivery-loaded-box-hu-number-by-xpcn-id?int_xpcn_id=' + xpcnid)
  }

  getDeliveryHuNumberByxptsid(int_xpts_id) {
    return this._http.get(environment.apiUrl + 'api/get-lcl-delivery-HuNumber-by-xptsid?int_xpts_id=' + int_xpts_id)
  }

  getDetailsbyHuNumber(data) {
    return this._http.get(environment.apiUrl + 'api/get-details-from-hu-number?HUNumber=' + data);
  }
  UpdatePartBDelivery(xptsno) {
    return this._http.get(environment.apiUrl + 'api/update-ewb-part-b-lcl-delivery?xptsId=' + xptsno);
  }
  // to receive pod forwarding note
  receiveLclPODFN(data) {
    return this._http.post(environment.apiUrl + 'api/receive-lcl-pod-forwarding-note', data);
  }

  upsertExcessLCLXPCNBoxes(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-lcl-xpcn-excess-boxes', data);
  }

  removexpcn(xpts, xpcn) {
    return this._http.get(environment.apiUrl + 'api/remove-xpcn-from-xpts-tally-delivery?xptsId=' + xpts + '&xpcnId=' + xpcn)
  }

deliveryorderspoddatavalidate(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/uploaded/pod', data);

  }
  
  getCheckPodcount() {
    return this._http.get(environment.apiUrl + 'api/get-operations-lcl-delivery-above-tabs-count');
  }

  updateResceduleOrderForDeliveryPlanning(data) {
    return this._http.post(environment.apiUrl + 'api/Update-lcl-reschedule-order', data)
  }

  getLCLDeliveryAboveTabsCount() {
    return this._http.get(environment.apiUrl + 'api/get-operations-lcl-delivery-above-tabs-count')
  }

  getLCLCEWBForDelivery(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/consolidate-eway-bill', data)
  }
  // to get pod forwarding note for dispatch
  getPODFNLclForDispatch(data) {
    return this._http.post(environment.apiUrl + 'api/get-pod-lcl-forwarding-note-for-dispatch', data);
  }
  // to get pod forwarding note for dispatch
  getPODFNLclForRecieve(data) {
    return this._http.post(environment.apiUrl + 'api/get-pod-forwarding-note-lcl-for-receive', data);
  }
  UpdateUndeliveredToDeliveryPlanning(int_orderId) {
    return this._http.get(environment.apiUrl + 'api/Update-lcl-moved-undelivered-to-delivery-planning?int_orderId=' + int_orderId)
  }

  getLocationCountForDeliverPlanning(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-location-stats-delivery-planning', data)
  }

  GetAllLCLDeliveryXPTSForTotalClosedXPTS(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/total-closed-xpts', data)
  }

  GetAllLClPODLogByOrderId(orderId) {
    return this._http.get(environment.apiUrl + 'api/get-all-lcl-pod-log-by-orderId?intOrderId=' + orderId)
  }
  getLCLValidatePODCount(datefilterType, fromDate, toDate) {
    return this._http.get(environment.apiUrl + 'api/get-all-lcl-validate-pod-count?datefilterType=' + datefilterType + '&fromdate=' + fromDate + '&toDate=' + toDate)
  }

  generateCEWBDelivery(xptsId) {
    return this._http.get(environment.apiUrl + 'api/generate-consolidated-ewaybill-for-delivery?xptsId=' + xptsId);
  }

  getUndeliveredOrderDetails(OrderId) {
    return this._http.post(environment.apiUrl + 'api/get-delay0rder', OrderId);
  }


  getCount() {
    this.getLCLDeliveryCount().subscribe(data => {
      if (data != null) {
        this.dcount1 = data['Data']['delivery_planning_count'];
        this.dcount2 = data['Data']['OrderAssignmentCount'],
          this.dcount2 = data['Data']['OrderAssignmentCount'],
          this.dcount3 = data['Data']['VehicleAllocationCount'],
          this.dcount4 = data['Data']['ValidatePlacementCount'],
          this.dcount5 = data['Data']['GenerateLoadingCount'],
          this.dcount6 = data['Data']['CloseLoadingTallyCount'],
          this.dcount7 = data['Data']['UpdatePartBcount'],
          this.dcount8 = data['Data']['Validate_xpts_Count'],
          this.dcount9 = data['Data']['OutForDeliveryCount'],
          this.dcount10 = data['Data']['CloseXpts_count'],
          this.dcount11 = data['Data']['POD_Count'],
          this.dcount12 = data['Data']['Undelivered_Count'],
          this.dcount18 = data['Data']['delivered_delivered_count'],
          //this.dcount19=data['Data']['Delivery_Validated_pod_Count'],
          this.dcount20 = data['Data']['Delivery_upload_pod_Count'],
          this.dcount21 = data['Data']['Total_closed_xpts_count'],
          this.dcount16 = data['Data']['cewb_Count'],
          this.dcount13 = data['Data']['CancelledBookingCount'];
      }
    });
  }

  getValidatePODCount(datefilterType, fromDate, toDate) {
    this.getLCLValidatePODCount(datefilterType, fromDate, toDate).subscribe(data => {
      this.dcount19 = data['Data']['Delivery_Validated_pod_Count'];
    })
  }

  deletedeliveryxpts(xptsId) {
    return this._http.get(environment.apiUrl + 'api/delete-lcl-delivery-xpts?XPTSId=' + xptsId)
  }
  getPodFn(int_id, bt_isCOD) {
    return this._http.get(environment.apiUrl + 'api/get-pod-forwarding-note-id?int_id=' + int_id + '&bt_isCOD=' + bt_isCOD)
  }
  getcancelledxptsdata(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/CancelledXPTS', data);
  }
  deliveryordersforwardingnotes(data) {
    return this._http.post(environment.apiUrl + 'api/get-delivery-delivered-orders-forwarding_notes', data);

  }
  updateCOD(data) {
    return this._http.post(environment.apiUrl + 'api/save-xpcn-cod-dod-lcl', data);
  }
  getLclNextPODFNNo() {
    return this._http.get(environment.apiUrl + 'api/get-lcl-next-podfn-no');
  }
  uploadFilesWithXPCNs(files: File[], xpcnList: any[]): Observable<any> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('xpcnList', JSON.stringify(xpcnList));

    return this._http.post(environment.apiUrl + 'api/upload-manifest-for-xpcns', formData);
  }
  // to generate pod forwarding note
  LclgeneratePODFN(data) {
    return this._http.post(environment.apiUrl + 'api/generate-lcl-podfn-forwarding-note', data);
  }

  UpsertDriverLCLValidatePlacementDelivery(data) {
    return this._http.post(environment.apiUrl + 'api/upsert-ffv-driver-in-delivery-validate-placement', data);
  }

  DownloadToPayInvoices(XPTSId) {
    return this._http.get(environment.apiUrl + 'Invoices/DownloadToPayInvoices?xptsId=' + XPTSId);
  }
  UpdateCEWBDetailsAtValidateXPTS(data) {
    return this._http.post(environment.apiUrl + 'api/delivery_cewb_at_validate_xpts', data);

  }

  scan_Xpcn(data) {
    return this._http.post(environment.apiUrl + 'api/get-delivered_scan_by_xpcn', data)
  }

  scan_Xpcn_podfn_access(data) {
    return this._http.post(environment.apiUrl + 'api/get-podfn_access_scan_by_xpcn', data)
  }
  updatepodfntoxpcnbyscan(xpcnid, podfnid) {
    return this._http.get(environment.apiUrl + 'api/update-lcl-podfn-to-xpcn-by-scan-result?XPCNId=' + xpcnid + '&podfnId=' + podfnid)
  }
  upseraccessofpodfn(xpcnid, podfnid, gatewayid) {
    return this._http.get(environment.apiUrl + 'api/upsert-lcl-podfn-access?XPCNId=' + xpcnid + '&podfnId=' + podfnid + '&gatewayid=' + gatewayid + '&bt_short=' + false)
  }

  updatereceivepodfnandxpcn(data) {
    return this._http.post(environment.apiUrl + 'api/update-lcl-podfn-receivig-note-and-access', data)
  }

  getalllclpodfnshortaccess(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-lcl-podfn-xpcn-pod-short-access', data)
  }

  deletepodfnaccessbyxpcnid(xpcnid, podfnid) {
    return this._http.get(environment.apiUrl + 'api/delete-lcl-podfn-access?XPCNId=' + xpcnid + '&podfnId=' + podfnid)
  }

  deletepodfnbyid(podfnid) {
    return this._http.get(environment.apiUrl + 'api/delete-podfn-note-id?int_podfn_id=' + podfnid)
  }

  GetAllIntransitOrderGatewayStats(Gateway) {
    return this._http.get(environment.coreapiUrl + 'api/v2/delivery/Out-For-Delivery-Graph?Gateway=' + Gateway)
  }
  // GetAlldeliveredOrderGatewayStats(data){
  //   return this._http.post(environment.apiUrl + 'api/get-all-delivered-order-gateway-stats',data)
  //}
  GetAlldeliveredOrderGatewayStats(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/uploadpod/stats', data)
  }
  GetAlldeliveredOrderCustomerStats(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/not-uploaded/pod/Customerstats', data)
  }
  getUserScreenPermissions(screenName: string) {
    return this._http.get(environment.apiUrl + "api/get-user-screen-permissions?screen_name=" + screenName);
  }


  searchpodfnbyxpcnnumber(input) {
    return this._http.get(environment.apiUrl + 'api/get-pod-forwarding-note-by_xpcn?xpcn=' + input)
  }

  
  getcoddodrecords(data) {
  // return this._http.post(environment.apiUrl + 'api/sp_get-all_total_records', data);
  return this._http.post(environment.coreapiUrl + 'api/v2/CODDOD/TotalRecord', data);
}
getscreenTabsCount(data) {
  return this._http.post(environment.coreapiUrl + 'api/v2/CODDOD/TabCount',data);
}
  getcod_dod_graph(data){
    return this._http.post(environment.coreapiUrl + 'api/v2/CODDOD/Graph',data);
  }
  cancel_cod_dod_xpcn(XPCN,remarks){
    return this._http.get(environment.apiUrl + 'api/cancel-dod-cod-xpcn-by-xpcnid?XPCN=' + XPCN + '&remarks=' + remarks)
  }
  cod_dod_history(XPCN){
    return this._http.get(environment.apiUrl + 'api/get-cod-dod-history-details?XPCN=' + XPCN)
  }
  Editcoddodxpcn(data) {
    return this._http.post(environment.apiUrl + 'api/edit-dod-cod-xpcn-by-xpcnid', data)
  }  

  GetAllDeliveryXpcnArrivedOrders(data) {
    return this._http.post(environment.coreapiUrl + 'api/v2/delivery/lcl-xpcn-arrived', data)
  }

  GetDeliveredXPCNForCodDodForwardingNoteGrid(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-delivered-orders-for-forwarding-note-grid', data)
  }

  GetGatewayForCODFN(data) {
    return this._http.post(environment.apiUrl + 'api/get-delivery-gateway-for-cod-fn', data);
  }

  GenerateCODFN(data) {
    return this._http.post(environment.apiUrl + 'api/generate-codDod-forwarding-note', data);
  }

  GetDespatchedOrders(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-despatched-orders-for-forwarding-note-grid', data);

  }
  GetHOReceivedOrders(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-ho-received-orders-for-cod-dod-forwarding-note', data);

  }
  // DespatchedByGatewayCODFNDetails(data) {
  //   return this._http.post(environment.apiUrl + 'api/dispatched-cod-dod-fn-details', data);

  // }
SaveDespatchedDialogDetails(api,data){
  return this._http.post(environment.apiUrl + api, data);
}
  updateReceivedCodFNExcess(data) {
    return this._http.post(environment.apiUrl + 'api/update-lcl-cod-dod-fn-receiving-note-and-excess', data)
  }

  DespatchedByGatewayCODFNDetailsForCustomer(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-despatched-orders-of-customer-cod-dod', data);

  }

  getCustomerstatsForCODdodFN(data) {
    return this._http.post(environment.apiUrl + 'api/get-lcl-customer-stats-cod-dod-fn', data);
  }

  GetShortXPCNsInCODFN(data) {
    return this._http.post(environment.apiUrl + 'api/get-all-lcl-codfn-short-excess-grid', data)
  }

  UpdateDraftDetailsForCodDodXPCN(data) {
    return this._http.post(environment.apiUrl + 'api/update-draft-details-for-codDod-xpcn', data)
  }

deleteCODFNbyid(fnid){
  return this._http.get(environment.apiUrl + 'api/delete-codfn-note-id?int_id='+fnid)
}

uploadDocPDF(api,data){
  return this._http.post(environment.apiUrl + api, data);
}
GetUndeliveredOrderForUnloading(XptsId)
{
  return this._http.get(environment.apiUrl+'api/get-lcl-undelivered_orders-ids-for-unloading?int_xpts_id='+XptsId)
}


GetOrderCountForCloseRunningTrips(XptsId)
{
  return this._http.get(environment.apiUrl+'api/get-all-orders-count-for-undelivered-orders-of-delivery?int_xpts_id='+XptsId)
}

GetAllXpcnDetailsForCloseTripsDialog(XptsId,StatusId)
{
  return this._http.get(environment.apiUrl+'api/Get-all-xpcn-details-for-close-trips?xptsid='+XptsId+'&StatusId='+StatusId )
}

GetAllDeliveryDeliveredOrders(data)
{
  return this._http.post(environment.coreapiUrl+'api/v2/delivery/delivered-orders',data )
}

GetAllDeliveryPODLogDetails(XptsId,isDirectDelivery)
{
  return this._http.get(environment.coreapiUrl+'api/v2/delivery/pod-log-details?XptsId='+XptsId+'&isDirectDelivery='+isDirectDelivery )
}

utilitypostapi(api,data)
{
  return this._http.post(environment.coreapiUrl + api,data)
}

utilitygetapi(api){
  return this._http.get(environment.coreapiUrl + api)
}
getTabFromChild(): Observable<{ totalSize: number; screenname: string }> {
  return this.childCountSubject.asObservable();
}
updatePrentTotal(totalSize: number, screenname: string): void {
  this.childCountSubject.next({ totalSize, screenname });
}


postDataToServer(apiEndPoint : string,data,coreAPI : boolean) {
  return this._http.post(`${coreAPI?environment.coreapiUrl:environment.apiUrl}${apiEndPoint}`, data)
}
// Countd2c() {
//   return this._http.get(environment.coreapiUrl + 'api/v2/lcl/delivery/tab-counts');
// }
GetAttemptedOrders(data)
{
  return this._http.post(environment.coreapiUrl+'api/v2/delivery/delivery-attempted-orders',data);
}

}