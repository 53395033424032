import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FfvDetailsService {

  headers: { headers: any; };

  constructor(private _http: HttpClient) { }


  public SetFfvId(id: number) {
    localStorage.setItem('FFV_ID', id.toString());
  }
  public getFfvId() {
    return parseInt(localStorage.getItem('FFV_ID'));
  }

  public SetFfvName(name: string) {
    localStorage.setItem('FFV_NAME', name);
  }
  public getFfvName() {
    return localStorage.getItem('FFV_NAME');
  }

  // surepass
  
validatedriverDL(data)
{
  const headerDict = {
    'Content-Type': 'application/json',
    'Authorization': environment.surepasstoken,
  }
  
  const requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders(headerDict), 
  };
  
  return this._http.post("https://kyc-api.aadhaarkyc.io/api/v1/driving-license/driving-license", data,requestOptions);
}

generateAadharOTP(data)
{
  const headerDict = {
    'Content-Type': 'application/json',
    'Authorization': environment.surepasstoken,
  }
  
  const requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders(headerDict), 
  };
  
  return this._http.post("https://kyc-api.aadhaarkyc.io/api/v1/aadhaar-v2/generate-otp", data,requestOptions);
}

validatedriverAadhar(data)
{
  const headerDict = {
    'Content-Type': 'application/json',
    'Authorization': environment.surepasstoken,
  }
  
  const requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders(headerDict), 
  };
  
  return this._http.post("https://kyc-api.aadhaarkyc.io/api/v1/aadhaar-v2/submit-otp", data,requestOptions);
}

validatedriverPAN(data)
{
  const headerDict = {
    'Content-Type': 'application/json',
    'Authorization': environment.surepasstoken,
  }
  
  const requestOptions = {                                                                                                                                                                                 
    headers: new HttpHeaders(headerDict), 
  };
  
  return this._http.post("https://kyc-api.aadhaarkyc.io/api/v1/pan/pan", data,requestOptions);
}

validatevehicleRC(data) {
    
  const headerDict = {
    'Content-Type': 'application/json',
    'Authorization': environment.surepasstoken,
  }

  const requestOptions = {
    headers: new HttpHeaders(headerDict),
  };

  return this._http.post("https://kyc-api.aadhaarkyc.io/api/v1/rc/rc", data, requestOptions);
}

// surepass end
validateINSTANTvehicleDriver(vehicleNo) {
  const baseUrl = 'https://liveapicore.instant-transport.com';
  const apiUrl = '/api/get-driver-vehicle-details-fr-xp/';

  const headers = {
    'user_name': 'XpIndia',
    'user_token_id': '280a492334e3c2ddfd225640eb2628e8d5968a5c09d9704e60d29a8acb9255aa',
  }
  const url = `${baseUrl}${apiUrl}${vehicleNo}`;

  return this._http.get(url, { headers });
}
}
