import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LclPickUpService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-pick-up/lcl-pick-up.service';
import { AlertService } from '../services/alert.service';
import { DatePipe } from '@angular/common';
import { LclDeliveryService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-delivery/lcl-delivery.service';
import { VendorService } from 'src/app/xp-admin-web/users/vendors/vendor.service';
import { FfvDetailsService } from 'src/app/xp-admin-web/users/ffv2/ffv-details.service';
import { LclTranshipmentService } from 'src/app/xp-admin-web/operations/operations-lcl/lcl-transhipment/lcl-transhipment.service';
import { Router } from '@angular/router';
import { TextValidator } from 'src/app/_helpers/text-validator';
import { Console } from 'console';

@Component({
  selector: 'app-add-driver-vehicle-dialog',
  templateUrl: './add-driver-vehicle-dialog.component.html',
  styleUrls: ['./add-driver-vehicle-dialog.component.scss']
})
export class AddDriverVehicleDialogComponent implements OnInit {

  DLVerified = false;
  isDisabled = false;
  DriverAvailable: boolean = true;
  VehicleAvailable: boolean = true;
  showdate = false;
  vehicles: any;
  ffvs = [];
  BookingId = [];
  drivers = [];
  customer;
  type = '';
  details;
  totalWeight = 0;
  Weight = 0;
  searchedVehicles = [];
  addEditForm: FormGroup;
  xptsno: any;
  vehiclelist: any;
  vehicletype: any;
  manifest: any;
  cl: any;
  DL_Number: any;
  selectedFFVName: string = ''; 
  // vehiclePattern = "^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{4}$";
  ffvId: any;
  newVehicleDriverForm: FormGroup;
  VehicleType: any;
  VehicleTypeId: any;
  mParivahanFetched: boolean = false;
  DLNotRequired: boolean = false;
  constructor(public fb: FormBuilder, public dialogRef: MatDialogRef<AddDriverVehicleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _LclPickupService: LclPickUpService,
    public alertService: AlertService, public datepipe: DatePipe,
    public _lcldeliveryService: LclDeliveryService,
    private _vendorService: VendorService, private _FfvDetailsService: FfvDetailsService,
    public transhipmentService: LclTranshipmentService, public route: Router) {

    // use to add new vehicle and driver
    this.newVehicleDriverForm = this.fb.group({
      int_manifest_id: new FormControl(''),
      FFVId: new FormControl(null, Validators.required),
      FFVName: new FormControl(''),
      FFVSearch: new FormControl(''),
      VehicleId: new FormControl(''),
      Vehicle: new FormControl(''),
      VehicleNo: new FormControl('', [Validators.required]),
      VehicleTypeId: new FormControl(''),
      VehicleTypeName: new FormControl(''),
      DriverId: new FormControl(''),
      DriverName: new FormControl(''),
      DriverNo: new FormControl('', [Validators.required, Validators.max(10)]),
      DlNumber: new FormControl('', Validators.required),
      DriverDOB: new FormControl(''),
      DlClass: new FormControl(''),
      ValidUpto: new FormControl(''),
      Address: new FormControl(''),
      dec_dynamic_freight: new FormControl(),
      ScreenName: new FormControl(''),
      MakeModel: new FormControl(''),
      VehicleRegistrationDate: new FormControl(''),
      VehicleTaxExpiry: new FormControl(''),
      VehicleInsuranceExpiry: new FormControl(''),
      VehicleNationalPermitExpiry: new FormControl(''),
      VehicleFitnessExpiry: new FormControl(''),
      VehiclePollutionExpiry: new FormControl(''),
      VehicleFiveYearExpiry: new FormControl(''),
      tripRemark: new FormControl(''),
      OwnerName: new FormControl(''),
      
    });
  }




  BookingIDList = [];
  ngOnInit(): void {
    this.ffvId = this.data.ffvId;
    this.VehicleType = this.data.VehicleType;
    this.VehicleTypeId = this.data.VehicleTypeId;
    debugger
    if (this.data != null) {
      this.newVehicleDriverForm.controls['ScreenName'].setValue(this.data.data.ScreenName);
      this.xptsno = this.data.data.data['XPTSNo'];
      this.manifest = this.data.ManifestId;
      this.ffvs = this.data.data['ffvs'];
      this.type = this.data.data['type'];
      this.details = this.data.data['data'];
      this.newVehicleDriverForm.controls['int_manifest_id'].setValue(this.manifest);
      this.newVehicleDriverForm.controls['FFVId'].setValue(this.ffvId);
      this.vehiclelist = this.data.VehicleList;
  
      if (this.data.Vehicle) {
        this.newVehicleDriverForm.controls['VehicleNo'].setValue(this.data.VehicleNo);
        this.newVehicleDriverForm.controls['VehicleTypeName'].setValue(this.data.VehicleType);
        this.newVehicleDriverForm.controls['VehicleTypeId'].setValue(this.data.VehicleTypeId);
      }
  
      if (this.data.DriverName) {
        this.newVehicleDriverForm.controls['DriverName'].setValue(this.data.DriverName);
        this.newVehicleDriverForm.controls['DriverNo'].setValue(this.data.DriverNumber);
        this.newVehicleDriverForm.controls['DriverId'].setValue(this.data.DriverId);
        this.DLNotRequired = true;
      } else {
        this.DLNotRequired = false;
      }
    }
  
    let selectedFFVName = '';
    for (const ffv of this.ffvs) {
      if (ffv.FFVId === this.ffvId) {
        selectedFFVName = ffv.FFVName;
        break;
      }
    }
    this.newVehicleDriverForm.controls['FFVName'].setValue(selectedFFVName);
    this.selectedFFVName = selectedFFVName; // Assign the FFV name to a component variable
  }

  close() {
    this.dialogRef.close();
  }

  VehicleList: any;
  VehicleFound: boolean = false;
  IsVehicleAvailabe: boolean = true;
  invalidVehicle: boolean = false;

  filterVehicle(e) {
    // this.addEditForm.controls['VehicleNo'].setValue(null);
    if (e != null) {
      this.newVehicleDriverForm.controls['VehicleTypeId'].setValue(null);
      this.newVehicleDriverForm.controls['VehicleTypeName'].setValue(null);
      this.invalidVehicle = false;
      let VehNo = this.newVehicleDriverForm.controls['VehicleNo'].value.trim();
      //let FfvId = this.newVehicleDriverForm.controls['FFVId'].value;
      let FfvId = this.ffvId
      // if (VehNo.match(this.vehiclePattern)) {
        this._lcldeliveryService.filterVehicle(VehNo).subscribe(res => {
          if (res['Success']) {
            console.log('vehicle list ', res)
            this.VehicleList = res['Data']['vechicles'];
            console.log(this.VehicleList.length);
            if (this.VehicleList.length > 0) {
              this.VehicleFound = true;             
              if (this.VehicleList[0]['IsAvailable']) {
                if (FfvId == this.VehicleList[0]['FFVId']) {
                  this.IsVehicleAvailabe = true;
                  this.newVehicleDriverForm.controls['VehicleNo'].setValue(this.VehicleList[0]['VehicleNo']);
                  this.newVehicleDriverForm.controls['VehicleId'].setValue(this.VehicleList[0]['VehicleId']);
                  this.newVehicleDriverForm.controls['VehicleTypeName'].setValue(this.VehicleList[0]['VehicleType']);
                  this.newVehicleDriverForm.controls['VehicleTypeId'].setValue(this.VehicleList[0]['VehicleTypeId']);
                  this.newVehicleDriverForm.controls['OwnerName'].setValue(this.VehicleList[0]['OwnerName']);
                  this.mParivahanFetched = true
                  this.newVehicleDriverForm.get('VehicleNo').disable();  // to disable


                }
                else {
                  this.alertService.createAlert('Vehicle FFV does not match with the selected FFV ', 0)
                  this.mParivahanFetched = false
                }
              } else {
                this.IsVehicleAvailabe = false;
                this.newVehicleDriverForm.controls['VehicleNo'].setValue(null);
                this.mParivahanFetched = false
              }
            }
            else {
              this.VehicleFound = false;
              this.newVehicleDriverForm.controls['VehicleId'].setValue(null);
              this.newVehicleDriverForm.controls['VehicleNo'].setValue(VehNo);
              this.newVehicleDriverForm.controls['VehicleTypeName'].setValue(this.VehicleType);
              this.newVehicleDriverForm.controls['VehicleTypeId'].setValue(this.VehicleTypeId);
              this.getVehicleDetails(VehNo);
            }
          }
          else {
            this.newVehicleDriverForm.controls['VehicleId'].setValue(null);
            this.newVehicleDriverForm.controls['VehicleTypeName'].setValue(this.VehicleType);
            this.newVehicleDriverForm.controls['VehicleTypeId'].setValue(this.VehicleTypeId);
            this.alertService.createAlert('Something went wrong', 0);
            // this.mParivahanFetched = false
          }
        })
      // } else {
        // this.invalidVehicle = true;
        // this.mParivahanFetched = false
        // this.newVehicleDriverForm.controls['VehicleNo'].setValue('');
      //}
    }
  }


  filteredList: any;
  DriverChanged: boolean = false;
  DriverSearch: boolean = false;
  DLNo: any;
  DifferentFFV: boolean = false;
  filterdriver(type) {
    this.DLVerified = false;
    this.DLNo = this.newVehicleDriverForm.controls['DlNumber'].value.trim();

    this._vendorService.getFfvDriversDLNo(this.DLNo).subscribe((res: any) => {
      this.filteredList = res.Data.Drivers;

      if (this.filteredList.length > 0) {
        this.showdate = false
        if (this.filteredList[0].IsAvailable == true) {
          this.DriverAvailable = true
          if (this.filteredList[0].FfvId == this.ffvId) {
            this.alertService.createAlert('Driver Verified', 1)
            this.DLVerified = true;
          }
          else {
            this.DifferentFFV = true;
            this.alertService.createAlert(`Driver is of another ffv,${this.filteredList[0].FFVName},Please enter correct licence number`, 0)
          }
        }
        else {
          if (this.DL_Number == this.filteredList[0].DlNumber) {
            this.DriverAvailable = true
          }
          else {
            this.DriverAvailable = false
          }
        }

        if (this.DL_Number == this.filteredList[0].DlNumber) {
          this.DriverChanged = false
        }
        else {
          this.DriverChanged = true
        }


        this.newVehicleDriverForm.controls['DlNumber'].setValue(this.filteredList[0].DlNumber);
        if (this.DifferentFFV == true) {
          this.newVehicleDriverForm.controls['DriverName'].setValue(null);
          this.newVehicleDriverForm.controls['DriverNo'].setValue(null);
          this.newVehicleDriverForm.controls['DriverId'].setValue(null);

        }
        else {
          this.newVehicleDriverForm.controls['DriverName'].setValue(this.filteredList[0].DriverName);
          this.newVehicleDriverForm.controls['DriverNo'].setValue(this.filteredList[0].PhoneNumber);
          this.newVehicleDriverForm.controls['DriverId'].setValue(this.filteredList[0].DriverId);

        }
        //this.newVehicleDriverForm.controls['FFVId'].setValue(this.filteredList[0].FfvId);


      }
      else {
        this.showdate = true;
        this.DriverAvailable = true
      }

    })
  }

  hide: boolean = false
  GetDriverDetails(type) {
    if (this.newVehicleDriverForm.controls['DriverDOB'].value == '' || this.newVehicleDriverForm.controls['DlNumber'].value == '') {
      this.alertService.createAlert("Please select DL/DOB", 0);
    }
    else {

      let datavalue = {
        "id_number": this.newVehicleDriverForm.controls['DlNumber'].value,
        "dob": this.datepipe.transform(this.newVehicleDriverForm.controls['DriverDOB'].value, 'yyyy-MM-dd')
      }
      this._FfvDetailsService.validatedriverDL(datavalue).subscribe(data => {
        if (data['success'] == true) {
          this.newVehicleDriverForm.controls['DriverName'].setValue(data['data']['name']);
          this.newVehicleDriverForm.controls.DlClass.setValue(data['data'].vehicle_classes[0]);
          this.newVehicleDriverForm.controls.ValidUpto.setValue(new Date(this.datepipe.transform(data['data'].doe, 'yyyy-MM-dd')));
          this.newVehicleDriverForm.controls.Address.setValue(data['data'].permanent_address);
          this.DLVerified = true;
          this.DriverChanged = true
          this.DriverAvailable = true
          // this.hide = true
        }
        else {
          this.alertService.createAlert("DL Number/DOB is Invalid", 0);

        }
      },
        (error) => {
          this.alertService.createAlert("DL Number/DOB is Invalid", 0);
        });
    }
  }
  onlyNumbers(event) {
    let k;
    k = event.charCode;
    return (k > 47 && k < 58);
  }


  AddNewDV(type) {
    debugger
    if (this.mParivahanFetched == true) {
      if (this.DLVerified || this.DLNotRequired == true) {
        this.newVehicleDriverForm.get('VehicleNo').enable();
  
        if (
          this.ffvId == null || this.ffvId.value == '' ||
          this.newVehicleDriverForm.controls['VehicleNo'].value == null || this.newVehicleDriverForm.controls['VehicleNo'].value == '' ||
          this.newVehicleDriverForm.controls['DriverNo'].value == null || this.newVehicleDriverForm.controls['DriverNo'].value == '' ||
          this.newVehicleDriverForm.controls['DlNumber'].value == null || this.newVehicleDriverForm.controls['DlNumber'].value == '' ||
          this.newVehicleDriverForm.controls['tripRemark'].value == null || this.newVehicleDriverForm.controls['tripRemark'].value == '' &&
          this.manifest != null && this.manifest != "" && typeof (this.manifest) != 'undefined'
        ) {
          if (this.newVehicleDriverForm.controls['VehicleNo'].value == null || this.newVehicleDriverForm.controls['VehicleNo'].value == '') {
            this.alertService.createAlert('Vehicle Number Required', 0);
            this.newVehicleDriverForm.controls['VehicleNo'].markAsTouched();
          } else {
            this.newVehicleDriverForm.controls['VehicleNo'].markAsTouched();
          }
  
          this.newVehicleDriverForm.controls['DriverNo'].markAsTouched();
          this.newVehicleDriverForm.controls['DlNumber'].markAsTouched();
          this.newVehicleDriverForm.controls['tripRemark'].markAsTouched();
  
          if (this.mParivahanFetched && this.newVehicleDriverForm.controls['Vehicle'].value != null) {
            this.transhipmentService.upsertNewOldDispatchvehicle(this.newVehicleDriverForm.value).subscribe(res => {
              if (res['Success']) {
                this.dialogRef.close(res['Success']);
              }
            });
          }
        } else {
          this.transhipmentService.upsertNewOldDispatchvehicle(this.newVehicleDriverForm.value).subscribe(res => {
            if (res['Success']) {
              this.dialogRef.close(res['Success']);
            }
          });
        }
      } else {
        this.alertService.createAlert('Please validate driver', 0);
      }
    } else {
      this.alertService.createAlert('Please Verify Vehicle !!', 0);
    }
  }

  // disableButton() {
  //   this.isDisabled = true;
  // }

  getVehicleDetails(VehNo) {
    // let VehNo = this.newVehicleDriverForm.controls['VehicleNo'].value.trim();
    let datavalue = {
      "id_number": VehNo
    }
    var FFVList = this.ffvs
    var selectedFFV = this.selectedFFVName;
    this._FfvDetailsService.validatevehicleRC(datavalue).subscribe(data => {
      console.log(data)
      if (data['success'] == true) {
       debugger

      if(selectedFFV.toUpperCase() == 'INSTANT TRANSPORT SOLUTION PRIVATE LIMITED'){
        this.instantVehicleDriver(VehNo);
      }
        this.newVehicleDriverForm.controls['MakeModel'].setValue(data['data']['maker_model'])
        this.newVehicleDriverForm.controls['VehicleRegistrationDate'].setValue(data['data']['registration_date'])
        this.newVehicleDriverForm.controls['VehicleTaxExpiry'].setValue(data['data']['tax_upto'])
        this.newVehicleDriverForm.controls['VehicleInsuranceExpiry'].setValue(data['data']['insurance_upto'])
        this.newVehicleDriverForm.controls['VehicleFitnessExpiry'].setValue(data['data']['fit_up_to'])
        this.newVehicleDriverForm.controls['VehiclePollutionExpiry'].setValue(data['data']['pucc_upto'])
        this.newVehicleDriverForm.controls['VehicleFiveYearExpiry'].setValue(data['data']['permit_valid_upto'])
        this.newVehicleDriverForm.controls['VehicleNo'].setValue(data['data']['rc_number'])

        this.newVehicleDriverForm.controls['Vehicle'].setValue(data['data']['rc_number'])
        this.newVehicleDriverForm.controls['NationalPermit'].setValue(data['data']['national_permit_upto'] ?? data['data']['permit_valid_upto']);

        // var checkVehicleFFV = FFVList.some(x => this.normalizeName(x.FFVName.toLowerCase()) === data['data']['owner_name'].toLowerCase())
        var checkVehicleFFV = FFVList.some(x => this.normalizeName(x.FFVName) === this.normalizeName(data['data']['owner_name']));
        if (checkVehicleFFV) {
          if(this.normalizeName(selectedFFV) === this.normalizeName(data['data']['owner_name'])){
            this.newVehicleDriverForm.controls['OwnerName'].setValue(data['data']['owner_name']);
            this.alertService.createAlert('Vehicle Verified Successfully.', 1)
            this.newVehicleDriverForm.get('VehicleNo').disable();  // to disable 
            this.mParivahanFetched = true
          }  else  {
            this.alertService.createAlert('You cannot add this vehicle('+VehNo+')in this FFV('+selectedFFV+').', 0)
            this.newVehicleDriverForm.controls['Vehicle'].setValue(null);
            this.newVehicleDriverForm.controls['VehicleNo'].setValue(null);
            this.mParivahanFetched = false;
          }
        }      else  {
          this.mParivahanFetched = true
          this.newVehicleDriverForm.controls['OwnerName'].setValue(data['data']['owner_name']);
          this.alertService.createAlert('Vehicle Verified Successfully.', 1)
          this.newVehicleDriverForm.get('VehicleNo').disable();  // to disable  
        }
        console.log(this.newVehicleDriverForm.value)

        // this.alertService.createAlert('Vehicle Verified Successfully.', 1)
        // this.newVehicleDriverForm.get('VehicleNo').disable();  // to disable

      } else if (data['success'] == false) {
        this.mParivahanFetched = false;
        this.alertService.createAlert(data['message'], 1)

      }
      else{
        this.mParivahanFetched = false;
        this.alertService.createAlert('Verification Failed.', 0)
      }
      },
      (error) => {  
       this.alertService.createAlert('Verification Failed.', 0)
      });
  }

  alphaNumeric(event) {
    const char = String.fromCharCode(event.charCode);

    // Convert lowercase character to uppercase
    const uppercaseChar = char.toUpperCase();

    // Prevent spaces and update the input value
    if (char === ' ' || char !== uppercaseChar) {
      this.alertService.createAlert('Enter Vehicle No in Uppercase', 2)
      event.preventDefault();
      const inputElement = event.target;
      const currentValue = inputElement.value;
      const newValue = currentValue.replace(char, uppercaseChar).replace(/\s/g, '');
      inputElement.value = newValue;
    }

    return TextValidator.alphaNumeric(event);
  }

  onPaste(event) {
    const clipboardData = event.clipboardData || window.Clipboard;
    const pastedText = clipboardData.getData('text');

    // Remove spaces from the pasted text and convert to uppercase
    const cleanedText = pastedText.replace(/\s/g, '').toUpperCase();

    // Insert the cleaned text into the input field
    const inputElement = event.target;
    const currentValue = inputElement.value;
    const selectionStart = inputElement.selectionStart;
    const selectionEnd = inputElement.selectionEnd;
    const newValue =
      currentValue.substring(0, selectionStart) +
      cleanedText +
      currentValue.substring(selectionEnd);

    inputElement.value = newValue;

    // Prevent default paste behavior
    event.preventDefault();
  }

  instantVehicleDriver(vehicleNo){
    this._FfvDetailsService.validateINSTANTvehicleDriver(vehicleNo).subscribe(
      (response: any) => {
        if (response && response.Success && response.Data) {
          this.DLVerified = true;
          this.newVehicleDriverForm.controls['DriverName'].setValue(response.Data.DriverName);
          this.newVehicleDriverForm.controls['DlNumber'].setValue(response.Data.DriverDlNo);
          this.newVehicleDriverForm.controls['DriverNo'].setValue(response.Data.DriverMobileNumber);
          this.DLVerified = true;
        } else {
          console.error('Invalid response:', response);
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
    
  }

  normalizeName = (name: string) => {
    return name
      .toUpperCase()
      .replace(/\bPVT(\.|\s*)LTD\b/g, 'PRIVATE LIMITED') // Replace all variations with 'PRIVATE LIMITED'
      .trim();
  };
}
