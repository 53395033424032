
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AlertService } from 'src/app/shared/services/alert.service';
import { CustomersService } from '../../customers/customers.service';
import { Ffv2Service } from '../../ffv2/ffv2.service';
import { VendorService } from '../vendor.service';
@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss']
})
export class AddVendorComponent implements OnInit {
  formData: FormData = new FormData();
  Document: FormData = new FormData();
  addFFvForm: FormGroup;
  addBillingDetails: FormGroup;
  addBankDetails: FormGroup;
  addDocumentDetails: FormGroup;
  StateList= [];
  DisctrictList: [];
  LocationList: [];
  PostCodeList: [];
  BillingOfficeTypesList: [];
  BillingMethodsList: [];
  BillingFrequenciesist: [];
  AccountTypesList: [];
  GSTNatureList: [];
  RegistrationTypeList: [];
  DuplicatePhoneNumber = false;
  IsDiscountSelected: boolean = false;
  IsToPaidSelected: boolean = false;
  docList = [];
  isCentral = false;
  InvalidGSTIN = false;
  InvalidGstinLength = false;
  isPerTrip = false;
  XPBillingOffices = [];

  ServicesList = [];
  SelectedServiceList = [];
  autoStateId: any;
  ServiceType= [];
  VendorType=[];
  BillingFrequenciesistCopy: any;
  pennydrop: boolean = false;

  constructor(public fb: FormBuilder, private _vendorService: VendorService,public dialogRef: MatDialogRef<AddVendorComponent>, @Inject(MAT_DIALOG_DATA) public _ffvObject: any,
    private alertService: AlertService, private _ffv2Service: Ffv2Service, private _customerService: CustomersService) {
    this.createFfvForm();
    this.addBillingDetailForm();
    this.addBankDetailsForm();
    this.createDocumentDetailsForm();
  }

  ngOnInit() {
    this.getLookUpOption();
  }

  GetCustomerDetails(){
    let Gsttin = this.addFFvForm.controls['GSTinInput'].value;
    
    if(Gsttin == '' || Gsttin == null ){
      this.alertService.createAlert("Please enter GST Number",0);
    }
    else if( Gsttin.length < 15){
      this.alertService.createAlert(" Oops Wrong GST Number",0);
    }
    else{
    this._customerService.getCustomerDetailsfromGSTIN(Gsttin).subscribe(data=>{
      if(data!=null){
       // console.log(data);
        if(data['Data'].CustomerName == null || data['Data'].CustomerName == ''){
          this.alertService.createAlert("Uh oh! No Data Found. Please fill up the details",0);
        }
        else{
          this.getLookUpOption();
          this.getDistrict(data['Data'].StateId);
          //this.autoStateId=data['Data'].StateId;
          this.getLocations(data['Data'].DistrictId);
          this.getPostCodes(data['Data'].LocationId);
          this.addFFvForm.controls["FfvName"].setValue(data['Data'].CustomerName);
          this.addFFvForm.controls["email"].setValue(data['Data'].CustomerAbbreviation);
          this.addFFvForm.controls["address"].setValue(data['Data'].Address);
          this.addFFvForm.controls["state"].setValue(data['Data'].StateId);
          this.addFFvForm.controls["postalCode"].setValue(data['Data'].PostcodeId);
          this.addFFvForm.controls["district"].setValue(data['Data'].DistrictId);
          this.addFFvForm.controls["location"].setValue(data['Data'].LocationId);
          this.addFFvForm.controls["service_type"].setValue(data['Data'].ffv_service_type_name);
          this.addBillingDetails.controls["PAN"].setValue(data['Data'].PAN);
        }
      }
    });
    
  }
  }

  getLookUpOption() {
 //debugger

    this._ffv2Service.getFfvLookupOptions().subscribe((res: any) => {
      //console.log(res.Data,'pghvj');
      this.StateList = res.Data.StatesList;
      this.BillingOfficeTypesList = res.Data.BillingOfficeTypesList;
      this.BillingMethodsList = res.Data.FFVBillingMethodsList;
      this.BillingFrequenciesist = res.Data.BillingFrequenciesist;
      this.BillingFrequenciesistCopy = res.Data.BillingFrequenciesist;
      this.AccountTypesList = res.Data.AccountTypesList;
      this.GSTNatureList = res.Data.GSTNatureList;
      this.ServicesList = res.Data.FFVServicesList;
      this.XPBillingOffices = res.Data.XPBillingOfficeList;
      this.RegistrationTypeList = res.Data.Registration_list;
      this.ServiceType = res.Data.Lookup_option;
      this.VendorType=res.Data.vendor_Type_List;
      if (this._ffvObject) {
        this.getFfvById()
      }
    })
  }

  getFfvById() {
    this._ffv2Service.getFfvById(this._ffvObject.FfvId).subscribe((res: any) => {
     console.log(res.Data,'FFV Details');
      this.DisctrictList = res.Data.Districts;
      this.LocationList = res.Data.Locations;
      this.PostCodeList = res.Data.PostCodes;
      this.docList = res.Data.Ffv.FfvDocumentList;

      //Tomeshwar
      this.ServiceType=res.Data.Lookup_option;
      this.VendorType=res.Data.vendor_type;
      console.log(this.VendorType,'Hello ');
     
      this.setBasicFormDetails(res.Data.Ffv)
      this.setBillingFormDetails(res.Data.Ffv)
      this.setBankDetails(res.Data.Ffv)

    });
  }

  setBasicFormDetails(data) {
   // console.log(data, "Data")
    //debugger;
    this.addFFvForm.controls["FfvName"].setValue(data["FfvName"]);
    this.addFFvForm.controls["FfvAbbreviation"].setValue(data["FfvAbbreviation"]);
    this.addFFvForm.controls["email"].setValue(data["Email"]);
    this.addFFvForm.controls["phone"].setValue(data["ContactNumber"]);
    this.addFFvForm.controls["address"].setValue(data["Address"]);
    this.addFFvForm.controls["state"].setValue(data["StateId"]);
    this.addFFvForm.controls["postalCode"].setValue(data["PostcodeId"]);
    this.addFFvForm.controls["district"].setValue(data["DistrictId"]);
    this.addFFvForm.controls["location"].setValue(data["LocationId"]);
    this.addFFvForm.controls["service_type"].setValue(data["ffv_service_type"]);
    this.addFFvForm.controls["IsTranspoter"].setValue(data["IsTranspoter"]);
    this.addFFvForm.controls["vendor_type"].setValue(data["vendor_type"]);

    this.VendorTypechange(data["vendor_type"])
  }

  setBillingFormDetails(data) {
    debugger;
    this.addBillingDetails.controls["billingOffice"].setValue(data["BillingOfficeTypeId"]);
    let billingOffice = this.BillingOfficeTypesList.find(x => x['LookupId'] == data["BillingOfficeTypeId"])['LookupName'];
    //this.addBillingDetails.controls['BilligOfficeId'].setValue(val);
    //this.addBillingDetails.controls['BilligOffice'].setValue(billingOffice);
    this.addBillingDetails.controls["IntransitTrip"].setValue(data["intransitBilling"]);
    this.addBillingDetails.controls["isGraceDays"].setValue(data["isGraceDays"]);
    this.addBillingDetails.controls["PAN"].setValue(data["PAN"]);
    this.addBillingDetails.controls["billingState"].setValue(data["CentralBillingStateId"]);
    this.addBillingDetails.controls["TAN"].setValue(data["TAN"]);
    this.addBillingDetails.controls["billingAddress"].setValue(data["CentralBillingAddress"]);
    this.addBillingDetails.controls["TDS"].setValue(data["TDS"]);
    this.addBillingDetails.controls["registrationType"].setValue(data["registration_type_id"]);
    this.addBillingDetails.controls["gstNature"].setValue(data["GSTNatureId"]);
    this.gstNatureChanged(data["GSTNatureId"]);
    this.addBillingDetails.controls["billingFrequnecy"].setValue(data["BillingFrequencyId"]);
    if((data["BillingFrequency"] == 'Per Trip' && data["BillingMethod"] == 'Paid') || (data["BillingFrequency"] == 'Per Trip' && data["BillingMethod"] == 'To Pay') ){
      this.isPerTrip=true;
    }
    this.PerTripSelection();
    //this.addBillingDetails.controls["GST"].setValue(data["GST"]);
    //this.addBillingDetails.controls["GST"].setValue(data["ServiceDescId"]);
    this.addBillingDetails.controls["billingMethod"].setValue(data["BillingMethodId"]);
    this.addBillingDetails.controls["GSTin"].setValue(data["GSTIN"]);
    this.addBillingDetails.controls["creditPeriod"].setValue(data["CreditPeriod"]);
    
    this.addBillingDetails.controls["Advance"].setValue(data["Advance"]);
    this.addBillingDetails.controls["Balance"].setValue(data["Balance"]);
    
    
    this.addBillingDetails.controls["stateCode"].setValue(data["StateCode"]);
    this.IsDiscountSelected = data["IsDiscount"];
    this.addBillingDetails.controls.IsDiscount.setValue(data["IsDiscount"]);
    this.addBillingDetails.controls.IsFVPerTrip.setValue(data["IsFVPerTrip"]);
    this.ToggleDiscountFields();
    this.addBillingDetails.controls["billDiscount"].setValue(data["DiscountPercent"]);
    //this.addBillingDetails.controls["descOfService"].setValue(data["ServiceDesc"]);
    this.addBillingDetails.controls["discountDays"].setValue(data["DiscountDays"]);
    //this.addBillingDetails.controls["HSN"].setValue(data["SACCode"]);
    this.billingOfficeChanged(data["BillingOfficeTypeId"]);

  
    this.getTempServices(data["GSTNatureId"]);
    if (data["GSTNatureId"] == 21) {
      this.isCentral = false;
      this.addBillingDetails.controls['GSTin'].setValidators([]);
      this.addBillingDetails.controls['GSTin'].disable();
      this.addBillingDetails.controls['stateCode'].setValidators([]);
      this.addBillingDetails.controls['stateCode'].disable();
      } else {
        this.isCentral = true;
        this.addBillingDetails.controls['billingState'].setValidators([Validators.required]);
        this.addBillingDetails.controls['GSTin'].setValidators([Validators.required]);
        this.addBillingDetails.controls['stateCode'].setValidators([Validators.required]);
        this.addBillingDetails.controls['GSTin'].enable();
      }

    this.addBillingDetails.controls["GST"].setValue(data["ServiceDescId"]);
    this.addBillingDetails.controls["descOfService"].setValue(data["ServiceDesc"]);
    this.addBillingDetails.controls["HSN"].setValue(data["SACCode"]);


    this.addBillingDetails.controls["IsAuto"].setValue(data["IsAuto"]);
    //this.addBillingDetails.controls["XPAddressId"].setValue(data["XPAddressId"]);
    this.addBillingDetails.controls["IsAuto"].setValue(data["IsAuto"]);
    this.addBillingDetails.controls["IsAuto"].setValue(data["IsAuto"]);
     if(data["BillingMethodId"] == 40) {
      this.IsToPaidSelected = true;
      } else {
        this.IsToPaidSelected = false;
      }
      this.ToggleAdvacnceFields();
      if (billingOffice == 'Central') {
        this.isCentral = true;
        this.addBillingDetails.controls['billingState'].setValidators([Validators.required]);
        this.addBillingDetails.controls['billingState'].enable();
        if(data["GSTNatureId"] == 21){
          this.addBillingDetails.controls['GSTin'].setValidators([]);
        this.addBillingDetails.controls['GSTin'].disable();
        }
        else{
          this.addBillingDetails.controls['GSTin'].setValidators([Validators.required]);
        this.addBillingDetails.controls['GSTin'].enable();
        }
        
        this.addBillingDetails.controls['billingAddress'].setValidators([Validators.required]);
        this.addBillingDetails.controls['billingAddress'].enable();
        this.addBillingDetails.controls['stateCode'].setValidators([Validators.required]);
      } else {
        this.isCentral = false;
        this.addBillingDetails.controls['billingState'].setValue(null);
        this.addBillingDetails.controls['billingState'].setValidators([]);
        this.addBillingDetails.controls['billingState'].disable();
        this.addBillingDetails.controls['billingAddress'].setValue(null);
        this.addBillingDetails.controls['billingAddress'].setValidators([]);
        this.addBillingDetails.controls['billingAddress'].disable();
        this.addBillingDetails.controls['GSTin'].setValue(null);
        this.addBillingDetails.controls['GSTin'].setValidators([]);
        this.addBillingDetails.controls['GSTin'].disable();
        this.addBillingDetails.controls['stateCode'].setValue(null);
        this.addBillingDetails.controls['stateCode'].setValidators([]);
        this.addBillingDetails.controls['stateCode'].disable();
      }
  }

  setBankDetails(data) {
   // debugger;
    this.addBankDetails.controls["bankAccountNo"].setValue(data["AccountNumber"]);
    this.addBankDetails.controls["bankBranch"].setValue(data["BankBranch"]);
    this.addBankDetails.controls["accountType"].setValue(data["AccountTypeId"]);
    this.addBankDetails.controls["BankIFSC"].setValue(data["IFSC"]);
    this.addBankDetails.controls["bankName"].setValue(data["BankName"]);
    this.addBankDetails.controls["bankAddress"].setValue(data["BankAddress"]);
    this.addBankDetails.controls["BeneficiaryName"].setValue(data["BeneficiaryName"]);
  }

  getLocations(id) {
    this._ffv2Service.GetLocationsDropdownlist(id).subscribe(res => {
      this.LocationList = res['Data']
    })
  }

  getDistrict(id) {
    this._ffv2Service.GetDistrictsDropdownlist(id).subscribe(res => {
      this.DisctrictList = res['Data']
    })
  }
  getPostCodes(id) {
    this._ffv2Service.getPostCodeDropdownList(id).subscribe(res => {
      this.PostCodeList = res['Data']
    })
  }
  getFfvDocuments() {
    this._ffv2Service.getFfvDocuments(this._ffvObject.FfvId).subscribe(res => {
      this.docList = res['Data'];
    })
  }
  upsertFfvDocument(data) {
    this.Document.delete('Document');
    this.Document.append('Document', JSON.stringify(data));
    this._ffv2Service.upsertFfvDocument(this.Document).subscribe(res => {
      this.getFfvDocuments();
      this.Document.delete("123")
    })
  }
  deleteFfvDocument(id) {
    this._ffv2Service.deleteFfvDocument(id).subscribe((res) => {
      this.getFfvDocuments();
    })
  }

  BillingMethodChanged(val) {
    if (val == 'Paid' || val == 'To Pay') {
      this.addBillingDetails.controls.IsDiscount.setValue(false);
      this.addBillingDetails.get('IsDiscount').disable();

      //Tomeshwar
      this.addBillingDetails.controls.IntransitTrip.setValue(true);
      this.addBillingDetails.get('IntransitTrip').disable();

      // pavan
      this.addBillingDetails.controls.creditPeriod.disable();
      this.addBillingDetails.controls["creditPeriod"].setValue(null);
      this.addBillingDetails.controls.billingFrequnecy.disable();

      let temp = this.BillingFrequenciesist.find(x => x['LookupName'] == 'Per Trip')['LookupId'];
      this.addBillingDetails.controls["billingFrequnecy"].setValue(temp);

      this.addBillingDetails.controls["IsAuto"].setValue(true);
      //this.ToggleAdvacnceFields();

    } else if (val == 'Credit'){
      this.addBillingDetails.controls.IsDiscount.setValue(true);
      this.addBillingDetails.get('IsDiscount').enable();

      //pavan
      this.addBillingDetails.controls.billingFrequnecy.enable();
      this.addBillingDetails.controls.creditPeriod.enable();

      //Tomeshwar
      this.addBillingDetails.controls.IntransitTrip.setValue(false);
      this.addBillingDetails.get('IntransitTrip').disable();
      
    
    }
    this.ToggleAdvacnceFields();
    this.ToggleDiscountFields();
  }

  
  updatePercentage(){
    let advance=Number(this.addBillingDetails.controls['Advance'].value);
    // let gst_amount=Math.round(payment_amount*((this.updatepayment["gst_percent"])/100))
    let balance = 100 - advance;
    this.addBillingDetails.controls['Balance'].setValue(balance);
  }


  ToggleDiscountFields() {
    if (this.addBillingDetails.get('IsDiscount').value == true) {
      this.addBillingDetails.controls.billDiscount.enable();
      this.addBillingDetails.controls.discountDays.enable();
    } else {
      this.addBillingDetails.controls.billDiscount.disable();
      this.addBillingDetails.controls.discountDays.disable();
      this.addBillingDetails.controls["billDiscount"].setValue(null);
      this.addBillingDetails.controls["discountDays"].setValue(null);
      
    }

  }

  ToggleAdvacnceFields() {
   
    if (this.addBillingDetails.get('billingMethod').value == 40) {
      this.addBillingDetails.controls.Advance.enable();
      //this.addBillingDetails.controls.Balance.enable();
    } else {
      this.addBillingDetails.controls.Advance.disable();
      this.addBillingDetails.controls.Balance.disable();
      this.addBillingDetails.controls["Advance"].setValue(null);
      this.addBillingDetails.controls["Balance"].setValue(null);
    }
  }


  PerTripSelection(){
    if (this.isPerTrip == true) {
      this.addBillingDetails.controls.billingFrequnecy.disable();
      this.addBillingDetails.controls.creditPeriod.disable();
      this.addBillingDetails.controls.IsDiscount.disable();
    } 
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32)
  }

  omit_all_char(event) {
    var k;
    k = event.charCode;
    return ((k > 47 && k < 58) || k == 8 || k == 32)
  }
  ValidateGSTIN() {
  
    if (this.addBillingDetails.get('billingState').value) {
      let gstin: string = this.StateList.find(state => state['StateId'] == this.addBillingDetails.get('billingState').value)['StateGSTIN'];
      let enteredGSTIN = this.addBillingDetails.get('GSTin').value.substring(0, 2);
      //let val = parseInt(this.addBillingDetails.get('GSTin').value).toString();
     // let gstinSub = val.substring(0, gstin.length);
      if (gstin != enteredGSTIN) {
        this.InvalidGSTIN = true;
      } else {
        this.InvalidGSTIN = false;
      }
    }
  }
  ValidateGstInLength() {
    let gstinFormValue = this.addBillingDetails.get('GSTin').value.toString();
    if (gstinFormValue.length != 15) {
      this.InvalidGstinLength = true
    } else {
      this.InvalidGstinLength = false
      this.ValidateGSTIN();
    }
    //this.ValidateGSTIN();
  }
  accept_all_number_and_text(event) {
    let k = event.charCode;
    return ((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || (k >= 48 && k <= 57))
  }

  billingOfficeChanged(val) {
    let billingOffice = this.BillingOfficeTypesList.find(x => x['LookupId'] == val)['LookupName'];
    this.InvalidGSTIN = false;
    this.InvalidGstinLength = false;
    //this.addBillingDetails.controls['BilligOfficeId'].setValue(val);
    //this.addBillingDetails.controls['BilligOffice'].setValue(billingOffice);
    if (billingOffice == 'Central') {
      this.isCentral = true;
      this.addBillingDetails.controls['billingState'].setValidators([Validators.required]);
      this.addBillingDetails.controls['billingState'].enable();
      this.addBillingDetails.controls['GSTin'].setValidators([Validators.required]);
      
      if(this.gstNatureGSTBilling == true){
        this.addBillingDetails.controls['GSTin'].enable();
      }
      else{
        this.addBillingDetails.controls['GSTin'].disable();
      }
      
      this.addBillingDetails.controls['billingAddress'].setValidators([Validators.required]);
      this.addBillingDetails.controls['billingAddress'].enable();
      this.addBillingDetails.controls['stateCode'].setValidators([Validators.required]);
      //this.billingStateChanged(this.autoStateId);
    } else {
      this.isCentral = false;
      this.addBillingDetails.controls['billingState'].setValue(null);
      this.addBillingDetails.controls['billingState'].setValidators([]);
      this.addBillingDetails.controls['billingState'].disable();
      this.addBillingDetails.controls['billingAddress'].setValue(null);
      this.addBillingDetails.controls['billingAddress'].setValidators([]);
      this.addBillingDetails.controls['billingAddress'].disable();
      this.addBillingDetails.controls['GSTin'].setValue(null);
      this.addBillingDetails.controls['GSTin'].setValidators([]);
      this.addBillingDetails.controls['GSTin'].disable();
      this.addBillingDetails.controls['stateCode'].setValue(null);
      this.addBillingDetails.controls['stateCode'].setValidators([]);
      this.addBillingDetails.controls['stateCode'].disable();
    }
  }

  billingStateChanged(val) {
    console.log(val,"saldnsand")
    let stateGSTINCode = this.StateList.filter(x => x['StateId'] == val)[0]['StateGSTIN'];
    //this.addBillingDetails.controls['CentralBillingStateId'].setValue(val);
    //this.addBillingDetails.controls['CentralBillingState'].setValue(this.StateList.filter(x =>x['StateId'] == val)[0]['StateName']);
    if (stateGSTINCode.length == 1) {
      stateGSTINCode = '0' + stateGSTINCode;
    }
    this.addBillingDetails.controls['stateCode'].setValue(stateGSTINCode);
    this.addBillingDetails.controls['billingState'].setValue(val);
    //this.addBillingDetails.controls['billingState'].setValue(this.StateList.filter(x => x.StateId == val)[0].StateName);

  }
  
 gstNatureGSTBilling : boolean ;
 
  gstNatureChanged(val) {
    this.addBillingDetails.controls['gstNature'].setValue(val);

    if (val != null || val != 0 || val != '') {
      if (val == 21) {
      this.isCentral = false;
      this.addBillingDetails.controls['GSTin'].setValue(null);
      this.addBillingDetails.controls['GSTin'].setValidators([]);
      this.addBillingDetails.controls['GSTin'].disable();
     // this.addBillingDetails.controls['stateCode'].setValue(null);
      this.addBillingDetails.controls['stateCode'].setValidators([]);
      this.addBillingDetails.controls['stateCode'].disable();

      this.gstNatureGSTBilling = false;
      } else {
        this.isCentral = true;
        //this.addBillingDetails.controls['billingState'].setValue(null);
        this.addBillingDetails.controls['billingState'].setValidators([Validators.required]);
        this.addBillingDetails.controls['GSTin'].setValidators([Validators.required]);
        this.addBillingDetails.controls['stateCode'].setValidators([Validators.required]);
        // this.addBillingDetails.controls['GSTin'].setValue(this.addFFvForm.controls['GSTinInput'].value);
        this.addBillingDetails.controls['GSTin'].enable();

        this.gstNatureGSTBilling = true;
        // this.addBillingDetails.controls['GSTin'].disable();
      }
      console.log(this.gstNatureGSTBilling)
      this.getTempServices(val);
      
      this.addBillingDetails.controls['GST'].setValue(null);
      this.addBillingDetails.controls['descOfService'].setValue(null);
      this.addBillingDetails.controls['HSN'].setValue(null);

      if(val==21){
        let gst = this.ServicesList.filter(x => x['vc_gst_nature'] == val)[0]['ServiceId'];
        let desc = this.ServicesList.filter(x => x['vc_gst_nature'] == val)[0]['Description'];
        let sac = this.ServicesList.filter(x => x['vc_gst_nature'] == val)[0]['SACCode'];
        this.addBillingDetails.controls['GST'].setValue(gst);
        this.addBillingDetails.controls['descOfService'].setValue(desc);
        this.addBillingDetails.controls['HSN'].setValue(sac);
      }
      
    } 
    //else {
       //this.addBillingDetails.controls['GST'].disable()
    //}
    
  }

  getTempServices(val){
    let tempServices = this.ServicesList.filter(x => x.vc_gst_nature == val);
    var Associate = [];
    for (let i = 0; i < tempServices.length; i++) {
      let newName = {
        GST: tempServices[i].GST,
        ServiceId: tempServices[i].ServiceId
      };
      Associate.push(newName);
    }
    Object.assign(this, { Associate });
    this.SelectedServiceList = Associate;
  }

  serviceChanged(val) {
    let gst = this.ServicesList.filter(x => x['ServiceId'] == val)[0]['Description'];
    let sac = this.ServicesList.filter(x => x['ServiceId'] == val)[0]['SACCode'];
    this.addBillingDetails.controls['descOfService'].setValue(gst);
    this.addBillingDetails.controls['HSN'].setValue(sac);
  }
  addBillingDetailForm() {
    let DecimalPattern = /^\d*(?:[.,]\d{1,2})?$/; 
    this.addBillingDetails = this.fb.group({
      billingOffice: new FormControl('', [Validators.required]),
      PAN: new FormControl('', [Validators.required,Validators.max(10),Validators.min(10)]),
      billingState: new FormControl(''),
      TAN: new FormControl('',[Validators.max(10),Validators.min(10)]),
      billingAddress: new FormControl(''),
      TDS: new FormControl('', [Validators.required,Validators.min(0)]),
      registrationType: new FormControl('', [Validators.required,]),
      gstNature: new FormControl('', [Validators.required,]),
      billingFrequnecy: new FormControl('', [Validators.required,]),
      GST: new FormControl('', [Validators.required,]),
      billingMethod: new FormControl('', [Validators.required,]),
      GSTin: new FormControl(''),
      creditPeriod: new FormControl('', [Validators.required,]),
      stateCode: new FormControl(''),
      IsDiscount: new FormControl(false, [Validators.required]),
      billDiscount: new FormControl({ value: '', disabled: !this.IsDiscountSelected }),
      descOfService: new FormControl('', [Validators.required,]),
      discountDays: new FormControl({ value: '', disabled: !this.IsDiscountSelected }),
      HSN: new FormControl('', [Validators.required,]),
      IsAuto: new FormControl(false, Validators.compose([Validators.required])),
      //XPAddressId: new FormControl('', Validators.compose([Validators.required])),
    
      Advance: new FormControl({ value: '', disabled: this.IsToPaidSelected }, [Validators.min(1), Validators.max(100), Validators.pattern(DecimalPattern)]),
      //Balance: new FormControl({ value: '', disabled: this.IsToPaidSelected }),
      Balance: new FormControl({ value: ''}),
      IsFVPerTrip: new FormControl(false, [Validators.required]),
      IntransitTrip: new FormControl(''),
      isGraceDays:new FormControl(false),

    })
  }

  ValidateNumber(event) {
    return parseFloat(event.target.value).toFixed(2);
  }

  addBankDetailsForm() {
    this.addBankDetails = this.fb.group({
      bankAccountNo: new FormControl('', [Validators.required,]),
      bankBranch: new FormControl('', [Validators.required,]),
      accountType: new FormControl('', [Validators.required,]),
      BankIFSC: new FormControl('', [Validators.required,]),
      bankName: new FormControl('', [Validators.required,]),
      bankAddress: new FormControl('', [Validators.required,]),
      BeneficiaryName: new FormControl('', [Validators.required,]),
      DeclarationChbox: new FormControl(false),
    })
  }
  createDocumentDetailsForm() {
    this.addDocumentDetails = this.fb.group({
      DocDesc: new FormControl(''),
      Doc: new FormControl(''),
    })
  }

  createFfvForm() {
    this.addFFvForm = this.fb.group({
      FfvName: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhiteSpaceValidator]),
      FfvAbbreviation: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.maxLength(70), this.noWhiteSpaceValidator]),
      phone: new FormControl('', [Validators.required,Validators.maxLength(10),Validators.pattern('^[0-9]+$'), this.noWhiteSpaceValidator ]),
      address: new FormControl('', [Validators.required, Validators.maxLength(500), this.noWhiteSpaceValidator]),
      state: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      postalCode: new FormControl('', [Validators.required, Validators.maxLength(8)]),
      district: new FormControl('', [Validators.required, Validators.maxLength(8)]),
      location: new FormControl('', [Validators.required, Validators.maxLength(8)]),
      GSTinInput: new FormControl(),
      //Tomeshwar
      service_type: new FormControl('', [Validators.required]),
      vendor_type:new FormControl('',[Validators.required]),
      IsTranspoter:new FormControl(false,[Validators.required]),

      FFVUserName: new FormControl('', [Validators.required])
    })
  }

  noWhiteSpaceValidator(control: FormControl) {
    let isWhiteSpace = (control.value || '').trim().length === 0;
    let isValid = !isWhiteSpace;
    return isValid ? null : { 'whitespace': true };
  }
  handleFileInput(files: FileList) {
    if (files != null && files.length > 0) {
      const acceptedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
      // const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'application/vnd.ms-excel', 'image/gif', 'image/jpeg', 'application/pdf', 'image/x-eps',
      // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png'];
      if (acceptedFileTypes.includes(files.item(0).type)) {
        if (files.item(0).size <= 30000000) {
          if (this._ffvObject) {
            this.Document.append('123', files.item(0));
          }
          else {
            this.formData.delete(this.docList.length.toString());
            this.formData.append(this.docList.length.toString(), files.item(0));
          }
        } else {
          this.alertService.createAlert('File size must be less than 30 MB', 0);
        }
      }
      else {
        this.alertService.createAlert('Wrong File Format.(Accepted File Formats : PDF, JPEG, PNG)', 0);
        this.addFFvForm.controls['Doc'].setValue(null);
        return;
      }
    }
  }
  preventNegative(event: KeyboardEvent): void {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault();
    }
  }
  clearDoc() {
    this.addDocumentDetails.controls['DocDesc'].setValue(null);
    this.addDocumentDetails.controls['Doc'].setValue(null);
    this.addDocumentDetails.controls['DocDesc'].markAsUntouched();
    this.addDocumentDetails.controls['Doc'].markAsUntouched();
    this.addDocumentDetails.controls['DocDesc'].markAsPending();
    this.addDocumentDetails.controls['Doc'].markAsPending();
  }
  deleteDoc(i, item) {
    this.docList.splice(i, 1);
    this.formData.delete(i);
    if (this._ffvObject) {
      this.deleteFfvDocument(item.DocumentId);
    }
  }
  saveDoc() {
    if (
      !this.addDocumentDetails.controls['DocDesc'].value || !this.addDocumentDetails.controls['Doc'].value) {
      this.addDocumentDetails.controls['DocDesc'].markAsTouched();
      this.addDocumentDetails.controls['Doc'].markAsTouched();
    } else {
      let docName = this.addDocumentDetails.controls['DocDesc'].value;

      if (this._ffvObject) {
        let data = {
          FfvId: this._ffvObject.FfvId,
          DocumentName: docName
        }
        this.upsertFfvDocument(data);
      }
      else {
        let doc = {
          'DocumentId': null,
          'DocumentName': docName
        }
        this.docList.push(doc);
      }
      this.clearDoc();
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  Validate1stStepper(stepper) {
    if (this.addFFvForm.invalid) {
      this.markFormGroupTouched(this.addFFvForm);
      return;
    }
    else {
      stepper.next();
    }
  }
  Validate2ndStepper(stepper) {
    if (this.addBillingDetails.invalid || this.InvalidGstinLength == true || this.InvalidGSTIN == true) {
      this.markFormGroupTouched(this.addBillingDetails);
      return;
    }
    else {
      stepper.next();
    }
  }

  Validate3rdStepper(stepper) {
    if (this.addBankDetails.invalid) {
      this.markFormGroupTouched(this.addBankDetails);
      return;
    }
    else {
      stepper.next();
    }
  }

  RegType(value){
    //let reg = this.RegistrationTypeList.filter(x => x['LookupId'] == value)[0]['LookupName'];
    // this.addBillingDetails.controls['registrationType'].setValue(reg);
    if(value == "1802")
    {
      this.addBillingDetails.controls['registrationType'].setValue(value);
      this.addBillingDetails.controls['TDS'].setValue('2');
    }
    else if(value == "1803")
    {
      this.addBillingDetails.controls['registrationType'].setValue(value);
      this.addBillingDetails.controls['TDS'].setValue('1');
    }
    else if(value == "1804")
    {
      this.addBillingDetails.controls['registrationType'].setValue(value);
      this.addBillingDetails.controls['TDS'].setValue('2');
    }
  }

  saveCustomer() {
    debugger
    if (this.addFFvForm.invalid && this.DuplicatePhoneNumber ) {
      this.markFormGroupTouched(this.addFFvForm);
      return;
    }
    if (this.addBillingDetails.invalid) {
      this.markFormGroupTouched(this.addBillingDetails);
      return;
    }
    if (this.addBankDetails.invalid) {
      this.markFormGroupTouched(this.addBankDetails);
      return;
    }
    // debugger
    if(this.addBankDetails.controls['DeclarationChbox'].value == true && this.docList.length == 0 && this.pennydrop == false){
        this.alertService.createAlert('Cancelled Cheque Document is required if checkbox is selected', 0)
    }
    else{
    let data = {
      "FfvId": this._ffvObject ? this._ffvObject.FfvId : null,
      "FfvName": this.addFFvForm.get('FfvName').value,
      "FfvAbbreviation": this.addFFvForm.get('FfvAbbreviation').value,
      "Email": this.addFFvForm.get('email').value,
      "ContactNumber": this.addFFvForm.get('phone').value,
      "State": this.StateList.find(state => state['StateId'] == this.addFFvForm.get('state').value)['StateName'],
      "StateId": this.addFFvForm.get('state').value,
      "District": this.DisctrictList.find(state => state['DistrictId'] == this.addFFvForm.get('district').value)['DistrictName'],
      "DistrictId": this.addFFvForm.get('district').value,
      "Location": this.LocationList.find(state => state['LocationId'] == this.addFFvForm.get('location').value)['LocationName'],
      "LocationId": this.addFFvForm.get('location').value,
      "PostcodeId": this.addFFvForm.get('postalCode').value,
      "Postcode": this.PostCodeList.find(x => x['PostcodeId'] == this.addFFvForm.get('postalCode').value)['PostcodeName'],
      "Address": this.addFFvForm.get('address').value,
      "FFVUserName": this.addFFvForm.get('FFVUserName').value,
      //Tomeshwar
      "ffv_service_type":this.addFFvForm.get('service_type').value,
      "IsTranspoter": this.addFFvForm.get('IsTranspoter').value,
      "vendor_type":this.addFFvForm.get('vendor_type').value,
      "BillingOfficeType": this.BillingOfficeTypesList.find(state => state['LookupId'] == this.addBillingDetails.get('billingOffice').value)['LookupName'],
      "BillingOfficeTypeId": this.addBillingDetails.get('billingOffice').value,
      "CentralBillingState": this.addBillingDetails.get('billingState').value != null ? this.StateList.find(state => state['StateId'] == this.addBillingDetails.get('billingState').value)['StateName'] : null,
      "CentralBillingStateId": this.addBillingDetails.get('billingState').value,
      "CentralBillingAddress": this.addBillingDetails.get('billingAddress').value,
      "StateCode": this.addBillingDetails.get('stateCode').value,
      "PAN": this.addBillingDetails.get('PAN').value,
      "TAN": this.addBillingDetails.get('TAN').value,
      "TDS": this.addBillingDetails.get('TDS').value,
      "registration_type_id": this.addBillingDetails.get('registrationType').value,
      "intransitBilling": this.addBillingDetails.get('IntransitTrip').value,
      "isGraceDays": this.addBillingDetails.get('isGraceDays').value,
     // "GST": this.addBillingDetails.get('GST').value,
      //"ServiceDescId": this.addBillingDetails.get('descOfService').value,
     // "ServiceDesc": this.ServicesList.find(x => x['ServiceId'] == this.addBillingDetails.get('descOfService').value)['Description'],

      "GST": this.ServicesList.find(x => x['ServiceId'] == this.addBillingDetails.get('GST').value)['GST'],
      "ServiceDescId": this.addBillingDetails.get('GST').value,
      "ServiceDesc":this.addBillingDetails.get('descOfService').value,
      "IsAuto":this.addBillingDetails.get('IsAuto').value,
      //"XPAddressId":this.XPBillingOffices.find(x => x['XPAddressId'] == this.addBillingDetails.get('XPAddressId').value)['XPAddressId'],

      "Advance":this.addBillingDetails.get('Advance').value,
      "Balance":this.addBillingDetails.get('Balance').value,



      "GSTNature": this.GSTNatureList.find(x => x['LookupId'] == this.addBillingDetails.get('gstNature').value)['LookupName'],
      "GSTNatureId": this.addBillingDetails.get('gstNature').value,
      "BillingFrequency": this.BillingFrequenciesist.find(x => x['LookupId'] == this.addBillingDetails.get('billingFrequnecy').value)['LookupName'],
      "BillingFrequencyId": this.addBillingDetails.get('billingFrequnecy').value,
      "BillingMethod": this.BillingMethodsList.find(x => x['LookupId'] == this.addBillingDetails.get('billingMethod').value)['LookupName'],
      "BillingMethodId": this.addBillingDetails.get('billingMethod').value,
      "GSTIN": this.addBillingDetails.get('GSTin').value,
      "CreditPeriod": this.addBillingDetails.get('creditPeriod').value,
      "IsDiscount": this.addBillingDetails.get('IsDiscount').value,
      "IsFVPerTrip": this.addBillingDetails.get('IsFVPerTrip').value,
      "DiscountPercent": this.addBillingDetails.get('billDiscount').value,
      //"ServiceDescId": this.addBillingDetails.get('descOfService').value,
      //"ServiceDesc": this.ServicesList.find(x => x['ServiceId'] == this.addBillingDetails.get('descOfService').value)['Description'],
      "DiscountDays": this.addBillingDetails.get('discountDays').value,
      "SACCode": this.addBillingDetails.get('HSN').value,
      "AccountNumber": this.addBankDetails.get('bankAccountNo').value,
      "BankBranch": this.addBankDetails.get('bankBranch').value,
      "AccountType": this.AccountTypesList.find(x => x['LookupId'] == this.addBankDetails.get('accountType').value)['LookupName'],
      "AccountTypeId": this.addBankDetails.get('accountType').value,
      "IFSC": this.addBankDetails.get('BankIFSC').value,
      "BankName": this.addBankDetails.get('bankName').value,
      "BankAddress": this.addBankDetails.get('bankAddress').value,
      "FfvDocumentList": this.docList,
      "BeneficiaryName": this.addBankDetails.get('BeneficiaryName').value,
    }
    this.formData.delete('FormData');
    this.formData.append('FormData', JSON.stringify(data));
    //console.log(JSON.stringify(data),'add ffv check');

    this._ffv2Service.upsertFFV(this.formData).subscribe((res: any) => {
      this.alertService.createAlert(res.Message, 1);
      this.dialogRef.close();
    });
  }
  }
  close(): void {
    this.dialogRef.close();
  }
  openDocumentLink(url) {
    window.open(url, "_blank");
  }

  ReturnNullOrDefault(val) {
    if (val) return val
    else return null
  }

  VendorTypechange(event) {
    //debugger
    if (event == 52) {
      this.BillingFrequenciesist = this.BillingFrequenciesistCopy.filter(x => x['LookupName'] == 'Daily' || x['LookupName'] == 'Per Trip')

      let temp = this.BillingFrequenciesistCopy.find(x => x['LookupName'] == 'Daily')['LookupId'];
      this.addBillingDetails.controls["billingFrequnecy"].setValue(temp);
    }
    else{
      this.BillingFrequenciesist = this.BillingFrequenciesistCopy
    }

  }

  DocReq: boolean = false;
  OnCheckboxChange(event) {
    if (event.checked) {
      this.addDocumentDetails.get('Doc').setValidators(Validators.required);
      this.addDocumentDetails.get('DocDesc').setValidators(Validators.required);
      this.DocReq = true;

    } else {
      this.addDocumentDetails.get('Doc').clearValidators();
      this.addDocumentDetails.get('DocDesc').clearValidators();
      this.DocReq = false;
    }
    this.addDocumentDetails.get('Doc').updateValueAndValidity();
    this.addDocumentDetails.get('DocDesc').updateValueAndValidity();

  }

  PennyDrop(){
    debugger
    const pennyDrop: PennyDrop = {
      BeneAccNo: this.addBankDetails.controls['bankAccountNo'].value,
      BeneIFSC: this.addBankDetails.controls['BankIFSC'].value,
      Beneficiary_Name: this.addBankDetails.controls['BeneficiaryName'].value.replace(/\s/g, "")
    };
    this._ffv2Service.PennyDropFFVAcc(pennyDrop).subscribe((res: any)=>{
      if(res['Success']){
        if(res['Data']['ActCode'] == 0){
          this.pennydrop = true;
          this.addBankDetails.controls['DeclarationChbox'].setValue(true);
          this.addBankDetails.controls['BeneficiaryName'].setValue(res['Data']['BeneName'])
        }

        alert('Beneficiary Name: ' + res['Data']['BeneName'] +'\n'+
          ' Match %: ' +res['Data']['Match_%']);
          // if(confirm){
          //   this.addBankDetails.controls['BeneficiaryName'].setValue(res['Data']['BeneName']);
          // }
          // else{
          //   this.addBankDetails.controls['BeneficiaryName'].setValue(null);
          // }
      }
    })
  }
  
}
export interface PennyDrop {
  BeneAccNo: string;
  BeneIFSC: string;
  Beneficiary_Name: string;
}
