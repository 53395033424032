import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { OrderSummaryComponent } from '../order-summary/order-summary.component';
import { ManualUpdationService } from 'src/app/xp-admin-web/operations/operation-one/manual-updation/manual-updation.service';
import { AdminService } from 'src/app/xp-admin-web/admin/admin.service';

@Component({
  selector: 'app-remove-time-stamps-fcldialog',
  templateUrl: './remove-time-stamps-fcldialog.component.html',
  styleUrls: ['./remove-time-stamps-fcldialog.component.scss']
})
export class RemoveTimeStampsFCLDialogComponent implements OnInit {

  formData: FormData = new FormData();
  data:any
  access = {
    btCreate: false,
    btRead: false,
    btUpdate: false,
    btDelete: false,
    btAllow: false,
    btUserOTP: false,
    btAdminOTP: false
  };

  constructor(@Inject(MAT_DIALOG_DATA) public keyword: any,
      public dialogRef:MatDialogRef<OrderSummaryComponent>,
      public dialog: MatDialog,
    private _manualService: ManualUpdationService,public adminService: AdminService) { }

  ngOnInit(): void {
    this.access = this.adminService.checkUserPermissions('Admin Control>>Remove TimeStamps');

    this.SearchKeyword()
  }

  SearchKeyword(){
    var request = {
      Keyword: this.keyword
    }
    this.formData.delete('FormData');
    this.formData.append('FormData', JSON.stringify(request));

    this._manualService.getorder(this.formData).subscribe(res => {
      if (res != null && res['Success']) {
        this.data = res['Data']
      }
    })
  }

  RemoveTimeStamp(TimestampType){
    console.log(TimestampType)
    this._manualService.RemoveTimeStampsOrder(this.data.OrderIdCode,TimestampType).subscribe(res=>{
      if(res != null && res['Success'] == true){
        this.SearchKeyword()
      }
    })
  }

  close(): void {
    this.dialogRef.close();
  }

}
