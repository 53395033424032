import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: signalR.HubConnection | null = null;

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.coreapiUrl}send/message`)
      .configureLogging(signalR.LogLevel.Information)
      .build();
  }

  public async startConnection(): Promise<void> {
    if (!this.hubConnection) {
      console.error('HubConnection is not initialized.');
      return;
    }

    try {
      await this.hubConnection.start();
      // console.log('Connection established');
    } catch (err) {
      console.error('Error establishing connection:', err);
    }

    this.hubConnection.onclose(() => {
      console.log('Connection closed');
    });
  }

  public addReceiveMessageListener(handler: (message: any) => void): void {
    if (!this.hubConnection) {
      console.error('HubConnection is not initialized.');
      return;
    }

    this.hubConnection.on('ReceiveProgress', handler);
  }

  public stopConnection(): void {
    if (!this.hubConnection) {
      console.error('HubConnection is not initialized.');
      return;
    }

    this.hubConnection
      .stop()
      .then(() => {
        console.log('Connection stopped');
      })
      .catch((err) => {
        console.error('Error stopping connection:', err);
      });
  }
}
