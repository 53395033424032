import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManualUpdationService {

  constructor(private _http: HttpClient) { }

  getorder(data) {
    return this._http.post(environment.apiUrl + 'api/get-order-for-admin-control',data);
  }
  getorderXPCN(data) {
    return this._http.post(environment.apiUrl + 'api/get-order-for-admin-control-xpcn',data);
  }
  getorderXPLS(data) {
    return this._http.post(environment.apiUrl + 'api/get-order-for-admin-control-xpls',data);
  }
  getorderXPTS(data) {
    return this._http.post(environment.apiUrl + 'api/get-order-for-admin-control-xpts',data);
  }
  getorderXPCNDetails(data) {
    return this._http.get(environment.apiUrl + `api/get-order-for-admin-control-xpts-xpcn/${data}`);
  }
  getOrderBill(branchId, billNo, type) {
    return this._http.get(environment.apiUrl + 'api/get-order-bill-for-admin-control?branchId=' + branchId + '&billNo=' + billNo + '&type=' + type);
  }
  getOrderTranshipment(data) {
    return this._http.post(environment.apiUrl + 'api/get-order-for-admin-control-transhipment',data);
  }
  updateTranshipmentReason(orderId, reason) {
    return this._http.get(environment.apiUrl + 'api/update-transhipment-reason?orderId=' + orderId + '&reason=' + reason);
  }

  RemoveTimeStampsOrder(orderId, timpeStamptype) {
    return this._http.get(environment.coreapiUrl + 'api/v2/update-timestamps-of-order?OrderId=' + orderId + '&TimeStampType=' + timpeStamptype);
  }
}
