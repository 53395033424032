import { Component, OnInit, } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "src/app/shared/services/alert.service";
import { DatePipe } from "@angular/common";
import { Location } from "@angular/common";
import { LclXpcnPreviewDialogComponent } from "src/app/shared/lcl-xpcn-preview-dialog/lcl-xpcn-preview-dialog.component";
import { LclPickUpService } from "../lcl-pick-up/lcl-pick-up.service";
import { AdminService } from "src/app/xp-admin-web/admin/admin.service";

@Component({
  selector: 'app-rebook-xpcns',
  templateUrl: './rebook-xpcns.component.html',
  styleUrls: ['./rebook-xpcns.component.scss']
})
export class RebookXpcnsComponent implements OnInit {
  public rebookingAdvises: any[] = [];
  public totalSize: number = 0;
  public currentPage: number = 0;
  public pageSize: number = 5;
  public filterForm: FormGroup;
  public dateFilter: string = 'MTD';
  public CustomDate: boolean = false;
  public fromDate: Date | null = null;
  public toDate: Date | null = null;
  savedAdviceIds: any;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,private _lclPickService: LclPickUpService,
    private alertService: AlertService,
    private _datePipe: DatePipe,
    private location: Location,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      StartIndex: [0, Validators.required],
      PageLength: [this.pageSize, Validators.required],
      datefilterType: [this.dateFilter, Validators.required],
      FromDate: [null],
      ToDate: [null]
    });

    this.getRebookingAdvises();
  }

  goBack(): void {
    this.location.back();
  }

  private getRebookingAdvises(): void {
    const requestBody = {
      StartIndex: this.currentPage * this.pageSize + 1,
      GridSize: this.pageSize,
      FilterType: this.dateFilter,
      FromDate: this.fromDate ? this._datePipe.transform(this.fromDate, "yyyy-MM-dd") : null,
      ToDate: this.toDate ? this._datePipe.transform(this.toDate, "yyyy-MM-dd") : null
    };

    this.adminService.getRebookingXPCN(requestBody).subscribe(
      (response) => {
        console.log('Rebooking Advises Response:', response);
        if (response?.Success) {
          this.rebookingAdvises = response.Data || [];
          this.totalSize = this.rebookingAdvises.length
            ? this.rebookingAdvises[0].TotalCount
            : 0;
    
          // Save all AdviceIds
          if (this.rebookingAdvises.length > 0) {
            const adviceIds = this.rebookingAdvises.map(advice => advice.AdviceId);
            console.log('Saved AdviceIds:', adviceIds);
            this.savedAdviceIds = adviceIds; // Store it in a variable or property
          }
        } else {
          this.alertService.createAlert(response.Message || "Error fetching data", 0);
        }
      },
      (error) => {
        console.error('Error fetching rebooking advises:', error);
        this.alertService.createAlert("Error fetching data", 0);
      }
    );      
  }

  handlePage(event: PageEvent): void {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getRebookingAdvises();
  }

  FilterChanged(type: string): void {
    if (this.fromDate && this.toDate) {
      this.setCustomDateFilter();
    } else {
      this.alertService.createAlert("Please Select Custom Date", 0);
    }
  }

  private setCustomDateFilter(): void {
    this.dateFilter = 'Dates';
    this.CustomDate = true;
    this.filterForm.patchValue({
      datefilterType: 'Dates',
      FromDate: this._datePipe.transform(this.fromDate, 'yyyy-MM-dd'),
      ToDate: this._datePipe.transform(this.toDate, 'yyyy-MM-dd')
    });
    this.getRebookingAdvises();
  }

  openLCLXPCNPreviewForAdvise(advise: any): void {
    console.log(advise);
  
    this._lclPickService.getLCLXPCNDetailsForFinzalization(advise.NewXPCNId).subscribe(data => {
      if (data != null && data['Data'] != null) {
  
        let xpcnDetails = data['Data'];
        let goodsDesc = data['Data'].GoodsDescs;
        let packageTypes = data['Data'].PackageTypes;
        let riskTypes = data['Data'].RiskTypes;
        let freightModes = data['Data'].FreightModes;
        let invoices = data['Data'].Invoices;
        let waybills = data['Data'].WayBills;
  
        let gst = data['Data'].CustomerGST;
        let rate = data['Data'].Rate;
  
        let dialogRef = this.dialog.open(LclXpcnPreviewDialogComponent, {
          data: { xpcn: xpcnDetails, invoices: invoices, waybills: waybills, showFreight: false },
          height: 'auto',
          width: '900px',
          autoFocus: false
        });
      }
    });
  }
  

  clearDateFilter(): void {
    this.dateFilter = 'MTD';
    this.CustomDate = false;
    this.filterForm.reset({ StartIndex: 0, PageLength: this.pageSize, datefilterType: 'MTD' });
    this.getRebookingAdvises();
  }

  rebook(adviceId: number): void {
    console.log('Rebooking initiated for AdviceId:', adviceId);
  }  

  dateFilterChanged(type: string): void {
    this.dateFilter = type;
    this.CustomDate = type === 'Dates';
    this.filterForm.controls["datefilterType"].setValue(type);
    this.getRebookingAdvises();
  }
  

  resetFilters(): void {
    this.dateFilterChanged('MTD');
  }
}
