import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, interval } from 'rxjs';
import { ApprovalsService } from 'src/app/xp-admin-web/approvals/approvals.service';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-otp-dialog-box',
  templateUrl: './otp-dialog-box.component.html',
  styleUrls: ['./otp-dialog-box.component.scss']
})
export class OtpDialogBoxComponent implements OnInit {

  otp: string = '';
  timeoutWidth: number = 0;
  private timeoutSubscription: Subscription = new Subscription();
  private readonly timeoutDuration: number = 30;
  paymentIds: string[];
  failedIds
  public timerStarted: boolean = false;
  public paymentInProgress: boolean = false;
  public progress: number = 0;
  public totalPayments: number = 0;
  public successCount: number = 0;
  public failedCount: number = 0;
  public showerror: boolean = false;
  PaymentAmount = 0;

  constructor(
    private _approvalService: ApprovalsService,public _alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OtpDialogBoxComponent>
  ) { }

  ngOnInit(): void {
    this.paymentIds = this.data.SelectedRecords.map(record => record.PaymentID); // Array of PaymentIDs
    this.PaymentAmount = this.data.PaymentAmount;
    // this.paymentIds = this.data.SelectedRecords.map(record => record.PaymentID).join(',');
    // console.log(this.paymentIds);
  }

  ngOnDestroy(): void {
    this.timeoutSubscription.unsubscribe();
  }

  startTimeout(): void {
    if (this.timerStarted) return;

    this.timerStarted = true;
    const totalMilliseconds = this.timeoutDuration * 1000;
    const intervalMilliseconds = 1000;
    let elapsedMilliseconds = 0;

    this.timeoutSubscription = interval(intervalMilliseconds).subscribe(() => {
      elapsedMilliseconds += intervalMilliseconds;
      this.timeoutWidth = (elapsedMilliseconds / totalMilliseconds) * 100;

      if (elapsedMilliseconds >= totalMilliseconds) {
        this.timeoutSubscription.unsubscribe();
        this.timeoutWidth = 0;
        this.timerStarted = false;
      }
    });
  }

  GetOTP(): void {
    this.startTimeout();

    this._approvalService.SendGenerateOTPRequest(true, 'FFV payment').subscribe(res => {
      if (res != null) {
        res['Success'] = true;
      }
    });
  }

  InitiatePayment(): void {
    if(this.otp != null && this.otp.length === 4){
      this.paymentInProgress = true;  // Start the payment process
      this.totalPayments = this.paymentIds.length;
      this.successCount = 0;
      this.failedCount = 0;
      this.progress = 0;
    
      // Loop over paymentIds and trigger the payment API for each one
      this.paymentIds.forEach((paymentId, index) => {
        this.processPayment(paymentId, index);
      });
      this.showerror = true;
    }
    else{
      this._alertService.createAlert("Enter valid OTP", 0)
    }

  }
  
  async processPayment(paymentId: string, index: number) {
    let paymentObservable;
        paymentObservable = await this._approvalService.ICICIPayment(paymentId, this.otp);
  
    if (paymentObservable) {
      paymentObservable.subscribe(
        data => this.handleApiResponse(data, index, paymentId),
      );
    }
  }
  failedRecords: Array<{ PaymentID: number }> = [];
  handleApiResponse(response: any, index: number, ID: any): void {
    const apiResponse = response?.['Data'];
    if (apiResponse === 'SUCCESS') {
      this.successCount++;
    } else {
      this.failedCount++;
      this.failedRecords.push({ PaymentID: ID });
      this.failedIds = this.failedRecords.map(record => record.PaymentID).join(',');

    }
  console.log(this.successCount, this.failedCount, this.failedIds)
    // Update progress
    this.progress = Math.round(((index + 1) / this.totalPayments) * 100);
  
    // If all payments have been processed, finalize the progress and disable actions
    if (this.successCount + this.failedCount === this.totalPayments && this.failedCount > 0) {
      // this.paymentInProgress = false;  // Stop the progress bar
      this._approvalService.SendMailReportFFVFailedTransactions(this.failedIds).subscribe(d=>{
        this._alertService.createAlert("Failed transactions were mailed", 2);
      });
    }
  }
  

  onCancel(): void {
    this.dialogRef.close();
  }

  onKeyPress(event: KeyboardEvent): void {
    const allowedCharacters = /^[0-9]*$/; // Only numeric characters allowed
    if (!allowedCharacters.test(event.key) && !event.ctrlKey && !event.metaKey && !event.altKey) {
      event.preventDefault();
    }
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let sanitizedValue = input.value.replace(/[^0-9]/g, '');
    if (sanitizedValue.length > 6) {
      sanitizedValue = sanitizedValue.slice(0, 6);
    }

    input.value = sanitizedValue;
    this.otp = sanitizedValue;
  }

  DownloadCSV(){
    
  }
}
